export interface ISidebarPage {
	name: string;
	page: string;
	title: string;
	desc?: string;
	icon?: string;
	url: string;
	resource?: string;
	childPages?: ISidebarPage[];
	children?: MixedChild[];
	isUnread?: boolean;
}

type SidebarPageMap = Record<string, ISidebarPage>;

type Child = string;
type ComplexChild = { name: string; resource?: string; children?: MixedChild[] };
type MixedChild = Child | ComplexChild;

/**
 * @param children В списке дочерних страниц можно использовать простой
 * тип страницы в виде строки (предпочтительней варианта `enum`), либо
 * подробный. В случае использования простого типа поле `resource`
 * будет применено к этой странице от родительской.
 */
export interface IPage {
	name: string;
	icon: string;
	children?: MixedChild[];
	resource?: string;
}

/**
 * Абстрактный класс для описания коллекции страниц разделов Киноплана
 * в сайдбаре. Используй его чтобы свести к минимуму бойлерплейт, путаницу
 * и ошибки в именовании идентификатора страницы, ее адреса, локалей, и пр.
 *
 * Использование:
 * 1. Создай новый класс для своего раздела и унаследуй им этот.
 * 2. Обязательно объяви статическое поле `SECTION_PREFIX` с префиксом для
 * своего раздела.
 * 3. Объяви статическое поле `PAGES`, в котором будет находиться описание
 * страниц `IPage[]`.
 * 4. Опционально можно указать `INITIAL_PAGE_NAME`, с именем страницы,
 * в которую пользователь попадает при переходе в раздел.
 * 4. Используй статическое поле `list`, чтобы получить привычный список
 * `ISidebarPage[]`, вычисленный автоматически. Результат мемоизирован
 */
export default abstract class Pages {
	static readonly SECTION_NAME: string;
	protected static readonly PAGES: IPage[];
	protected static readonly INITIAL_PAGE_NAME?: string;
	protected static readonly IS_TEST_DATA?: boolean;

	private static getPages?: () => ISidebarPage[];
	private static getFlatPages?: () => SidebarPageMap;

	private static isComplexChild = (child: MixedChild): child is ComplexChild => (
		!_.isString(child)
	);

	private static getChildName = (child: MixedChild) => this.isComplexChild(child)
		? child.name
		: child;

	private static getChildPagesRecursive = (
		parent: ISidebarPage,
		mainSection: string,
	) => parent.children?.map(child => {
		const sectionName = this.getChildName(child);
		const isComplex = this.isComplexChild(child);

		const childPage: ISidebarPage = {
			name: sectionName,
			page: `${mainSection}_${sectionName}`,
			title: i18n.t(`Sidebar.${mainSection}_${sectionName}.title`),
			desc: i18n.t(`Sidebar.${mainSection}_${sectionName}.desc`),
			url: `${parent.url}/${sectionName}`,
			resource: isComplex ? child.resource || parent.resource : parent.resource,
			children: isComplex ? child.children : undefined,
		};

		childPage.childPages = this.getChildPagesRecursive(childPage, mainSection);

		return childPage;
	});

	private static initializePages(): ISidebarPage[] {
		if (!i18n.isInitialized && !this.IS_TEST_DATA) {
			throw new Error(
				'Pages.ts > i18n is not initialized! \n'
				+ 'You are trying to access data of sidebar pages, '
				+ 'but it includes i18n translations which are not '
				+ 'available yet.',
			);
		}

		this.getPages = _.once(
			() => this.PAGES.map(page => {
				const sidebarPage: ISidebarPage = {
					name: page.name,
					page: `${this.SECTION_NAME}_${page.name}`,
					title: i18n.t(`Sidebar.${this.SECTION_NAME}_${page.name}`),
					icon: page.icon,
					url: `/${page.name}`,
					resource: page.resource,
					children: page.children,
				};

				sidebarPage.childPages = this.getChildPagesRecursive(sidebarPage, this.SECTION_NAME);

				return sidebarPage;
			}),
		);

		return this.getPages();
	}

	private static getFlatPagesRecursive = (pages: ISidebarPage[]): ISidebarPage[] => _.chain(pages)
		.map(page => page.childPages
			? [page, this.getFlatPagesRecursive(page.childPages)]
			: page,
		)
		.flatten()
		.value();

	private static initializeFlatPages(): SidebarPageMap {
		this.getFlatPages = _.once(
			() => _.indexBy(this.getFlatPagesRecursive(this.list), 'name'),
		);

		return this.getFlatPages();
	}

	static get list(): ISidebarPage[] {
		return this.getPages?.() || this.initializePages();
	}

	/**
	 * Плоский индексированный список страниц сайдбара
	 */
	static get flatList(): SidebarPageMap {
		return this.getFlatPages?.() || this.initializeFlatPages();
	}

	/**
	 * Вычисляемый роут, который определит куда попадёт пользователь при переходе в раздел
	 */
	static get initialPath(): string {
		const firstPage = this.PAGES[0];
		const firstChildren = firstPage?.children;

		let path = `/${this.SECTION_NAME}`;

		if (this.INITIAL_PAGE_NAME) {
			const parentPageName = this.PAGES.find(page => page.children?.some(
				child => this.getChildName(child) === this.INITIAL_PAGE_NAME,
			))?.name;

			if (parentPageName) {
				path += `/${parentPageName}`;
			}

			path += `/${this.INITIAL_PAGE_NAME}`;
		} else {
			path += `/${firstPage.name}`;

			if (firstChildren) {
				path += `/${this.getChildName(firstChildren[0])}`;
			}
		}

		return path;
	}
}
