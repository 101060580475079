import IReduxState from 'app/store/IReduxState';
import { createSelector } from 'reselect';
import { getIndexedCinemas, getUserCurrentCountry } from 'app/user/userSelectors';
import { handleActions } from 'redux-actions';
import actionTypes from 'app/ReferenceActionTypes';
import { GetVoiceoverFormatString, IReferenceFormat, IReferenceRating, ReferenceFormatType } from 'app/ReferenceTypes';
import { getLocalize } from 'app/mixins';

export const PUBLIC_VIDEO_ID = 6;
export const ID_2D = 1;
export const ID_3D = 2;
export const ID_IMAX = 4;
export const ID_IMAX_3D = 17;
export const ID_COSMAX = 26;
export const ID_COSMAX_3D = 27;
export const ID_4DX = 10;
export const ID_SCREENX = 24;
export const FORMAT_4K_ID = 12;

export const initialState = { formats: [], ratings: [], genres: [], languages: []};

export const referenceReducer = handleActions({
	[actionTypes.REFERENCE_SET_REFERENCE]: (_state: IReduxState, { payload: { reference }}) => reference,
}, initialState);

export const referenceFormatsPropTypes = PropTypes.arrayOf(
	PropTypes.shape({
		exception: PropTypes.number.isRequired,
		id: PropTypes.number.isRequired,
		title: PropTypes.shape({
			en: PropTypes.string.isRequired,
			ru: PropTypes.string,
		}),
		title_short: PropTypes.shape({
			en: PropTypes.string.isRequired,
			ru: PropTypes.string,
		}),
		type: PropTypes.string.isRequired,
	}),
);

export const referenceRatingsPropTypes = PropTypes.arrayOf(
	PropTypes.shape({
		id: PropTypes.number.isRequired,
		title: PropTypes.string.isRequired,
		country_for: PropTypes.string.isRequired,
	}),
);

export const getReferenceFormats = (state: IReduxState) => state.referenceReducer.formats;
export const getReferenceGenres = (state: IReduxState) => state.referenceReducer.genres;
export const getReferenceRatings = (state: IReduxState) => state.referenceReducer.ratings;
export const getReferenceLanguages = (state: IReduxState) => state.referenceReducer.languages;

export const getVoiceoverFormatsString = createSelector(
	getReferenceLanguages,
	getReferenceFormats,
	(voiceRefs, videoformatRefs) => (
		voiceoverIDs: number |  number[] = [],
		videoformatIDs: number[] = [],
		params: GetVoiceoverFormatString = { voiceformatType: 'short', videoformatType: 'mainFormats' },
	) => {
		const voiceIDs = Array.isArray(voiceoverIDs) ? voiceoverIDs : [voiceoverIDs];

		const videoFormatsString = params.videoformatType === 'mainFormats'
			? joinFormatsTitles(videoformatRefs, videoformatIDs)
			: joinMainAndAdditionalFormatsTitles(videoformatRefs, videoformatIDs);

		if (!voiceIDs.length) return videoFormatsString;

		const voiceformatsString = voiceRefs.reduce((result, item) => {
			const title = params.voiceformatType === 'full' ? item.title : item.code;

			if (voiceIDs.includes(item.id)) {
				return result.length ? `${result}, ${title}` : title;
			}

			return result;
		}, '');

		return _.compact([videoFormatsString, voiceformatsString]).join(', ');
	},
);

export const transformFormatStringsToIds = (referenceFormats, arrayOfFormats) =>
	arrayOfFormats.map(formatTitle => getIDByFormatTitle(referenceFormats, formatTitle));

export const getGenresByCountry = createSelector(
	getUserCurrentCountry,
	getReferenceGenres,
	(country, genres = []) => genres.filter(genre => genre.country === country),
);

export const getIndexedGenres = createSelector(
	getReferenceGenres,
	genres => _.indexBy(genres, 'id'),
);

/**
 * @param {state} entities
 * @returns {IReferenceFormat[]} sorted entities by period
 */
export const getIndexedFormats = createSelector(
	getReferenceFormats,
	formats => _.indexBy(formats, 'id'),
);

export const getFormatsGroupedByType = createSelector(
	getReferenceFormats,
	formats => _.groupBy(formats, 'type'),
);

export const getFormatsByID = (referenceFormats: IReferenceFormat[], formatsIDs: number[]): IReferenceFormat[] => (
	_.compact(_.map(formatsIDs, formatID => _.findWhere(referenceFormats, { id: +formatID })))
);

export const getIDByFormatTitle = (referenceFormats: IReferenceFormat[], formatTitle: string): number | undefined => (
	(_.find(referenceFormats, format => !!format.title.en.match(new RegExp(formatTitle, 'i'))) || {}).id
);

export const getLongFormat = (referenceFormats: IReferenceFormat[], formatsIDs: number[]) => {
	const formats = _.sortBy(getFormatsByID(referenceFormats, formatsIDs), format => format.type !== 'video');

	return _.reduce(formats, (prevFormat, nextFormat) => (
		`${prevFormat} ${getLocalize(nextFormat.title_short)}`
	), '').trim();
};

export const getShortFormat = (referenceFormats: IReferenceFormat[], formatsIDs: number[], with2D: boolean = false) => {
	const formats = getFormatsByID(referenceFormats, formatsIDs);
	let shortFormat = '';
	const formatTitleShort = getLocalize((_.findWhere(formats, { type: 'video' }) || {}).title_short);

	if (_.contains(formatsIDs, 1) && formatsIDs.length === 1) {
		shortFormat = with2D ? formatTitleShort : '';
	} else if (formatsIDs.length > 1) {
		shortFormat = `${formatTitleShort}+`;
	} else {
		shortFormat = formatTitleShort;
	}

	return shortFormat;
};

export const joinFormatsTitles = (referenceFormats: IReferenceFormat[], formatsIDs: number[] | string[] = []) =>
	_.reduce<IReferenceFormat, string[]>(getFormatsByID(referenceFormats, formatsIDs.map(Number)), (memo, format) => {
		if (format.title) {
			memo.push(getLocalize(format.title));
		}

		return memo;
	}, []).join(', ');

export const joinMainAndAdditionalFormatsTitles = (referenceFormats: IReferenceFormat[], formatsIDs: number[] = []) => {
	const formats = getFormatsByID(referenceFormats, formatsIDs);
	const mainFormats = _.sortBy(_.filter(formats, format => format.type !== 'sound'), format => format.type !== 'video');
	const soundFormats = _.sortBy(
		_.filter(formats, format => format.type === 'sound'), format => getLocalize(format.title),
	);

	return _.reduce<IReferenceFormat, string[]>(_.union(mainFormats, soundFormats), (memo, format) => {
		if (format.title) {
			memo.push(getLocalize(format.title));
		}

		return memo;
	}, []).join(', ');
};

export const getCinemaFormats = createSelector(
	getIndexedCinemas,
	getReferenceFormats,
	(_state: IReduxState, cinemaID: number) => cinemaID,
	(cinemas, formats, cinemaID) => {
		const cinemaFormatsIDs = cinemas[cinemaID]?.formats_id ?? [];

		return getFormatsByID(formats, cinemaFormatsIDs);
	});

export const getMainFormats = (referenceFormats: IReferenceFormat[]) =>
	_.filter(referenceFormats, format => format.type === ReferenceFormatType.video);

export const getAdditionalFormats = (referenceFormats: IReferenceFormat[]) =>
	_.filter(referenceFormats, format => format.type !== ReferenceFormatType.video);

export const getRatingsByUserCountry = createSelector(
	[getReferenceRatings, getUserCurrentCountry],
	(ratings, country) => _.filter(ratings, rating => rating.country_for === country),
);

export const getSortedRatings = createSelector(
	getRatingsByUserCountry,
	ratings => _.sortBy(
		ratings.reduce<IReferenceRating[]>((result, item) => {
			if (item.country_for  === 'ru' && item.id === 14) {
				return result;
			} else {
				return [...result, item];
			}
		}, []),
		(value: IReferenceRating) => Number(value.title.slice(0, -1)),
	),
);

export const getVideoFormats = createSelector(
	[getReferenceFormats],
	referenceFormats => _.filter(
		referenceFormats,
		format => format.type === ReferenceFormatType.video && ![5, 19].some(id => id === format.id),
	),
);

export const getNotVideoFormats = createSelector(
	[getReferenceFormats],
	referenceFormats => _.filter(referenceFormats, format => format.type !== ReferenceFormatType.video),
);

export const getVideoFormatsID = createSelector(
	[getVideoFormats],
	videoFormats => _.pluck(videoFormats, 'id'),
);

const makeGetFormatsIDsByType = (type: string) => (referenceFormats: IReferenceFormat[]) =>
	_.pluck(_.filter(referenceFormats, format => format.type === type), 'id');

export const getSoundFormatsIDs = createSelector([getReferenceFormats], makeGetFormatsIDsByType('sound'));
export const getUpgradeFormatsIDs = createSelector([getReferenceFormats], makeGetFormatsIDsByType('upgrade'));
export const getSeatFormatsIDs = createSelector([getReferenceFormats], makeGetFormatsIDsByType('seat'));

export const makeGetFormatTitle = (state: IReduxState) =>
	(formatIDs: number[]) => getLongFormat(getReferenceFormats(state), formatIDs);
