export const SUPPORT_PHONE = '+7 495 980-17-27';
export const SUPPORT_EMAIL = 'support@kinoplan.ru';
export const LEGAL = 'https://kinoplan.ru/legal';
export const LEGAL_CONFIDENTIALITY = 'https://kinoplan.ru/legal#confidentiality';
export const CAPTCHA_EMPLOYEES_DOMAINS = [
	'kinoplan.ru',
	'dcp24.ru',
	'dcp24.tech',
	'kinoplan.tech',
];

export const OTP_CODE_LENGTH = 4;

export const signUpSteps = {
	PERSONAL_INFO: 1,
	SELECT_ROLE: 2,
	APPROVE: 3,
};

export const codeTimeouts = {
	DEFAUlT_TIMEOUT: 120000,
	ONE_SECOND: 1000,
};

export const routes = {
	LOGIN: '/start',
	SIGN_UP: '/start/signup',
	RESET: '/start/reset',
	RESET_SUCCESS: '/start/reset/success',
	REFRESH: '/start/refresh',
	SIGN_UP_COMPLETE: '/start/signup-complete',
	TOKEN: '/start/token',
};

export const USER_TYPE_GUEST = 'guest';
export const USER_TYPE_DISTRIBUTOR = 'distributor';

export const getUserRoles = _.once(() => [
	'administrator',
	'cinema',
	'mechanic',
	USER_TYPE_DISTRIBUTOR,
	USER_TYPE_GUEST,
	'accountant',
	'senior_cashier',
	'cashier',
].map(userRole => ({
	value: userRole,
	title: i18n.t(`UserRoles.${userRole}.0`),
	description: i18n.t(`UserRoles.${userRole}.1`),
})));
