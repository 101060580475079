import Loader from 'app/ui/layout/Loader';
import { handleChunkError } from 'app/app/appHelpers';
import { useDispatch } from 'react-redux';
import { ReduxTypes } from 'app/store/redux';
import { useEffect, useState } from 'react';

type Props = Record<string, any> & {
	import: Promise<any>;
	compName?: string;
	needLoader?: boolean;
	dispatch?: ReduxTypes.Dispatch;
};

export default function AsyncComponent({
	import: importComponent,
	needLoader = true,
	dispatch: propsDispatch,
	...restProps
}: Props) {
	const dispatch = useDispatch() || propsDispatch;

	const [isLoading, setIsLoading] = useState(needLoader);
	const [Component, setComponent] = useState<{ value: () => JSX.Element | null }>({
		value: () => null,
	});

	useEffect(() => {
		(async () => {
			try {
				const component = await importComponent;

				setComponent({ value: component.default });
				setIsLoading(false);
			} catch (error) {
				setComponent({ value: () => null });

				handleChunkError(error)(dispatch);
			}
		})();
	}, [importComponent]);

	return isLoading
		? <Loader visible />
		: <Component.value {..._.omit(restProps, 'compName')} />;
}
