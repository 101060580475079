import { Moment } from 'moment';
import * as t from 'io-ts';
import { ReactNode, CSSProperties } from 'react';
import { Config } from 'final-form';
import { runtimeEnum, SetStateHelper, runtimeOption as option } from 'app/common/helpersTypes';
import PrmSubSections from 'app/premiera/components/PrmSubSections';

export const PrmBaseGroupDictRuntimeType = t.interface({
	id: t.string,
	name: t.string,
	parent_id: t.union([t.string, t.null]),
	is_deleted: t.boolean,
});

export type PrmBaseGroupDict = t.TypeOf<typeof PrmBaseGroupDictRuntimeType>;

export const PrmBaseDictRuntimeType = t.interface({
	id: t.string,
	name: t.string,
	is_main: t.boolean,
	group_id: t.union([t.string, t.null]),
	is_deleted: t.boolean,
});

export type PrmBaseDict = t.TypeOf<typeof PrmBaseDictRuntimeType>;

export type ITreeGroup<T> = PrmBaseGroupDict & {
	is_group?: boolean;
	level?: number;
	children?: Array<T & ITreeGroup<T>>;
};

export type ITreeGroupWithItem<G, T> = (PrmBaseDict & T | PrmBaseGroupDict & G) & {
	is_group?: boolean;
	level?: number;
	children?: Array<(G | T) & ITreeGroupWithItem<G, T>>;
};

export type ITreeItem = PrmBaseDict & {
	level?: number;
};

export interface IAnyObject {
	[key: string]: any;
}

export interface IFilter {
	[key: string]: string | number[] | boolean | Moment;
}

export interface ISelectOption {
	value: never[] | number | string;
	title: string | number | JSX.Element;
	textValue?: string;
	type?: 'item';
	[key: string]: any;
}

export type IMultiselectGroupedOption = {
	value: never[] | number | string;
	title: string | number | JSX.Element;
	type?: 'group';
	options: IMultiselectGroupedOption[] | ISelectOption[];
	[key: string]: any;
};

const TicketTemplateRuntimeType = t.interface({
	id: t.string,
	name: t.string,
});

export const TicketTypeRuntimeType = t.interface({
	id: t.string,
	name: t.string,
	is_main: t.boolean,
});

export type TicketType = t.TypeOf<typeof TicketTypeRuntimeType>;

export enum CreditCard {
	VISA = 1,
	MastercardOrEurocard = 2,
	DinersClub = 3,
	AmericanExpress = 4,
	JCB = 5,
	Uneximcard = 6,
	EuroCirrusMaestro = 7,
	QRCode = 8,
	PushkinCard = 9,
}

export enum Cashless {
	PaymentDocument = 1,
	BonusCard = 2,
}

const CreditCardCurrency = t.interface({
	method: t.literal('card'),
	auto_detect_id: option(runtimeEnum<CreditCard>(CreditCard)),
});

const CashlessCurrency = t.interface({
	method: t.literal('cashless'),
	auto_detect_id: option(runtimeEnum<Cashless>(Cashless)),
});

const NonCashlessCurrency = t.interface({
	method: t.keyof({
		cash: null,
		prepaid_expense: null,
		counter_grant: null,
	}),
});

export const CurrencyRuntimeType = t.intersection([
	t.interface({
		id: t.string,
		name: t.string,
	}),
	t.union([CreditCardCurrency, CashlessCurrency, NonCashlessCurrency]),
]);

export type Currency = t.TypeOf<typeof CurrencyRuntimeType>;

const ContractorRuntimeType = t.interface({
	id: t.string,
	name: t.string,
});

const SpecialOfferRuntimeType = t.interface({
	id: t.string,
	type: t.string,
	name: t.string,
	parameters: option(t.type({
		rate: t.number,
		threshold_sum: t.number,
		invitation_text: t.string,
		field_prefix: t.string,
		interval: t.type({
			date_from: t.string,
			date_to: t.string,
		}),
		is_active: t.boolean,
		is_modal_shown: t.boolean,
	})),
	is_deleted: t.boolean,
});

const PlaceTypeRuntimeType = t.interface({
	id: t.string,
	name: t.string,
	is_main: t.boolean,
	color: t.number,
	is_with_numbers: t.boolean,
});

export type PlaceType = t.TypeOf<typeof PlaceTypeRuntimeType>;
export type ContractorType = t.TypeOf<typeof ContractorRuntimeType>;
export type SpecialOfferType = t.TypeOf<typeof SpecialOfferRuntimeType>;

const ExternalCodeTicketTypeRuntimeType = t.interface({
	id: t.number,
	name: t.string,
});

const DateIntervalRuntimeType = t.interface({
	id: t.string,
	date_from: t.string,
	date_to: t.string,
	is_deleted: t.boolean,
	price_schemes_count: t.number,
	deleted_price_schemes_count: t.number,
});

export const DateIntervalsRuntimeType = t.array(DateIntervalRuntimeType);

export type DateInterval = t.TypeOf<typeof DateIntervalRuntimeType>;
export type DateIntervalFields = Omit<DateInterval, 'price_schemes_count' | 'deleted_price_schemes_count'>;

export const TitleRuntimeType = t.interface({
	en: t.string,
	ru: t.string,
});

export const CinemaRuntimeType = t.interface({
	id: t.number,
	title: TitleRuntimeType,
	cinema_network_id: t.number,
	city_title: TitleRuntimeType,
	halls: t.array(t.interface({
		id: t.number,
		title: t.string,
		number: t.number,
		cinema_id: t.number,
	})),
	sections: t.array(t.string),
});

export type Cinema = t.TypeOf<typeof CinemaRuntimeType>;

export const CinemaNetworkRuntimeType = t.interface({
	id: t.number,
	title: TitleRuntimeType,
	short_title: TitleRuntimeType,
	sections: t.array(t.string),
});

export type CinemaNetwork = t.TypeOf<typeof CinemaNetworkRuntimeType>;

export const BasePriceSchemeRuntimeType = t.interface({
	id: t.string,
	name: t.string,
	is_deleted: t.boolean,
});

export type BasePriceScheme = t.TypeOf<typeof BasePriceSchemeRuntimeType>;

export interface IFormattedCinemasAndHalls {
	cinemaIDs?: number[];
	cinemasCount: number;
	hallsCount: number;
	list: Array<{
		cinema: string;
		halls: string;
	}>;
}

export enum ActionType {
	delete = 'delete',
	restore = 'restore',
	create = 'create',
	edit = 'edit',
	export = 'export',
}

export enum PrmRequestType {
	Add,
	Edit,
}

export interface IDelayedData {
	[key: string]: ActionType.delete | ActionType.restore;
}

export interface ITableCells {
	[key: string]: {
		title: ReactNode;
		style?: CSSProperties;
	};
}

export enum PrmSectionName {
	Ribbon = 'ribbon',
	RibbonAcceptance = 'ribbon_acceptance',
	RibbonSettings = 'ribbon_settings',
	PlaceTypes = 'place_types',
	TicketTypes = 'ticket_types',
	Bod = 'bod',
	PriceSchemes = 'price_schemes',
	PriceGroups = 'price_groups',
	PriceModifiers = 'price_modifiers',
	LegalEntity = 'legal_entity',
	Requisites = 'requisites',
	Positions = 'positions',
	ObjectSettings = 'object_settings',
	ReleaseProperties = 'release_properties',
	Taxes = 'taxes',
	SpecialOffers = 'special_offers',
	ReservationTypes = 'reservation_types',
	Invitations = 'invitations',
	ReturnTypes = 'return_types',
	Discounts = 'discounts',
	PaymentMeans = 'payment_means',
	Cash = 'cash',
	Cashless = 'cashless',
	Card = 'card',
	CounterGrant = 'counter_grant',
	PrepaidExpense = 'prepaid_expense',
	DistributorReports = 'distributor_reports',
	Reports = 'reports',
}

const PrmManualRuntimeType = runtimeEnum<PrmSectionName>(PrmSectionName);

const SyncInfoRuntimeType = t.interface({
	manual: PrmManualRuntimeType,
	error_code: t.union([t.number, t.undefined]),
	updated: t.number,
});

const SyncInfoReferenceBaseRuntimeType = t.interface({
	sync_infos: t.array(SyncInfoRuntimeType),
});

const SyncInfoReferenceCinemaRuntimeType = t.intersection([
	SyncInfoReferenceBaseRuntimeType,
	t.interface({ cinema_id: t.number }),
]);

const SyncInfoReferenceNetworkRuntimeType = t.intersection([
	SyncInfoReferenceBaseRuntimeType,
	t.interface({ network_id: option(t.number) }),
]);

const SyncInfoReferenceRuntimeType = t.union([
	SyncInfoReferenceCinemaRuntimeType,
	SyncInfoReferenceNetworkRuntimeType,
]);

export const RefsRuntimeType = t.interface({
	cinemas: t.array(CinemaRuntimeType),
	cinema_networks: t.array(CinemaNetworkRuntimeType),
	external_codes_ticket_types: t.array(ExternalCodeTicketTypeRuntimeType),
	sync_info: t.array(SyncInfoReferenceRuntimeType),
});

export type Refs = t.TypeOf<typeof RefsRuntimeType>;

export const CinemaOrNetworkRefsRuntimeType = t.interface({
	payment_means: t.array(CurrencyRuntimeType),
	ticket_types: t.array(TicketTypeRuntimeType),
	ticket_layouts: t.array(TicketTemplateRuntimeType),
	contractors: t.array(ContractorRuntimeType),
	place_types: t.array(PlaceTypeRuntimeType),
	special_offers: t.array(SpecialOfferRuntimeType),
});

export type CinemaOrNetworkRefs = t.TypeOf<typeof CinemaOrNetworkRefsRuntimeType> & {
	cinemaOrNetworkID?: string;
};

export interface IPrmState {
	currentCinemaOrNetworkID: string;
	refs: Refs;
	needUpdateRefsInBackground: boolean;
	cinemaOrNetworkRefs: CinemaOrNetworkRefs;
	loaders: {
		refs: boolean;
		cinemaOrNetworkRefs: boolean;
	};
}

export interface IInfoBoxOptions {
	title: ReactNode;
	value: ReactNode;
	width?: number;
}

export interface IBaseEditableTableItem {
	id: string;
	is_deleted: boolean;
	canDelete?: boolean;
	canRestore?: boolean;
}

export type EditableTableFormData<T extends IBaseEditableTableItem> = {
	initialValues: Partial<T>;
	validate?: Config<Record<string, string | number | null>>['validate'];
};

export type EditableTableMenu<T> = {
	item: T & { is_new?: boolean };
	trackingCode: string;
	setEditingLine(itemID: string, mode: boolean): void;
	onSave(item: T): void;
};

export type TicketTemplate = t.TypeOf<typeof TicketTemplateRuntimeType>
export type TicketTemplateMap = Record<string, TicketTemplate>;

export type PrmSectionContext<T> = React.Context<[_.Dictionary<T>, SetStateHelper<_.Dictionary<T>>]>;

export type PrmSyncInfoReference = t.TypeOf<typeof SyncInfoReferenceRuntimeType>;
export type PrmSyncInfo = t.TypeOf<typeof SyncInfoRuntimeType>;

export type MapSectionToApp = React.ComponentProps<typeof PrmSubSections>['mapSectionToApp'];
