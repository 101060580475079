import {createSelector} from 'reselect';
import {getUserCurrentDate, getCinemaDayTime, getCinemaSettingsByID} from 'app/user/userSelectors';
import {getWeeksByDate} from 'app/repertory/RepertoryHelpers';

export const getCurrentCinemaViewMode = state => state.repertoryReducer.currentCinemaViewMode;

export const getDaysRange = (date, mode) => {
	let startDate;
	let endDate;

	switch (mode) {
		case 'separateWeeks':
			startDate = date.clone();
			endDate = date.clone().add(1, 'month').startOf('week');

			return _.range(startDate.date(), endDate.diff(startDate, 'days') + startDate.date());
		case 'threeMonths':
			startDate = date.clone();
			endDate = date.clone().add(3, 'month').startOf('week');

			return _.range(startDate.date(), endDate.diff(startDate, 'days') + startDate.date());
		case 'fullWeeks':
			startDate = date.clone().startOf('month').add(6, 'days').weekday(0);
			endDate = date.clone().endOf('month').weekday(6);
			break;
		case 'fullMonth':
		default:
			startDate = date.clone().startOf('month');
			endDate = date.clone().endOf('month');
			break;
	}

	return _.range(startDate.date(), endDate.diff(startDate, 'days') + startDate.date() + 1);
};

export const getWeekIntervals = createSelector(
	[getUserCurrentDate, getCurrentCinemaViewMode],
	(date, mode) => _.chain(getDaysRange(date, mode))
		.map(day => date.clone().date(day))
		.groupBy(day => day.week())
		.sortBy(days => _.first(days).clone().weekday(0).year())
		.value(),
);

const getChosenDuration = release => release.duration[release.chosen_duration] || release.duration.clean || 100;

export const getSumCount = (weeks = []) => _.chain(weeks)
	.pluck('count')
	.reduce((memo, num) => (memo + num), 0)
	.value();

const getSumWeekMinutes = (release, date, pause, ad) => {
	const weeks = getWeeksByDate(release.weeks, date);
	const sumCount = getSumCount(weeks);

	return sumCount !== 0
		? (getChosenDuration(release) + ad) * sumCount + pause * (sumCount - 1)
		: 0;
};

export const makeGetCinemaOccupancy = () => createSelector(
	[
		getCinemaDayTime,
		getCinemaSettingsByID,
		(state, cinemaID, week, releases) => releases,
		(state, cinemaID, week) => week,
	],
	(cinemaDayTime, cinemaSettingsByID, releases, week) => {
		const date = _.first(week);
		const {
			halls_settings: hallsSettings,
			default_cinema_advertising_block: defaultCinemaAd,
			occupancy_time_format: timeFormat,
		} = cinemaSettingsByID;
		const sumHallPause = _.chain(hallsSettings)
			.map(hall => Number(hall.default_hall_pause))
			.reduce((sum, item) => (sum + item))
			.value();

		const averagePause = Math.floor(sumHallPause / hallsSettings.length) || 0;
		const defaultAd = Number(defaultCinemaAd);
		const sumWeekMinutes = _.chain(releases)
			.map(release => getSumWeekMinutes(release, date, averagePause, defaultAd))
			.reduce((sum, item) => (sum + item))
			.value() || 0;

		return {
			freeMinutes: cinemaDayTime - sumWeekMinutes,
			busyMinutes: sumWeekMinutes,
			occupancyPercent: Math.floor((sumWeekMinutes / cinemaDayTime) * 100),
			timeFormat,
		};
	},
);

export const getDatesBetweenStartEnd = (dateStart, dateEnd) => {
	const dateStartMoment = moment(dateStart, 'YYYY-MM-DD');
	const dateEndMoment = moment(dateEnd, 'YYYY-MM-DD');

	return _.map(
		_.range(0, dateEndMoment.diff(dateStartMoment, 'days') + 1),
		day => dateStartMoment.clone().add(day, 'days').format(),
	);
};
