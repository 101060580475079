const Loader = ({classes, visible, isRelative}) =>
	<div className={`${classes} ${visible ? 'loader--visible' : ''}`} style={{position: isRelative ? 'relative' : null}} />;

Loader.propTypes = {
	classes: PropTypes.string,
	visible: PropTypes.bool,
	isRelative: PropTypes.bool,
};
Loader.defaultProps = {
	classes: 'loader',
	visible: false,
	isRelative: false,
};

export default Loader;
