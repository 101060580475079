import {createSelector} from 'reselect';
import {
	isRepertoireReadOnly,
	hasActiveBookingSubscription,
	isMediaplanOnly,
	getUserCinemas,
} from 'app/user/userSelectors';
import {
	getReleaseInfo,
	getPreviewInfo,
	hasKeys,
	getKeysInfo,
	getErrorInfo,
	getPreviewErrorInfo,
} from 'app/repertory/selectors/RepertoryHallsReleaseSelectors';
import {getReferenceFormats} from 'app/Reference';
import {COUNT_OF_LETTERS_FOR_CINEMA_TITLE_SEARCH} from 'app/repertory/RepertoryConstants';

export const getReleases = state => state.repertoryNetworkReducer.releases;
export const getIsLoadingReleases = state => state.repertoryNetworkReducer.isLoadingReleases;
export const getReleasesTotal = state => state.repertoryNetworkReducer.releasesTotal;
export const getCinemas = state => state.repertoryNetworkReducer.cinemas;
export const getNetworkCinemasFilter = state => state.repertoryNetworkReducer.cinemaValueFilter;
export const getAlreadyCalledCinemas = state => state.repertoryNetworkReducer.alreadyCalledCinemas;

export const getIndexedCinemas = createSelector(
	getCinemas,
	cinemas => _.indexBy(cinemas, 'id'),
);

export const getFilteredCinemas = createSelector(
	getCinemas, getUserCinemas, getAlreadyCalledCinemas, getNetworkCinemasFilter,
	(cinemas, userCinemas, alreadyCalledCinemas, filter) => {
		const lowerCasedFilter = filter.toLowerCase();

		return filter.length >= COUNT_OF_LETTERS_FOR_CINEMA_TITLE_SEARCH
			? userCinemas.filter(
				cinema => cinema.title.ru.toLowerCase().includes(lowerCasedFilter) ||
					cinema.title.en.toLowerCase().includes(lowerCasedFilter) ||
					cinema.city.title.ru.toLowerCase().includes(lowerCasedFilter) ||
					cinema.city.title.en.toLowerCase().includes(lowerCasedFilter),
			) : cinemas.filter((cinema, index) => index < alreadyCalledCinemas.length);
	},
);

export const getCinemaByID = (state, id) => getIndexedCinemas(state)[id];

const getWeek = (state, week) => week;
export const makeGetReleasesByWeek = () => createSelector(
	[getReleasesTotal, getWeek],
	(releases, week) => _.chain(releases)
		.filter(release => moment(_.first(week)).isBetween(
			moment(release.date_start).weekday(0),
			release.date_end,
			'day',
			'[]',
		))
		.groupBy('release_id')
		.reduce((memo, groupedRelease) => {
			const firstRelease = groupedRelease[0];
			if (groupedRelease.length === 1) {
				memo.push(firstRelease);
			} else {
				memo.push({
					...firstRelease,
					counts: _.reduce(
						_.groupBy(_.flatten(_.pluck(groupedRelease, 'counts')), 'format'),
						(subMemo, elem) => {
							if (!subMemo.length) {
								subMemo.push({
									...elem[0],
									count: elem.reduce((acc, subelem) => acc + subelem.count, 0),
								});
							}

							return subMemo;
						}, [],
					),
				});
			}

			return memo;
		}, [])
		.sortBy(release => _.getLocalize(release.title))
		.value(),
);

export const getAllReleasesByCinemaID = (releases, cinemaID) => {
	const hashMap = {};

	return _.reduce(releases[cinemaID], (cinemaReleases, items = []) => {
		const releasesPart = items.reduce((amountReleases, item) => {
			if (item.release_id in hashMap) return amountReleases;

			hashMap[item.release_id] = item.release_id;
			amountReleases.push(item);

			return amountReleases;
		}, []);

		cinemaReleases.push(...releasesPart);

		return cinemaReleases;
	}, []);
};

const getCurrentWeek = (state, cinemaID, week) => week;

const getCinemaID = (state, cinemaID) => cinemaID;
export const makeGetReleasesByCinemaAndDate = () => createSelector(
	[getReleases, getCurrentWeek, getCinemaID],
	(releases, week, cinemaID) => _.get(releases[cinemaID], _.first(week).format()),
);

export const hasReleaseError = release => {
	const date = release.date_preview || release.date;

	return moment(release.plan_start).isBefore(moment(date));
};

export const supportPreviewFormats = (release, cinemaFormatsIDs) => {
	const previewFormats = release.preview_formats || [];

	return previewFormats.length
		? _.some(previewFormats, formatID => _.contains(cinemaFormatsIDs, formatID))
		: true;
};

export const hasReleasePreviewError = (release, cinemaFormatsIDs) => {
	const previewDate = moment(release.date_preview);
	const startDate = moment(release.date);
	const planDate = moment(release.plan_start);

	const isPreviewPlan = previewDate.isValid() && planDate.isBefore(startDate)
		&& planDate.isBetween(previewDate, startDate, 'day', '[]');

	return isPreviewPlan && !supportPreviewFormats(release, cinemaFormatsIDs);
};

const getRelease = (state, release) => release;
const getCinemaFormatsIDs = (state, release, cinemaFormatsIDs) => cinemaFormatsIDs;
export const getReleaseTooltip = createSelector(
	[getReferenceFormats, getRelease, getCinemaFormatsIDs],
	(referenceFormats, release, cinemaFormatsIDs) => (
		<div>
			{getReleaseInfo(release)}
			{release.date_preview ? getPreviewInfo(release, referenceFormats) : ''}
			{hasKeys(release) ? getKeysInfo(release) : ''}
			{hasReleaseError(release) ? getErrorInfo(release) : ''}
			{hasReleasePreviewError(release, cinemaFormatsIDs) ? getPreviewErrorInfo() : ''}
		</div>
	),
);

const makeDoesUserHasRightsToCopy = (state, id) => !isRepertoireReadOnly(state, id) && !isMediaplanOnly(state, id);
const makeHasActiveBookingSubscription = (state, id) => hasActiveBookingSubscription(state, id);

export const makeGetCinemasForCopying = () => createSelector(
	getCinemas, getCinemaID, makeHasActiveBookingSubscription,
	state => id => makeDoesUserHasRightsToCopy(state, id),
	(cinemas, cinemaID, hasSubscription, hasRigths) => cinemas
		.reduce((memo, cinema) => {
			if (cinema.id !== cinemaID && hasRigths(cinema.id)) {
				const reasonType = [hasRigths(cinema.id), hasSubscription].indexOf(false);
				memo.push({
					...cinema,
					title: _.getLocalize(cinema.title),
					reasonToDisable: reasonType > -1 ? i18n.t(`Copying.CannotCopyRepertoryReason.${reasonType}`) : null,
				});
			}

			return memo;
		}, []),
);

export const areAllCinemasSelectedForCopying = (
	areAllCinemasChecked, selectedCinemasNumber, notDisabledCinemasNumber, filteredCinemasNumber,
) => (
	selectedCinemasNumber && (selectedCinemasNumber === notDisabledCinemasNumber)
	|| areAllCinemasChecked && (selectedCinemasNumber >= filteredCinemasNumber)
);
