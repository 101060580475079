import * as t from 'io-ts';
import { Mixed } from 'io-ts';
import { either } from 'fp-ts';
interface IAction {
	create: boolean;
	update: boolean;
	read: boolean;
	delete: boolean;
}

export type ObjectID = string;
export const ObjectIDRuntime = t.string;

interface IAbstractResource {
	name: string;
	is_admin?: boolean | null;
	action?: IAction;
	resources?: IAbstractResource[];
}

export type NullableProps<T> = {
	[K in keyof T]: T[K] | null
};

export type Modify<T, R> = Omit<T, keyof R> & R;

export type IResource = IAbstractResource;

export type Gravity = 's' | 'n' | 'e' | 'w' | 'n-e' | 'n-w' | 's-e' | 's-w' | 'e-n' | 'e-s' | 'w-n' | 'w-s';

export type JSONString = string;
export type Timestamp = number;
export type DateTime = string; // 'YYYY-MM-DD HH:mm:ss'
export const DateTimeRuntime = t.string;
export const TimestampRuntime = t.number;

export const runtimeOption = <T extends Mixed>(type: T) => t.union([type, t.null]);

export const runtimeEnum = <T, E extends object = object>(
	enumeration: E,
	name?: string,
): t.Type<T> => {
	type ReverseMap<T> = Map<{[K in keyof T]: T[K] }[keyof T], keyof T>;
	const enumDescription = `One of (${Object.values(enumeration).join(', ')})`;

	return new (class extends t.Type<T> {
		public readonly reverseMap: ReverseMap<E>;
		// _tag и keys нужны, чтобы runtimeEnum адекватно работал в t.record в качестве ключа
		public _tag = 'KeyofType';
		public keys: Record<string, unknown> = Object.values(enumeration)
			.reduce((acc, val) => ({
				...acc,
				[val]: null,
			}), {});

		public enumReverseMap<E extends object>(enumeration: E): ReverseMap<E> {
			return new Map(Object.entries(enumeration).map(([key, value]) => [value, key as keyof E]));
		}

		constructor() {
			super(
				name || enumDescription,
				(value: unknown): value is T => this.reverseMap.has(value as E[keyof E]),
				(value: unknown, context: t.Context): either.Either<t.Errors, T> =>
					this.is(value) ? t.success(value) : t.failure(value, context),
				t.identity,
			);

			this.reverseMap = this.enumReverseMap(enumeration);
		}
	})();
};

export const omit = <T extends object, K extends Extract<keyof T, string>>(obj: T, ...keys: K[]): Omit<T, K> =>
	_.omit(obj, ...keys);
export type SetStateHelper<T> = React.Dispatch<React.SetStateAction<T>>;
