import {createSelector} from 'reselect';
import {
	getDatesBetweenStartEnd,
	getDateStart,
	getDateEnd,
	makeGetReleaseByID,
	getAllScheduleReleases,
	isHallSupportsFormats,
	hasHallLicense,
} from 'app/schedule/selectors/scheduleSelectors';
import {getEditOnlyCurrentDay} from 'app/schedule/selectors/scheduleCommonSelectors';
import {
	getShortFormat,
	getUpgradeFormatsIDs,
} from 'app/Reference';
import {SCHEDULE_STYLES} from 'app/schedule/scheduleColorsConstants';
import {canUserEditSchedule, isPremiere, getCurrentCinemaHalls, getHall} from 'app/user/userSelectors';
import {
	getPatchDataForCastling,
	getAllSeancesForCastling,
	getSeanceDurationWithoutPause,
} from 'app/schedule/scheduleHelpers';

const getChosenWeekday = state => state.scheduleReducer.scheduleMainReducer.present.chosenWeekday;
export const getSeances = state => state.scheduleReducer.scheduleMainReducer.present.seances;
export const getSeancesGrouped = state => state.scheduleReducer.scheduleMainReducer.present.seancesGrouped;
export const getSeanceByID = _.memoize(seanceID => createSelector(
	getSeances,
	seances => seances[seanceID],
));

const getHallIDSelector = (state, props) => props.hallID;
const getDateStartSelector = (state, props) => {
	const toReturn = props.dateStart;

	return toReturn;
};
const hasErrorUCSStatus = (isCinemaPremiere, seances) => isCinemaPremiere && _.some(
	seances,
	seance => _.get(seance.ucs_status, 'status') === 'error' ||
		_.get(seance.ucs_status, 'seance_status.status') === 'error',
);

export const makeGetSeancesForHallForDay = () => createSelector(
	[getSeancesGrouped, getHallIDSelector, getDateStartSelector, isPremiere],
	(seancesGrouped, hallID, dateStart, isCinemaPremiere) => {
		const seancesForHallForDay = (seancesGrouped[hallID] || {})[dateStart];
		if (hasErrorUCSStatus(isCinemaPremiere, seancesForHallForDay)) {
			return _.map(seancesForHallForDay, seance => ({
				...seance,
				ucs_status: {
					...seance.ucs_status,
					seance_status: {
						..._.get(seance.ucs_status, 'seance_status'),
						status: 'error',
					},
				},
			}));
		}

		return seancesForHallForDay;
	},
);

export const getNextSeance = (state, {seance}) => _.find(
	makeGetSeancesForHallForDay()(state, {hallID: seance.hall_id, dateStart: seance.date_start}),
	currentSeance => currentSeance.date > seance.date,
);

export const canMoveSeance = seance => !!(seance.id && seance.can_move);
export const isOnSaleSeance = seance => !!(seance && seance.sale && (seance.sale.is_open || seance.sale.is_sold));
export const doesSeanceHasSolds = seance => !!(seance && seance.sale && (seance.sale.sold_count > 0 || seance.sale.is_sold));
export const isOnSaleForAllTicketSystems = seance => !!(seance && seance.sale &&
	(seance.sale.is_open || seance.sale.ucs_is_open || seance.sale.is_sold || !!seance.sale.reserved_count));

export const makeCanMoveNextSeance = () => createSelector(
	getNextSeance,
	nextSeance => (nextSeance ? canMoveSeance(nextSeance) : true),
);

export const hasUnmovableSeances = seances => !_.every(seances, canMoveSeance);

export const getSeanceDurationWithPause = seance => _.reduce(
	seance.duration,
	(memo, duration) => memo + duration,
	0,
);

export const getEmptyTimeBetweenTwoSeances = (seance1, seance2) =>
	moment.unix(seance2.date).diff(
		moment.unix(seance1.date).add(
			getSeanceDurationWithoutPause(seance1),
			'minutes',
		),
		'minutes',
	);

const getSeance = (state, props) => props.seance;
export const makeShouldUpdateDuration = () => createSelector(
	[getAllScheduleReleases, getSeance],
	(allScheduleReleases, seance) => {
		const release = makeGetReleaseByID().resultFunc(allScheduleReleases, seance.release_id);

		return !!seance.release_id &&
			_.has(release, 'duration') &&
			release.duration[release.chosen_duration] !== seance.duration.release;
	},
);

const getReleaseID = (state, props) => props.releaseID;
const getFormats = (state, props) => props.formats;
const getVoiceoverID = (state, props) => props.voiceoverID;
export const makeGetCountSeancesForChosenWeekday = () => createSelector(
	[getSeances, getDateStart, getChosenWeekday, getReleaseID, getFormats, getVoiceoverID],
	(seances, dateStart, chosenWeekday, releaseID, formats, voiceoverID) => {
		const currentSeances = _.where(seances, {
			release_id: releaseID,
			voiceover_language_id: voiceoverID,
		});
		const chosenDate = moment(dateStart).add(chosenWeekday, 'd').format();

		return _.filter(
			currentSeances,
			seance => seance.date_start === chosenDate && _.isEqual(seance.formats, formats),
		).length;
	},
);

export const needCheckSeanceDateStart = seance => {
	const commonStatus = _.get(seance.ucs_status, 'status');
	const seanceStatus = _.get(seance.ucs_status, 'seance_status.status');

	return commonStatus === 'error' || seanceStatus === 'error' ? 'date_start' : null;
};

export const getImportantSeanceFields = seance => ({
	..._.pick(
		seance,
		'release_id',
		'time_start',
		'duration',
		'hall_id',
		'position',
		'formats',
		'advertising',
		'is_advertising_actual',
		'is_approved',
		'is_locked',
		'is_on_sale',
		'comment',
		'title',
		'ucs_status',
		'property',
		'voiceover_language_id',
		needCheckSeanceDateStart(seance),
	),
	is_on_sale: isOnSaleSeance(seance),
});

export const isTwoSeancesSimilar = (seance1, seance2) => _.isEqual(
	getImportantSeanceFields(seance1),
	getImportantSeanceFields(seance2),
);

// ([seance], [seance]) => Boolean
export const isTwoDaysSeancesSimilar = (daySeances1 = [], daySeances2 = []) => !!(
	daySeances1.length === daySeances2.length &&
	_.every(
		daySeances1,
		seance1 => isTwoSeancesSimilar(seance1, daySeances2[seance1.position]),
	)
);

export const getSeancesMaxLength = (state, hallID) => _.max([
	..._.map(getSeancesGrouped(state)[hallID], daySeances => daySeances.length + 2),
	3,
]);

export const getSeancesMaxLengthForHallSeances = seancesGroupedByHall => _.max([
	..._.map(seancesGroupedByHall, daySeances => daySeances.length + 2),
	3,
]);

export const getSeancesHeightForHalls = createSelector(
	[getSeancesGrouped, (_, halls) => halls],
	(seancesGrouped, halls) =>
		_.pluck(halls, 'id').reduce((obj, hallID, index) => {
			const cellHeight = parseInt(SCHEDULE_STYLES.cellHeight, 10);
			const headerBorder = parseInt(SCHEDULE_STYLES.headerBorder, 10);
			const headerHeight = 36;

			obj[hallID] =
				getSeancesMaxLengthForHallSeances(seancesGrouped[hallID])
				* cellHeight
				+ headerHeight
				+ (index > 0 ? cellHeight : 0)
				+ headerBorder;

			return obj;
		}, {}),
);

const getDateEndSelector = (state, props) => props.dateEnd;

export const makeGetSeancesForHallForRange = () => createSelector(
	[getSeancesGrouped, getHallIDSelector, getDateStartSelector, getDateEndSelector, isPremiere],
	(seancesGrouped, hallID, dateStart, dateEnd, isCinemaPremiere) => {
		const range = getDatesBetweenStartEnd(moment(dateStart), moment(dateEnd));
		const seancesByHall = seancesGrouped[hallID];

		if (seancesByHall) {
			const seancesForHallForRange = range.map(date => seancesByHall[date]);

			if (hasErrorUCSStatus(isCinemaPremiere, seancesForHallForRange)) {
				return _.map(seancesForHallForRange, seance => ({
					...seance,
					ucs_status: {
						...seance.ucs_status,
						seance_status: {
							..._.get(seance.ucs_status, 'seance_status'),
							status: 'error',
						},
					},
				}));
			}

			return seancesForHallForRange;
		}

		return [];
	},
);

export const makeGetSeancesByTime = () => createSelector(
	[
		(state, props) => props.hallID,
		(state, props) => props.dateStart,
		(state, props) => props.dateEnd,
		isPremiere,
		getSeancesGrouped,
	],
	(hallID, dateStart, dateEnd, isCinemaPremiere, seancesGrouped) => {
		const similarDaysSeancesFromRange = makeGetSeancesForHallForRange().resultFunc(
			seancesGrouped,
			hallID,
			dateStart,
			dateEnd,
			isCinemaPremiere,
		);

		return _.groupBy(_.flatten(similarDaysSeancesFromRange), seance => seance.time_start);
	},
);

const getDate = (state, props) => props.date;
export const makeGetSeancesForDate = () => createSelector(
	[getSeances, getDate],
	(seances, date) => _.filter(seances, seance => (seance || {}).date_start === date),
);

export const getSeancesInHall = _.memoize(hallID => createSelector(
	getSeancesGrouped,
	seancesGrouped => seancesGrouped[hallID],
));

export const getHallsWithSeances = (state, halls, currentDate) => {
	const seancesInHall = getSeancesGrouped(state);

	return (
		_.filter(halls, hall => (!_.isEmpty(seancesInHall[hall.id] && seancesInHall[hall.id][currentDate])))
	);
};

export const getSimilarDaysArray =  (seancesInHall = {}, date) => {
	const datesOfWeek = getDatesBetweenStartEnd(
		moment(date).startOf('week').format(),
		moment(date).endOf('week').format(),
	);

	const daysBeforeSeance = _.first(datesOfWeek, moment(date).weekday() + 1).reverse();
	const daysAfterSeance = _.last(datesOfWeek, datesOfWeek.length - moment(date).weekday());

	const similarDaysStart = _.find(
		daysBeforeSeance,
		(day, index) => !isTwoDaysSeancesSimilar(
			seancesInHall[day],
			seancesInHall[daysBeforeSeance[index + 1]] || [],
		),
	) || _.last(daysBeforeSeance);
	const similarDaysEnd = _.find(
		daysAfterSeance,
		(day, index) => !isTwoDaysSeancesSimilar(
			seancesInHall[day],
			seancesInHall[daysAfterSeance[index + 1]] || [],
		),
	) || _.last(daysAfterSeance);

	return getDatesBetweenStartEnd(similarDaysStart, similarDaysEnd);
};

export const makeGetDays = () => (
	createSelector(
		[getDateStart, getDateEnd, (state, seancesInHall) => seancesInHall],
		(dateStart, dateEnd, seancesInHall = {}) => {
			const dates = getDatesBetweenStartEnd(dateStart, dateEnd);
			const seancesByDay = _.toArray(seancesInHall);

			return _.map(dates, (date, index) => {
				const isDaySameAsPrev = index > 0 &&
					isTwoDaysSeancesSimilar(seancesByDay[index], seancesByDay[index - 1]);

				const similarDays = getSimilarDaysArray(seancesInHall, date);

				const similarDaysLength = !isDaySameAsPrev
					? similarDays.length
					: 1;

				return {
					date_start: date,
					isSameAsPrev: isDaySameAsPrev,
					similarDaysLength,
					similarDays,
				};
			});
		},
	)
);

export const getSimilarSeances = (seancesInHall, seance) => {
	const similarDays = getSimilarDaysArray(seancesInHall, seance.date_start);

	return _.map(
		similarDays,
		day => _.findWhere(seancesInHall[day], {time_start: seance.time_start}) ||
			_.findWhere(seancesInHall[day], {position: seance.position}),
	);
};

export const getSeancesGroupedByChangingDates = (seancesInHallForFullWeek, seances, changingDaysArray) => {
	const positions = _.pluck(seances, 'position');

	return _.chain(changingDaysArray)
		.map(dateStart => _.filter(
			seancesInHallForFullWeek[dateStart],
			seance => _.contains(positions, seance.position),
		))
		.flatten()
		.groupBy('date_start')
		.value();
};

export const getSeancesUntilTheEnd = (seances, seance) => _.filter(seances, currentSeance =>
	moment.unix(currentSeance.date).isSameOrAfter(moment.unix(seance.date)));

export const getSeancesBeforeFirstBlocked = seances => (seances.length ? _.first(
	seances,
	((_.find(seances, currentSeance => !canMoveSeance(currentSeance)) || {}).position || Infinity)
		- seances[0].position,
) : []);

export const isAnySeanceOnSale = createSelector(
	getSeances, getSeancesGrouped, getEditOnlyCurrentDay, getChosenWeekday,
	(seances, seancesGrouped, editOnlyCurrentDay, chosenWeekday) => {
		const daySeances = _.reduce(Object.values(seancesGrouped), (basket, keySeancesGrouped) =>
			basket.concat(Object.values(keySeancesGrouped)[chosenWeekday]), []);

		return	_.any(editOnlyCurrentDay ? daySeances : seances, isOnSaleSeance);
	},
);

export const isAnySeanceApproved = createSelector(
	[getSeances],
	seances => _.any(seances, seance => (seance || {}).is_approved),
);

export const hasAnySeanceSameEndOrStart = (seances, seance) => {
	const seanceTimeEndWithoutPause = moment.unix(seance.date).utc()
		.add(getSeanceDurationWithoutPause(seance), 'minutes');
	const seanceTimeEndWithPause = seanceTimeEndWithoutPause.clone()
		.add(seance.duration.pause, 'minutes');

	return _.any(makeGetSeancesForDate().resultFunc(seances, seance.date_start), daySeance => {
		const daySeanceTimeEndWithoutPause = moment.unix(daySeance.date).utc()
			.add(getSeanceDurationWithoutPause(daySeance), 'minutes');
		const daySeanceTimeEndWithPause = daySeanceTimeEndWithoutPause.clone()
			.add(daySeance.duration.pause, 'minutes');

		return (daySeance.hall_id !== seance.hall_id && (
			seanceTimeEndWithPause.isSame(daySeanceTimeEndWithPause) ||
			seanceTimeEndWithoutPause.isSame(daySeanceTimeEndWithoutPause)
		));
	});
};

export const getAdvertising = state => state.scheduleReducer.scheduleMainReducer.present.advertising;
export const getSeanceAdvertisingIDs = (state, props) => props.seance.advertising;

export const getAdvertisingHash = createSelector(
	getAdvertising,
	advertising => _.indexBy(advertising, 'id'),
);

export const getSeanceAdvertisingByIDs = (advertisingHash, ids) => ids.reduce((memo, id) => {
	if (advertisingHash[id]) {
		memo.ad.push(advertisingHash[id]);
		memo.totalDurationWithIntegratedTrailers = memo.totalDurationWithIntegratedTrailers +
			_.get(advertisingHash[id], 'duration', 0);
	}

	return memo;
}, {ad: [], totalDurationWithIntegratedTrailers: 0});

export const makeGetAdvertisingByIDs = () => createSelector(
	[getAdvertisingHash, getSeanceAdvertisingIDs],
	(advertising, ids) => getSeanceAdvertisingByIDs(advertising, ids),
);

export const getIsAdvertisingWrongDuration = seance => {
	const durationTotalAdv = _.get(seance, 'duration_total.advertising', 0);
	const seanceAdv = _.get(seance, 'duration.advertising', 0);

	return durationTotalAdv > seanceAdv * 60;
};

const getIsDisabledForCastling = (seances, hallFormatsID) => createSelector(
	getUpgradeFormatsIDs,
	upgradeFormatsID => seances.some(seance => !canMoveSeance(seance)
		|| isOnSaleForAllTicketSystems(seance)
		|| doesSeanceHasSolds(seance))
	|| !isHallSupportsFormats(seances, hallFormatsID, upgradeFormatsID),
);

export const getOptionsForCastling = (currentHallID, currentDay) => createSelector(
	getCurrentCinemaHalls,
	getEditOnlyCurrentDay,
	state => hasHallLicense(currentHallID)(state),
	state => getHall(state, 0, currentHallID),
	state => hallID => getSeancesInHall(hallID)(state),
	state => (seances, hallFormats) => getIsDisabledForCastling(seances, hallFormats)(state),
	// eslint-disable-next-line max-params
	(hallsInCinema, editOnlyCurrentDay, hasLicense, currentHall, seancesInHall, getIsDisabled) => {
		const days = editOnlyCurrentDay
			? [currentDay]
			: getDatesBetweenStartEnd(
				moment(currentDay).startOf('week').format(),
				moment(currentDay).endOf('week').format(),
			);
		const firstSeances = getAllSeancesForCastling(seancesInHall(currentHallID), days);
		const firstFormats = currentHall.formats_id;

		return _.without(hallsInCinema, currentHall).map(({id, title, number, formats_id: secondFormats}) => {
			const secondSeances = getAllSeancesForCastling(seancesInHall(id), days);
			const isFirstDisabled = firstSeances
				? getIsDisabled(firstSeances, secondFormats) : false;
			const isSecondDisabled = secondSeances
				? getIsDisabled(secondSeances, firstFormats) : false;

			return {
				value: id,
				title: `${i18n.t('common.Hall')} ${title || number}`,
				isDisabled: isFirstDisabled || isSecondDisabled || !hasLicense,
			};
		});
	},
);

export const makeGetDataForExchangeRequest = (firstHallID, secondHallID, dateStart, dateEnd) => createSelector(
	getEditOnlyCurrentDay,
	state => hallID => getSeancesInHall(hallID)(state),
	(editOnlyCurrentDay, seancesInHall) => {
		const days = editOnlyCurrentDay ? [dateStart] :	getDatesBetweenStartEnd(dateStart, dateEnd);
		const firstSeances = seancesInHall(firstHallID);
		const secondSeances = seancesInHall(secondHallID);

		if (!firstSeances && !secondSeances) return {};

		return {
			seancesToDelete: [
				...getAllSeancesForCastling(firstSeances || {}, days),
				...getAllSeancesForCastling(secondSeances || {}, days),
			],
			seancesToPatch: [
				...getPatchDataForCastling(firstSeances || {}, secondHallID, days),
				...getPatchDataForCastling(secondSeances || {}, firstHallID, days),
			],
		};
	},
);

export const hasAnySeanceWithClosedSale = createSelector(
	getSeances,
	seances => seances.length && !!seances.find(seance => seance.sale.is_closed && seance.sale.is_sold),
);

/**
 * Is schedule seance planned for this week in repertory
 * @param {Object} release - Release from repertory
 * @param {Object} seance
 * @param {boolean} [checkPlannedNumber=false] - If specified checks if number of planned seances is more than 0
 * @returns {boolean} isSeancePlanned
 */
export const isSeancePlanned = (release, seance, checkPlannedNumber = false) => Boolean(seance.release_id === 0 || // Empty seance
	release.weeks &&
	_.find(release.weeks, week => (
		_.isEqual(week.formats, seance.formats) &&
		_.isEqual(week.voiceover_language_id, seance.voiceover_language_id) &&
		moment(seance.date_start).isSame(week.date_start, 'w') &&
		(!checkPlannedNumber || week.count > 0)
	)));

export const getSeancePlanningError = (release, seance, seanceFormats, checkPlannedNumber) => {
	if (!isSeancePlanned(release, seance)) {
		return [
			_.getLocalize(release.title),
			seanceFormats,
			i18n.t('ScheduleGeneral.noPlanningRelease'),
		].join(' ');
	} if (checkPlannedNumber && !isSeancePlanned(release, seance, checkPlannedNumber)) {
		return [i18n.t('schedule:NoPlanningSeance'), _.getLocalize(release.title), seanceFormats].join(' ');
	}

	return '';
};

export const getDaysToPatch = (seancesInHallForFullWeek, editOnlyCurrentDay, dateStart) => {
	const dateEnd = editOnlyCurrentDay ? dateStart : _.last(getSimilarDaysArray(seancesInHallForFullWeek, dateStart));

	return getDatesBetweenStartEnd(dateStart, dateEnd);
};

export const getMaxDurationForEmptySeance = (seancesInHallForDay, seance) => {
	if ((seance || {}).id !== (_.last(seancesInHallForDay) || {}).id && seancesInHallForDay.length > 1) {
		return seance.duration.release + seance.duration.pause;
	}

	return 500;
};

export const calculateSeancePause = (seance, newDuration) => {
	const {duration: {release, pause}} = seance;
	if (release + pause <= newDuration) {
		return 0;
	}
	if (release < newDuration) {
		return pause - (newDuration - release);
	}

	return pause + (release - newDuration);
};

const getDurationType = (state, props) => props.durationType;
export const makeCanIncreaseSeanceDuration = () => createSelector(
	[canUserEditSchedule, getSeance, getDurationType, getNextSeance],
	(canUserEditScheduleInCinema, seance, durationType, nextSeance) => {
		const canMoveNextSeance = nextSeance ? canMoveSeance(nextSeance) : true;

		if (durationType === 'advertising') {
			return canUserEditScheduleInCinema &&
				canMoveSeance(seance) &&
				(canMoveNextSeance || seance.duration.pause > 0);
		}

		return canUserEditScheduleInCinema && !seance.is_approved && canMoveNextSeance;
	},
);

export const makeCanDecreaseSeanceDuration = () => createSelector(
	[canUserEditSchedule, getSeance, getDurationType, getNextSeance],
	(canUserEditScheduleInCinema, seance, durationType, nextSeance) => {
		const canMoveNextSeance = nextSeance ? canMoveSeance(nextSeance) : true;

		if (durationType === 'advertising') {
			return canUserEditScheduleInCinema && canMoveSeance(seance) && seance.duration.advertising > 0;
		}

		return canUserEditScheduleInCinema && !seance.is_approved && canMoveNextSeance && seance.duration.pause >= 5;
	},
);

const getUCSErrorTranslation = (seance, errorID, referenceFormats, hallTitle) => i18n.t(
	[
		`schedule:UCSErrors.${errorID}`,
		'schedule:UCSErrors.Unknown',
	],
	{
		hallID: seance.hall_id,
		formats: getShortFormat(referenceFormats, seance.formats, true),
		hallTitle,
	},
);

export const getScheduleUCSErrorTooltipOverlay = (seancesForHallForDay, dateStart, referenceFormats, hallTitle) => {
	const seancesWithUCS = _.filter(seancesForHallForDay, seance =>
		!_.isEmpty(seance.ucs_status) && seance.is_approved);

	if (_.isEmpty(seancesWithUCS)) {
		return null;
	}

	const firstSeance = _.first(seancesWithUCS);
	const commonError = firstSeance.ucs_status.status === 'error'
		? getUCSErrorTranslation(firstSeance, firstSeance.ucs_status.message_id, referenceFormats, hallTitle)
		: null;

	const seancesWithErrorsMessage = seancesWithUCS
		.filter(seance => (seance.ucs_status.seance_status || {}).status === 'error')
		.map(seance => ({
			errorMessage: getUCSErrorTranslation(seance, seance.ucs_status.seance_status.message_id, referenceFormats, hallTitle),
			timeStart: seance.time_start,
		}))
		.filter(seance => !_.isEmpty(seance.errorMessage));

	const hasCommonError = !_.isEmpty(commonError);
	const hasSeancesError = !_.isEmpty(seancesWithErrorsMessage);

	return hasCommonError || hasSeancesError ? (
		<div>
			{hasCommonError &&
				<div>
					<span className="text text--x-small text--red">
						{`${i18n.t('notifications.CommonError')}:`}
						{' '}
					</span>
					<span className="text text--x-small">
						{commonError}
					</span>
					{hasSeancesError && <hr />}
				</div>
			}
			<div>
				{seancesWithErrorsMessage.map(seance => (
					<div className="text text--x-small" key={seance.id}>
						<span className="text text--x-small text--bold">
							{moment(dateStart).format('DD.MM.YYYY')}
							{' '}
							{i18n.t('preposition.in')}
							{' '}
							{`${seance.timeStart}:`}
						</span>
						{' '}
						{seance.errorMessage}
					</div>
				))}
			</div>
		</div>
	) : null;
};

export const hasSeanceUCSStatus = (isCinemaPremiere, seance) => {
	const hasCommonStatus = !_.isEmpty((seance.ucs_status || {}).status);
	const hasSenceStatus = _.isEmpty(_.get(seance.ucs_status, 'seance_status.status'));

	return isCinemaPremiere && (hasSenceStatus || hasCommonStatus);
};

export const getUCSStatus = (isCinemaPremiere, seance) => (
	hasSeanceUCSStatus(isCinemaPremiere, seance)
		? _.get(seance, 'ucs_status.seance_status.status') || _.get(seance, 'ucs_status.status')
		: ''
);
