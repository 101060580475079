import {
	IRelease,
	IUserNote,
	ReleaseWeek,
	IReleaseAnalytics,
	ILastOrder,
	ReleaseWeekStatus,
	IReleaseCardState,
	ReleaseCardPerson, AltInfo,
} from 'app/release_card/releaseCardTypes';
import * as types from 'app/release_card/releaseCardActionTypes';

export const setRelease = (release: IRelease & { id: number }) => ({
	type: types.RELEASE_CARD_SET_RELEASE,
	payload: release,
});

export const setIsFetching = (payload: boolean) => ({
	type: types.RELEASE_CARD_SET_IS_FETCHING,
	payload,
});

export const addReleaseCardNote = (note: IUserNote, id: string) => ({
	type: types.RELEASE_CARD_ADD_NOTE,
	payload: { note, id },
});

export const deleteReleaseCardNote = (noteID: string) => ({
	type: types.RELEASE_CARD_DELETE_NOTE,
	payload: noteID,
});

export const updateReleaseCardNote = (id: string, text: string) => ({
	type: types.RELEASE_CARD_UPDATE_NOTE,
	payload: { id, text },
});

export const setReleaseCardCover = (cover: string) => ({
	type: types.RELEASE_CARD_SET_COVER,
	payload: cover,
});

export const setReleaseWeeks = (weeks: ReleaseWeek[]) => ({
	type: types.RELEASE_CARD_SET_WEEKS,
	payload: weeks,
});

export const addReleaseWeeks = (weeks: ReleaseWeek[]) => ({
	type: types.RELEASE_CARD_ADD_NEW_WEEKS,
	payload: weeks,
});

export const deleteReleaseWeeks = (weeksIDs: string[], cinemaID: number) => ({
	type: types.RELEASE_CARD_DELETE_WEEKS,
	payload: {
		weeksIDs,
		cinemaID,
	},
});

export const updateReleaseWeeks = (weeks: ReleaseWeek[]) => ({
	type: types.RELEASE_CARD_UPDATE_WEEKS,
	payload: weeks,
});

export const massWeekUpdate = (weeks: ReleaseWeek[]) => ({
	type: types.RELEASE_CARD_MASS_UPDATE,
	payload: weeks,
});

export const changeReleaseWeeksStatus = (cinemaID: number, status: ReleaseWeekStatus) => ({
	type: types.RELEASE_CARD_CHANGE_WEEKS_STATUS,
	payload: { cinemaID, status },
});

export const setMyCinemas = (cinemasIDs: number[]) => ({
	type: types.RELEASE_CARD_ADD_MY_CINEMA,
	payload: cinemasIDs,
});

export const setReleaseDuration = (type: string, newDuration: number) => ({
	type: types.RELEASE_CARD_SET_DURATION,
	payload: { type, newDuration },
});

export const setReleaseActors = (payload: IRelease['actors']) => ({
	type: types.RELEASE_CARD_SET_ACTORS,
	payload,
});

export const setReleaseAnalytics = (releaseAnalytics: IReleaseAnalytics[]) => ({
	type: types.RELEASE_CARD_SET_ANALYTICS,
	payload: { releaseAnalytics },
});

export const setPersonsList = (payload: Partial<IReleaseCardState>) => ({
	type: types.RELEASE_CARD_SET_PERSONS_LIST,
	payload,
});

export const addPerson = (payload: ReleaseCardPerson) => ({
	type: types.RELEASE_CARD_ADD_PERSON,
	payload,
});

export const setReleaseLastOrders = (order: ILastOrder) => ({
	type: types.RELEASE_CARD_SET_LAST_ORDER,
	payload: order,
});

export const setReleaseLanguageID = (payload?: number) => ({
	type: types.RELEASE_CARD_SET_LANGUAGE_ID,
	payload,
});

export const setReleaseAltInfos = (payload: AltInfo[]) => ({
	type: types.RELEASE_CARD_SET_ALT_INFOS,
	payload,
});

export const resetReleaseCard = () => ({ type: types.RELEASE_CARD_RESET });
