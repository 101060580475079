export const SET_PLAYLISTS_CONTENT = 'SET_PLAYLISTS_CONTENT_LEGACY';
export const SET_PLAYLISTS_PLAYSERVERS = 'SET_PLAYLISTS_PLAYSERVERS_LEGACY';
export const SET_PLAYLISTS_PLAYLIST_CHANGES = 'SET_PLAYLISTS_PLAYLIST_CHANGES_LEGACY';
export const SET_PLAYLISTS_PLAYLIST_TRANSMISSIONS_CHANGES = 'SET_PLAYLISTS_PLAYLIST_TRANSMISSIONS_CHANGES_LEGACY';
export const SET_PLAYLISTS_PLAYLIST_ADDITIONAL_CHANGES = 'SET_PLAYLISTS_PLAYLIST_ADDITIONAL_CHANGES_LEGACY';

export const PLAYLISTS_CHANGE_DATE = 'PLAYLISTS_CHANGE_DATE';
export const PLAYLISTS_UPDATE = 'PLAYLISTS_UPDATE_LEGACY';
export const PLAYLISTS_ADD = 'PLAYLISTS_ADD_LEGACY';
export const PLAYLISTS_DELETE = 'PLAYLISTS_DELETE_LEGACY';

export const PUT_PLAYLISTS_HISTORY_UPLOAD_REQUEST = 'PUT_PLAYLISTS_HISTORY_UPLOAD_REQUEST_LEGACY';
export const PLAYLIST_CLEAR_PLAYLIST_CHANGES = 'PLAYLIST_CLEAR_PLAYLIST_CHANGES_LEGACY';
export const PLAYLIST_CLEAR_PLAYLIST_ADDITIONAL_CHANGES = 'PLAYLIST_CLEAR_PLAYLIST_ADDITIONAL_CHANGES_LEGACY';
export const PUT_PLAYLISTS_HISTORY_UPLOAD_SUCCESS = 'PUT_PLAYLISTS_HISTORY_UPLOAD_SUCCESS_LEGACY';
export const PUT_PLAYLISTS_HISTORY_UPLOAD_FAIL = 'PUT_PLAYLISTS_HISTORY_UPLOAD_FAIL_LEGACY';
export const PLAYLIST_SET_CHANNEL_VALUES = 'PLAYLIST_SET_CHANNEL_VALUES_LEGACY';
export const PLAYLIST_SET_AUTO_UPLOAD_INFO = 'PLAYLIST_SET_AUTO_UPLOAD_INFO_LEGACY';
export const PLAYLIST_SET_INITIAL_RELEASE = 'PLAYLIST_SET_INITIAL_RELEASE_LEGACY';

export const SET_PLAYLISTS_FETCHING_STATUS = 'SET_PLAYLISTS_FETCHING_STATUS_LEGACY';
export const CHECK_PLAYLISTS_GENERATION_SUCCESS = 'CHECK_PLAYLISTS_GENERATION_SUCCESS_LEGACY';
export const SET_FETCHING_GENERATION = 'SET_FETCHING_GENERATION_LEGACY';

export const PLAYLISTS_DELETE_LIGAMENT = 'PLAYLISTS_DELETE_LIGAMENT_LEGACY';

export const SET_UPLOAD_PLAYLISTS_TIMEOUTS = 'SET_UPLOAD_PLAYLISTS_TIMEOUTS_LEGACY';
export const CLEAR_UPLOAD_PLAYLISTS_TIMEOUTS = 'CLEAR_UPLOAD_PLAYLISTS_TIMEOUTS_LEGACY';
export const SET_LAST_UCS_DOWNLOAD_DATE = 'SET_LAST_UCS_DOWNLOAD_DATE_LEGACY';
export const SET_GENERATING_DAYS_BY_HALL = 'SET_GENERATING_DAYS_BY_HALL_LEGACY';
export const REMOVE_GENERATING_DAY_BY_HALL = 'REMOVE_GENERATING_DAY_BY_HALL_LEGACY';
export const RESET_STATUS_GENERATING = 'RESET_STATUS_GENERATING_LEGACY';
export const SET_IS_PACKAGE_CHANGED_FOR_SIMILAR_PLAYLISTS = 'SET_IS_PACKAGE_CHANGED_FOR_SIMILAR_PLAYLISTS_LEGACY';
export const SET_GENERATING_DAYS = 'SET_GENERATING_DAYS_LEGACY';

// Single playlist page
export const PLAYLIST_SET_FLAG_VALUE = 'PLAYLIST_SET_FLAG_VALUE_LEGACY';
export const PLAYLIST_SET_DATA = 'PLAYLIST_SET_DATA_LEGACY';
export const PLAYLIST_SET_PRELOAD_ACCESS_STATUS = 'PLAYLIST_SET_PRELOAD_ACCESS_STATUS_LEGACY';
export const PLAYLIST_SET_CUES = 'PLAYLIST_SET_CUES_LEGACY';
export const PLAYLIST_RESET_DATA = 'PLAYLIST_RESET_DATA';

export const PLAYLIST_INITIALIZE_SOCKET_QUEUE = 'PLAYLIST_INITIALIZE_SOCKET_QUEUE_LEGACY';
export const PLAYLIST_APPLY_SOCKET_QUEUE = 'PLAYLIST_APPLY_SOCKET_QUEUE_LEGACY';
export const PLAYLIST_ADD_TO_SOCKET_QUEUE = 'PLAYLIST_ADD_TO_SOCKET_QUEUE_LEGACY';

export const PlaylistFlags = {
	playlistChanged: 'isPlaylistChanged',
	applyToWeek: 'isAppliedForWeek',
	applyToSamePlaylists: 'isAppliedForAllPlaylists',
	applyToSamePlaylistsInHall: 'isAppliedForAllPlaylistsInHall',
	hasAdditionalBlock: 'has_additional',
};

export const TMSTitles = {
	'dcp24': 'DCP24',
	'rosettabridge': 'Rosetta Bridge',
};

export const PlaylistsAPIRootURL = '/api/tms/shows/v1';

export const PlaylistsApiURLv2 = '/api/tms/shows/v2';

export const ContentCriticalStatuses = [
	'error',
	'missing',
	'incompleted',
	'link_required',
	'storage_limit',
	'remove',
];

export const ContentStatusTexts = {
	missing: 'tmsPlaylistsLegacy:ContentMissing',
	incompleted: 'tmsPlaylistsLegacy:ContentIncompleted',
	waiting: 'tmsPlaylistsLegacy:ContentWaiting',
	remove: 'tmsPlaylistsLegacy:ContentWaiting',
	copying: 'tmsPlaylistsLegacy:ContentCopying',
	queued: 'tmsPlaylistsLegacy:ContentWillBeCopied',
	error: 'tmsPlaylistsLegacy:ContentError',
	link_required: 'tmsPlaylistsLegacy:LinkRequired',
	storage_limit: 'tmsPlaylistsLegacy:NotEnoughSpace',
};

export const upgrades = {
	UPG_DBOX: 'DBOX',
	UPG_SUB: 'SUB',
	UPG_ATMOS: 'ATMOS',
	UPG_ATMOS_3D: 'ATMOS 3D',
	UPG_AURO: 'AURO',
	UPG_AURO_3D: 'AURO 3D',
	UPG_4DX: '4DX',
	UPG_SCREENX: 'SCREENX',
};

export const IgnoredUpgrades = _.pick(upgrades, 'UPG_SCREENX');

export const MIN_DURATION_BASIC_PACKAGE = 1200; // 20 minutes
