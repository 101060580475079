export const SET_PLAYSERVERS = 'SET_PLAYSERVERS' as const;
export const SET_PLAYLISTS = 'SET_PLAYLISTS' as const;

export const PLAYLISTS_CHANGE_DATE = 'PLAYLISTS_CHANGE_DATE' as const;
export const PLAYLISTS_UPDATE = 'PLAYLISTS_UPDATE' as const;
export const PLAYLISTS_NEW_UPDATE = 'PLAYLISTS_NEW_UPDATE' as const;
export const PLAYLISTS_UPDATE_GENERATION_STATUS = 'PLAYLISTS_UPDATE_GENERATION_STATUS' as const;
export const PLAYLISTS_SET_UPLOADING_DATA = 'PLAYLISTS_SET_UPLOADING_DATA' as const;
export const PLAYLISTS_ADD = 'PLAYLISTS_ADD' as const;
export const PLAYLISTS_DELETE = 'PLAYLISTS_DELETE' as const;

export const SET_PLAYLISTS_FETCHING_STATUS = 'SET_PLAYLISTS_FETCHING_STATUS' as const;
export const CHECK_PLAYLISTS_GENERATION_SUCCESS = 'CHECK_PLAYLISTS_GENERATION_SUCCESS' as const;
export const SET_FETCHING_GENERATION = 'SET_FETCHING_GENERATION' as const;

export const SET_UPLOAD_PLAYLISTS_TIMEOUTS = 'SET_UPLOAD_PLAYLISTS_TIMEOUTS' as const;
export const CLEAR_UPLOAD_PLAYLISTS_TIMEOUTS = 'CLEAR_UPLOAD_PLAYLISTS_TIMEOUTS' as const;
export const SET_LAST_UCS_DOWNLOAD_DATE = 'SET_LAST_UCS_DOWNLOAD_DATE' as const;
export const SET_GENERATING_DAYS_BY_HALL = 'SET_GENERATING_DAYS_BY_HALL' as const;
export const REMOVE_GENERATING_DAY_BY_HALL = 'REMOVE_GENERATING_DAY_BY_HALL' as const;
export const RESET_STATUS_GENERATING = 'RESET_STATUS_GENERATING' as const;
export const SET_GENERATING_DAYS = 'SET_GENERATING_DAYS' as const;

export const PLAYLIST_SET_PRELOAD_ACCESS_STATUS = 'PLAYLIST_SET_PRELOAD_ACCESS_STATUS' as const;

export const PLAYLIST_INITIALIZE_SOCKET_QUEUE = 'PLAYLIST_INITIALIZE_SOCKET_QUEUE' as const;
export const PLAYLIST_APPLY_SOCKET_QUEUE = 'PLAYLIST_APPLY_SOCKET_QUEUE' as const;
export const PLAYLIST_ADD_TO_SOCKET_QUEUE = 'PLAYLIST_ADD_TO_SOCKET_QUEUE' as const;

export const TMSTitles = {
	dcp24: 'DCP24',
	rosettabridge: 'Rosetta Bridge',
};

export const PlaylistsAPIRootURL = '/api/tms/shows/v1';

export const PlaylistsApiURLv2 = '/api/tms/shows/v2';

export const ContentCriticalStatuses = [
	'error',
	'missing',
	'incompleted',
	'link_required',
	'storage_limit',
	'remove',
];

export const ContentStatusTexts = {
	missing: 'tmsPlaylists:ContentMissing',
	incompleted: 'tmsPlaylists:ContentIncompleted',
	waiting: 'tmsPlaylists:ContentWaiting',
	remove: 'tmsPlaylists:ContentWaiting',
	copying: 'tmsPlaylists:ContentCopying',
	queued: 'tmsPlaylists:ContentWillBeCopied',
	error: 'tmsPlaylists:ContentError',
	link_required: 'tmsPlaylists:LinkRequired',
	storage_limit: 'tmsPlaylists:NotEnoughSpace',
};

export const upgrades = {
	UPG_DBOX: 'DBOX',
	UPG_SUB: 'SUB',
	UPG_ATMOS: 'ATMOS',
	UPG_ATMOS_3D: 'ATMOS 3D',
	UPG_AURO: 'AURO',
	UPG_AURO_3D: 'AURO 3D',
	UPG_4DX: '4DX',
	UPG_SCREENX: 'SCREENX',
};

export const IgnoredUpgrades = _.pick(upgrades, 'UPG_SCREENX');
