import {createSelector} from 'reselect';
import {currentCinemaTimeZone} from 'app/user/userSelectors';

const currentDate = state => state.playlistsLegacyReducer.PlaylistsMainReducer.date;

export const getMomentDate = createSelector(
	[currentDate, currentCinemaTimeZone],
	(date, cinemaTimeZone) => {
		if (date) return moment(date);

		return moment().utcOffset(cinemaTimeZone).startOf('day').startOf('week').format();
	},
);

export default createSelector(
	[currentDate, currentCinemaTimeZone],
	(cinemaDate, cinemaTimeZone) => cinemaDate || moment().utcOffset(cinemaTimeZone).startOf('day').startOf('week').format(),
);
