import {getDistributorDefaults} from 'app/distributors/DistributorsConstants';
import {
	isDistributor, getDistributorID, getUserID, atLeastOneCinemaMaster,
} from 'app/user/userSelectors';
import {createSelector} from 'reselect';

export const getDistributors = state => state.distributorsReducer.distributors;
export const getDistributorPartners = state => state.distributorsReducer.partners;
export const getDistributorCompetitors = state => state.distributorsReducer.competitors;

export const getForeignDistributors = state => (
	_.reject(getDistributors(state), distributor => distributor.id === getDistributorID(state) || distributor.type === 'custom')
);
export const getCustomDistributors  = createSelector(
	[getDistributors],
	distributors => _.filter(distributors, distributor => distributor.type === 'custom'),
);

export const getCurrentDistributor = (state, distributorID) =>
	_.findWhere(getDistributors(state), {id: parseInt(distributorID, 10)}) ||
	getDistributorDefaults();

export const getDistributorForm = (state, isCreate, distributorID) => (
	isCreate ? getDistributorDefaults() :
		_.findWhere(getCustomDistributors(state), {id: parseInt(distributorID, 10)})
);
export const canCreateDistributor = state => (
	!isDistributor(state) && atLeastOneCinemaMaster(state)
);
export const isDistributorEditable = (state, distributor) => (
	canCreateDistributor(state) && getUserID(state) === distributor.user_id &&
	distributor.type === 'custom'
);

export const getDistributorRelations = createSelector(
	[getForeignDistributors, getDistributorPartners, getDistributorCompetitors],
	(distributors, partners, competitors) => (
		_.map(distributors, distributor => {
			let relation = '';

			if (_.contains(partners, distributor.id)) {
				relation = 'partner';
			} else if (_.contains(competitors, distributor.id)) {
				relation = 'competitor';
			}

			return {...distributor, relation};
		})
	),
);

export const getSortedDistributors = createSelector(
	[getDistributors],
	distributors => {
		const {withReleases, withoutReleases} = _.groupBy(
			distributors,
			dist => (dist.count_releases ? 'withReleases' : 'withoutReleases'),
		);

		return [...(withReleases || []), ...(withoutReleases || [])];
	},
);
