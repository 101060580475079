export const RELEASES_LIST_SET_ROUTE = 'RELEASES_LIST_SET_ROUTE' as const;
export const RELEASES_LIST_SET_RELEASES = 'RELEASES_LIST_SET_RELEASES' as const;
export const RELEASES_LIST_UPDATE_RELEASE = 'RELEASES_LIST_UPDATE_RELEASE' as const;
export const RELEASES_LIST_ADD_RELEASES = 'RELEASES_LIST_ADD_RELEASES' as const;
export const RELEASES_LIST_SET_DISTRIBUTORS = 'RELEASES_LIST_SET_DISTRIBUTORS' as const;
export const RELEASES_LIST_SET_DISTRIBUTOR_FILTER = 'RELEASES_LIST_SET_DISTRIBUTOR_FILTER' as const;
export const RELEASES_LIST_SET_FORMAT_FILTER = 'RELEASES_LIST_SET_FORMAT_FILTER' as const;
export const RELEASES_LIST_TOGGLE_FILTER = 'RELEASES_LIST_TOGGLE_FILTER' as const;
export const RELEASES_LIST_SET_REGION_FILTER = 'RELEASES_LIST_SET_REGION_FILTER' as const;
export const RELEASES_LIST_SET_START_VISIBLE_DATE = 'RELEASES_LIST_SET_START_VISIBLE_DATE' as const;
export const RELEASES_LIST_ADD_RELEASES_TO_FAVORITES = 'RELEASES_LIST_ADD_RELEASES_TO_FAVORITES' as const;
export const RELEASES_LIST_DELETE_RELEASES_FROM_FAVORITES = 'RELEASES_LIST_DELETE_RELEASES_FROM_FAVORITES' as const;
export const RELEASES_LIST_SET_RELEASES_TO_FAVORITES = 'RELEASES_LIST_SET_RELEASES_TO_FAVORITES' as const;
export const RELEASES_LIST_SET_IS_FETCHING = 'RELEASES_LIST_SET_IS_FETCHING' as const;
export const RELEASES_LIST_SET_SCROLL_TOP = 'RELEASES_LIST_SET_SCROLL_TOP' as const;
export const RELEASES_LIST_SET_IS_RELEASE_DELETED = 'RELEASES_LIST_SET_IS_RELEASE_DELETED' as const;
export const RELEASES_LIST_SET_POSTER_FOR_NEW_RELEASE = 'RELEASES_LIST_SET_POSTER_FOR_NEW_RELEASE' as const;
