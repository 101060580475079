import Cookies from 'js-cookie';
import merge from 'merge';
import Request from 'app/common/Request';
import { isNewYearHolidays } from 'app/user/userSelectors';
import { setUser, logoutUserRequest } from 'app/user/actions/UserActions';
import { getUnitedNetworkCinemaID } from 'app/user/helpers/userCinemaHelpers';
import get from 'lodash.get';
import { referenceRequest } from 'app/ReferenceRequest';
import { setReference } from 'app/ReferenceActions';
import { setDistributorRelations } from 'app/distributors/DistributorsActions';
import { batch } from 'react-redux';
import { parseUser } from 'app/user/helpers/userHelpers';
import { redirectToAuth } from 'app/authentication/common';
import { getLocalize, sortByNat } from 'app/mixins';
import { ICinema, UserState } from 'app/user/UserTypes';

function parseUserCinemas(cinemas: ICinema[]) {
	return sortByNat(
		cinemas.map(cinema => ({
			...cinema,
			id: cinema.id,
			rights_id: cinema.rights_id,
			dd24_pro: cinema.dd24_pro,
			halls: _.chain(cinema.halls)
				.map(hall => ({
					...hall,
					title: hall.title || '',
				}))
				.sortBy(hall => hall.number)
				.value(),
			subscriptions: merge.recursive(true, { booking: { status: 'inactive' }}, cinema.subscriptions),
		})),
		cinema => getUnitedNetworkCinemaID(cinema) || getLocalize(cinema.title) || '');
}

export default class User {
	user: Partial<UserState>;
	_cinemas: Record<number, ICinema> = {};

	constructor() {
		this.user = this.getDefaults();
	}

	async getUser(store) {
		try {
			const updatedUser = await new Request<UserState>().get(this.getUrl());
			const updatedReference = await referenceRequest();
			await this.checkTimezone();
			const dispatchIfNeeded = store ? store.dispatch : _.noop;
			this.user = this.parse(updatedUser);

			batch(() => {
				dispatchIfNeeded(setUser(parseUser(this)));
				dispatchIfNeeded(setReference(updatedReference));
				dispatchIfNeeded(setDistributorRelations(this.user.partners, this.user.competitors));
			});
		} catch (error) {
			if (error.status === 401) redirectToAuth();
		}
	}

	async checkTimezone() {
		const timezone = new Date();
		const userTimezone = -timezone.getTimezoneOffset() / 60;

		if (!this.get('timezone') || userTimezone !== this.get('timezone')) {
			await new Request().patch('/api/user/v2/edit', {
				timezone: userTimezone,
			});
		}

		if (this.get('logout')) logoutUserRequest();
	}

	getDefaults(): Partial<UserState> {
		return {
			notes: [],
			cinema: [],
			cinema_tms: [],
			cinemasData: {},
			user_position: '',
			mobile_settings: {
				push_settings: {
					on_keys: true,
					on_pinned_message: true,
					on_releases: true,
					on_shipments: true,
				},
			},
			tms_settings: {
				send_auto_cancel_notification: true,
			},
			email_settings: {
				send_license_notifications: true,
			},
			approved: 0,
			features: {
				access_autoupload: 0,
				mass_scheduling: 0,
				campaign_import: 0,
				access_tms_cues_catalog: 0,
				access_tms_playlist_templates: 0,
			},
			last_read_chat: 0,
			photo: '',
			photo_medium: '',
			photo_big: '',
			type: '',
			service: {
				level: 0,
			},
			feature_awareness: {
				birth_and_position: 0,
				ideas_feedback: 0,
				news_feed: 0,
				tutorial: 0,
			},
			halls_licenses: {},
			error: {},
		};
	}

	getUrl() {
		return `/api/user/v2/0?nocache_${(Math.random() * 10 ** 8).toFixed()}`;
	}

	pick(fields) {
		return _.pick(this.user, fields);
	}

	parse(response = {}) {
		const user: UserState = merge.recursive(true, this.getDefaults(), response);

		const parsedCinemas = parseUserCinemas(user.cinema);

		_.each(parsedCinemas, cinema => {
			this._cinemas[cinema.id] = cinema;
		});

		const currentCinema = parsedCinemas.length ? _.find(
			_.pluck(parsedCinemas, 'id'),
			cinemaID => cinemaID === parseInt(Cookies.get('currentCinema')|| '', 10),
		) || parsedCinemas[0].id : null;

		return {
			...user,
			distributor_id: user.distributor_id,
			cinema: parsedCinemas,
			current: {
				cinema_id: currentCinema,
			},
			cinema_rights: _.map(user.cinema_rights, rights =>
				({ ...rights, cinema_id: rights.cinema_id })),
			cinema_relations: _.map(user.cinema_relations, rel => _.omit(rel, '_id')),
			notes: Array.isArray(user.notes) ? user.notes : [],
		};
	}

	get(field) {
		return get(this.user, field, null);
	}

	getPages() {
		const allowedPages = this.get('allowed_pages');

		if (allowedPages) return {
			analytics: !!allowedPages.analytics,
			ad_network: !!this.accessTo('ad_network'),
			cinemasDashboard: !!allowedPages.cinemas_dashboard,
			dashboard_sales: !!allowedPages.dashboard_sales,
			cinema_pulse: !!allowedPages.dashboard_sales,
			commercials: !!allowedPages.commercials,
			dashboard: !!(allowedPages.cinemas_dashboard || allowedPages.distributors_dashboard),
			dcptoday: !!allowedPages.dcptoday,
			distributors:  !!allowedPages.distributors,
			distributorsDashboard: !!allowedPages.distributors_dashboard,
			keys: !!allowedPages.keys,
			kinosite: {
				about: !!allowedPages.kinosite_news,
				content: !!allowedPages.kinosite_news,
				contacts: !!allowedPages.kinosite_news,
				clients_email: !!allowedPages.kinosite_clients,
				feedbacks: !!allowedPages.kinosite_news,
				halls: !!allowedPages.kinosite_news,
				pages: !!allowedPages.kinosite_news,
				kinowidget: !!allowedPages.kinosite_news,
				online_sales_report: !!allowedPages.kinosite_news,
				online_sales_refund: !!this.accessTo('online_sales_refund'),
				online_sale_change_status_to_refund: !!this.accessTo('online_sale_change_status_to_refund'),
				online_orders: !!allowedPages.kinosite_news,
			},
			mediaplan: !!allowedPages.mediaplan,
			newyear: isNewYearHolidays() && (this.isBooker() || this.isAdministrator()),
			notifications: {
				messages: !!(allowedPages.notifications || {}).messages,
				notifications: !!(allowedPages.notifications || {}).notifications,
				service: !!(allowedPages.notifications || {}).service,
				trailers: !!(allowedPages.notifications || {}).trailers,
				userUpdates: !!(allowedPages.notifications || {}).user_updates,
			},
			payment: !!allowedPages.payment,
			premiera: !!this.accessTo('premiera'),
			releases: !!allowedPages.releases,
			repertory: !!allowedPages.repertory,
			sale_promotions: !!allowedPages.sale_promotions,
			contracts: !!allowedPages.contracts,
			schedule: !!allowedPages.schedule,
			service: !!allowedPages.service,
			smpte: !this.isDistributor(),
			ticketSystem: {
				administrate: {
					service_apps: !!get(allowedPages.ticket_system, 'administrate.service_apps', false),
					schedule: !!get(allowedPages.ticket_system, 'administrate.schedule', false),
					pushes: !!get(allowedPages.ticket_system, 'administrate.pushes', false),
					sale_settings: !!get(allowedPages.ticket_system, 'administrate.sale_settings', false),
					discount_settings: !!get(allowedPages.ticket_system, 'administrate.discount_settings', false),
					reservation_settings: !!get(allowedPages.ticket_system, 'administrate.reservation_settings', false),
					rentrak: !!get(allowedPages.ticket_system, 'administrate.rentrak', false),
					digital_ticket: !!get(allowedPages.ticket_system, 'administrate.digital_ticket', false),
					api_settings: !!get(allowedPages.ticket_system, 'administrate.api_settings', false),
					sale_management: !!get(allowedPages.ticket_system, 'administrate.sale_management', false),
					goods_ops: !!get(allowedPages.ticket_system, 'administrate.goods_ops', false),
					seance_ops: !!get(allowedPages.ticket_system, 'administrate.seance_ops', false),
					backup: true,
				},
				auditoriums: {
					constructor: !!get(allowedPages.ticket_system, 'halls.constructor', false),
					auditoriums_settings: !!get(allowedPages.ticket_system, 'halls.settings', false),
					limited_constructor: !!get(allowedPages.ticket_system, 'halls.limited_constructor', false),
				},
				applog: !!get(allowedPages.ticket_system, 'applog', false),
				customers: !!get(allowedPages.ticket_system, 'customers', false),
				counterparty: !!get(allowedPages.ticket_system, 'customers', false),
				demoscreens: !!get(allowedPages.ticket_system, 'demoscreens', false),
				discards: !!get(allowedPages.ticket_system, 'discards', false),
				discounts: !!get(allowedPages.ticket_system, 'discounts', false),
				extapi: !!get(allowedPages.ticket_system, 'extapi', false),
				kiosk_commercials: false,
				orders: !!get(allowedPages.ticket_system, 'orders', false),
				prices: !!get(allowedPages.ticket_system, 'prices', false),
				reports: !!get(allowedPages.ticket_system, 'reports', false),
				schedules: !!get(allowedPages.ticket_system, 'schedules', false),
				settings: !!get(allowedPages.ticket_system, 'settings', false),
				summary_reports: !!get(allowedPages.ticket_system, 'summary_reports', false),
				stocks: true,
				stuff: true,
				hasKinokassaCinemas: !_.isEmpty(allowedPages.ticket_system),
			},
			tms: {
				content: !!(allowedPages.tms || {}).content,
				keys: !!allowedPages.tms,
				monitoring: !!(allowedPages.tms || {}).monitoring,
				playlists: !!(allowedPages.tms || {}).playlists,
				releases: !!allowedPages.releases,
				schedule: !!allowedPages.schedule,
				commercials: !!allowedPages.commercials,
				mediaplan: !!allowedPages.mediaplan,
				settings: !!this.accessTo('TMS'),
				equipment: !!allowedPages.tms && this.isAdmin(),
				cue_catalog: !!this.getFeatures().access_tms_cues_catalog,
				templates: !!this.getFeatures().access_tms_playlist_templates,
			},
			videoposter: !!allowedPages.videoposter,
			fond_kino: this.needShowFondKinoButton(),
			cv: {
				monitoring: !!get(allowedPages.cv, 'monitoring', false),
				history: !!get(allowedPages.cv, 'monitoring', false),
			},
		};
	}

	getFeatures() {
		const features = this.get('features');

		return {
			mass_scheduling: !!features.mass_scheduling,
			access_autoupload: !!features.access_autoupload,
			campaign_import: !!features.campaign_import,
			access_tms_cues_catalog: !!features.access_tms_cues_catalog,
			access_tms_playlist_templates: !!features.access_tms_playlist_templates,
		};
	}

	isDistributor() {
		return this.get('type') === 'distributor';
	}

	isAdmin() {
		return this.get('admin');
	}

	isBooker() {
		return this.get('type') === 'cinema';
	}

	isAdministrator() {
		return this.get('type') === 'administrator';
	}

	/* Get */
	getCinema() {
		return this._cinemas[this.get('current').cinema_id] || {};
	}

	getCinemas() {
		return this.get('cinema') || [];
	}

	getHall(id: number) {
		return _.chain(this.get('cinema'))
			.pluck('halls')
			.flatten()
			.findWhere({ id }).value() || {};
	}

	/* Access */
	accessTo(page: string) {
		let access;

		switch (page) {
			case 'kdmContent': access = this.get('access_content');
				break;
			case 'DD24': access = this.get('access_dd4_serv');
				break;
			case 'TMS': access = this.get('access_tms');
				break;
			case 'videoposter': access = this.get('access_videoposter');
				break;
			case 'cv': access = this.get('access_cv');
				break;
			case 'kinosite': access = this.get('access_kinosite');
				break;
			case 'ad_network': access = this.get('access_ad_network');
				break;
			case 'cue_catalog': access = this.get('access_tms_cues_catalog');
				break;
			case 'online_sales_refund': access = this.get('access_online_sale_refund');
				break;
			case 'online_sale_change_status_to_refund': access = this.get('access_online_sale_change_status_to_refund');
				break;
			case 'premiera': access = this.get('access_price_manual');
				break;

			default: access = false;
		}

		return access;
	}

	needShowFondKinoButton() {
		return _.some(this.getCinemas(), (cinema: ICinema) => cinema.is_fond_kino) &&
			(this.isBooker() || this.isAdministrator());
	}
}
