import {getDatesRangeInString, getErrorText} from 'app/schedule/selectors/ScheduleHelpers';
import {cinemaProps} from 'app/user/constants/UserConstants';

const COUNT_OF_CINEMAS_IN_TOAST = 5;

export default class ScheduleCopyingToasts extends React.Component {
	static propTypes = {
		dateStart: PropTypes.string.isRequired,
		dateEnd: PropTypes.string.isRequired,
		targetCinemas: PropTypes.arrayOf(cinemaProps).isRequired,
		errorCode: PropTypes.number,
	};

	static defaultProps = {
		errorCode: null,
	};

	state = {isOpened: false};

	getCinemaTitle = cinema => (
		<li key={cinema.id}>
			{_.getLocalize(cinema.title)}. {_.getLocalize(cinema.city.title)}
		</li>
	);

	handleShowMore = () => {
		this.setState({isOpened: true});
	};

	render() {
		const {dateStart, dateEnd, targetCinemas, errorCode} = this.props;
		const {isOpened} = this.state;
		const countOfCinemas = targetCinemas.length;
		const countOfMoreCinemas = countOfCinemas - COUNT_OF_CINEMAS_IN_TOAST;
		const toastContent = {
			reason: errorCode ? getErrorText(errorCode) : null,
			dates: getDatesRangeInString(dateStart, dateEnd),
			cinema: countOfCinemas > 1
				? i18n.t('common.inCinemas')
				: `${_.getLocalize(targetCinemas[0].title)}. ${_.getLocalize(targetCinemas[0].city.title)}`,
		};

		return (
			<div>
				{errorCode
					? i18n.t('schedule:ScheduleWasNotCopiedAtCinemas', toastContent)
					: i18n.t('schedule:ScheduleWasCopiedAtCinemas', toastContent)
				}
				{countOfCinemas > 1 &&
					<ul style={{padding: 0, paddingLeft: 20, margin: 0}}>
						{_.map(_.first(targetCinemas, COUNT_OF_CINEMAS_IN_TOAST), this.getCinemaTitle)}
						{countOfMoreCinemas > 0 && (
							!isOpened
								? (
									<div onClick={this.handleShowMore}>
										{i18n.t('preposition.andMore')}{' '}
										<span className="b-link">
											{i18n.t('declension.in_cinema', {count: countOfMoreCinemas})}
										</span>
									</div>
								)
								: _.map(_.last(targetCinemas, countOfMoreCinemas), this.getCinemaTitle)
						)}
					</ul>
				}
			</div>
		);
	}
}
