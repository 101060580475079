import {createSelector} from 'reselect';
import {canUserEditRepertory} from 'app/user/userSelectors';
import {getReferenceFormats, joinFormatsTitles} from 'app/Reference';
import {seancesStatuses} from 'app/repertory/RepertoryConstants';
import {getSumCount} from 'app/repertory/selectors/repertorySelectors';
import {getTimetableApprovedTime} from 'app/releases/ReleasesSelectors';
import {getWeeksByDate as getWeeksByDateHelper} from 'app/repertory/RepertoryHelpers';

export const getCurrentRelease = state => state.repertoryReducer.currentRelease;
export const getReleaseWeeks = state => getCurrentRelease(state).weeks;
export const getReleaseNote = state => getCurrentRelease(state).user_note;
export const getReleaseLastOrder = state => getCurrentRelease(state).last_order;

export const getPreviewInfo = createSelector(
	[getReferenceFormats, getCurrentRelease],
	(referenceFormats, release) => {
		const date = moment(release.date_preview);
		const formats = release.preview_formats;

		return (
			<div>
				{i18n.t('repertory:Preview')} {date.format('ll')}, {joinFormatsTitles(referenceFormats, formats)}
			</div>
		);
	},
);

export const getPlanRange = createSelector(
	[getReleaseWeeks],
	seances => {
		if (_.isEmpty(seances)) {
			return {};
		}

		return {
			start: moment.min(_.map(seances, seance => moment(seance.date_start))),
			end: moment.max(_.map(seances, seance => moment(seance.date_end))),
			count: getSumCount(seances),
			status: seances[0].status,
		};
	},
);

export const getCustomPlanRange = weeks => {
	if (_.isEmpty(weeks)) {
		return {};
	}

	return {
		start: moment.min(_.map(weeks, week => moment(week.date_start))),
		end: moment.max(_.map(weeks, week => moment(week.date_end))),
		count: getSumCount(weeks),
		status: weeks[0].status,
	};
};

export const isReleaseEditable = createSelector(
	[getPlanRange, canUserEditRepertory],
	(planRange, canEditRepertory) => canEditRepertory &&
		planRange.status !== seancesStatuses.APPROVED_BY_DISTRIBUTOR,
);

export const needShowTimetableApprovedTime = createSelector(
	[getTimetableApprovedTime, getPlanRange],
	(timetableApprovedTime, planRange) => Boolean(timetableApprovedTime) &&
		planRange.status === seancesStatuses.APPROVED_BY_DISTRIBUTOR,
);

const getDaysRange = (rangeStart, rangeEnd) => {
	const startDate = moment(rangeStart);
	const endDate = moment(rangeEnd);

	return _.range(startDate.date(), endDate.diff(startDate, 'days') + startDate.date() + 1);
};

export const getCustomWeekIntervals = (planStart, planEnd) => _.chain(getDaysRange(planStart, planEnd))
	.map(day => moment(planStart).date(day))
	.reduce((memo, day) => {
		const weekStart = day.clone().startOf('w');
		const weekEnd = weekStart.clone().add(6, 'days');

		if (day.isBetween(weekStart, weekEnd, 'd', '[]')) {
			const key = `${weekStart.year()}${weekStart.week()}`;
			if (memo.hasOwnProperty(key)) {
				memo[key] = [...memo[key], day];
			} else {
				memo[key] = [day];
			}
		}

		return memo;
	}, {})
	.sortBy(days => _.first(days).clone().weekday(0).year())
	.value();

export const getWeekIntervals = createSelector(
	[getPlanRange],
	planRange => getCustomWeekIntervals(planRange.start, planRange.end),
);

export const getWeeksByDate = (state, date) => getWeeksByDateHelper(getReleaseWeeks(state), date);

export const getDeletedWeeks = (state, newRange) => {
	const oldRange = getPlanRange(state);
	const startIntervals = _.initial(getCustomWeekIntervals(oldRange.start, newRange.start));
	const endIntervals = _.rest(getCustomWeekIntervals(newRange.end, oldRange.end));
	const deletedIntervals = startIntervals.concat(endIntervals);

	return _.flatten(_.map(deletedIntervals, days => (
		getWeeksByDate(state, _.first(days))
	)));
};

export const getPlanFormatsByWeeks = weeks => _.chain(weeks)
	.map(({formats, voiceover_language_id}) => ({
		formats,
		voiceoverID: voiceover_language_id,
	}))
	.uniq(({formats, voiceoverID}) => `${formats.sort().join(', ')}${voiceoverID || ''}`)
	.sortBy(({formats, voiceoverID}) =>
		formats.length === 1 ? _.first(formats) + (voiceoverID || 0) : Infinity)
	.value();

export const createWeeks = (state, options = {}) => {
	const weeks = getReleaseWeeks(state);
	const {date_start: dateStart, date_end: dateEnd, releaseID, cinemaID} = options;
	const status = getPlanRange(state).status || seancesStatuses.PLANNED;

	return _.map(getPlanFormatsByWeeks(weeks), ({formats, voiceoverID}) => ({
		release_id: releaseID,
		cinema_id: cinemaID,
		halls: [],
		count: 0,
		date_start: dateStart,
		date_end: dateEnd,
		status,
		formats,
		voiceover_language_id: voiceoverID,
	}));
};

export const warnAboutDeletedWeeks = (referenceFormats, weeks) => {
	const weeksWithCount = _.filter(weeks, week => (week.count));
	if (!weeksWithCount.length) return true;

	const messageIntro = i18n.t('ScheduleChange.DistributionRangeChanges');
	const messageConclusion = i18n.t('ScheduleChange.AreYouSure');
	const messageBody = _.chain(weeksWithCount)
		.groupBy(week => moment(week.date_start).week())
		.map(weeksGroup => {
			const intro = i18n.t('ScheduleChange.WeekWillRemove', {
				changedDates: _.getFormatDate(
					moment(_.first(weeksGroup).date_start),
					moment(_.first(weeksGroup).date_end),
					{day: 'D', month: 'MMM', year: 'YYYY'},
				),
			});
			const body = _.map(weeksGroup, week => {
				const count = i18n.t('declension.session', {count: week.count});

				return `${count} ${joinFormatsTitles(referenceFormats, week.formats)}`;
			}).join(', ');

			return `${intro} ${body}.`;
		})
		.value().join(' ');

	return window.confirm(`${messageIntro} ${messageBody} ${messageConclusion}`);
};

export const changeWeek = (changedWeek, newCount, newHalls) => {
	const newWeek = _.omit(changedWeek, 'count', 'halls');
	newWeek.count = newCount;
	newWeek.halls = _.map(newHalls, hallID => {
		const hall = _.findWhere(changedWeek.halls, {hall_id: hallID}) || {};

		return {
			hall_id: hallID,
			comment: hall.comment || '',
			count: 0,
		};
	});

	return newWeek;
};
