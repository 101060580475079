import { ITitle } from 'app/user/UserTypes';

export interface IReferenceState {
	formats: IReferenceFormat[];
	ratings: IReferenceRating[];
	genres: IReferenceGenre[];
	languages: Array<BaseReference & { code: string }>;
}

type BaseReference = {
	id: number;
	title: string;
};

export interface IReferenceGenre extends BaseReference {
	country: string;
}

export enum ReferenceFormatType {
	seat = 'seat',
	upgrade = 'upgrade',
	luxe = 'luxe',
	sound = 'sound',
	resolution = 'resolution',
	frame_rate = 'frame_rate',
	video = 'video',
}

export interface IReferenceFormat extends Pick<BaseReference, 'id'> {
	exception: number;
	is_strict: number;
	title: ITitle;
	title_short: ITitle;
	type: ReferenceFormatType;
}

export interface IReferenceRating extends BaseReference {
	country_for: 'ru' | 'ua' | 'kz';
}

export type GetVoiceoverFormatString = {
	voiceformatType?: 'short' | 'full';
	videoformatType?: 'mainFormats' | 'allFormats';
};
