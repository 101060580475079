import { createSelector } from 'reselect';
import PrmPages from 'app/new_sidebar/pages/PrmPages';
import IReduxState from 'app/store/IReduxState';

export const isFetching = (state: IReduxState) => state.appReducer.isFetching;
export const getCurrentURL = (state: IReduxState) => state.appReducer.currentURL;
export const getMainViewNode = (state: IReduxState) => state.appReducer.mainViewNode;
export const isNewSidebar = (state: IReduxState) => state.appReducer.isNewSidebar;
export const getPopupNode = state => state.appReducer.popupNode;
export const getPressedKeys = (state: IReduxState) => state.appReducer.pressedKeys;
export const isPopupClosable = (state: IReduxState) => state.appReducer.isPopupClosable;
export const getHeaderSettings = (state: IReduxState) => state.appReducer.headerSettings;
export const getSidebarURLs = (state: IReduxState) => state.appReducer.sidebarURLs;
export const getToasts = (state: IReduxState) => state.appReducer.toasts;
export const getSearchFilter = (state: IReduxState) => state.appReducer.searchValue;
export const getTopNotifications = (state: IReduxState) => state.appReducer.topNotifications;
export const getShownFeaturesOnboarding = state => state.appReducer.onboardingShownFeatures;
export const getUserAdvancedInfoDate = (state: IReduxState) => state.appReducer.userAdvancedInfoVisibleDate;

/**
 * There are 5 main sections in KINOPLAN: TMS, Ticket System, Kinosite, CV & Kinoplan (Booking)
 * Depending on section app sidebar changes
 * @param {string} path
 * @returns {string} one of: kinoplan, ticket_system, tms, kinosite, cv
 */
export const getSidebar = (path: string) => {
	const currentPage = path.split('/')[1];

	if (_.contains(['ticket_system', 'tms', 'kinosite',
		'cv', 'cinema_pulse', 'ad_network', PrmPages.SECTION_NAME], currentPage)
	) {
		return currentPage;
	}

	return 'kinoplan';
};

export const getCurrentPage = (route: string[]) => {
	return [
		'tms',
		'ticket_system',
		'cv',
		'kinosite',
		'cinema_pulse',
		'ad_network',
		PrmPages.SECTION_NAME,
	].includes(route[0]) ? route[1] : route[0];
};

/**
 * Route to current page depends on section
 * For all TMS, Ticket System, CV page first part of route is section title and second is page title
 * For Kinoplan first route part is page title
 */

const serviceConvention = {
	'ticket_system': 'Kinokassa',
	'tms': 'TMS',
	'videoposter': 'Videopanels',
};

export const getValidServiceRoute = (route: string[]) => {
	const serviceRouteForInactiveSubscription = route[2] !== 'keys' &&
		_.contains(['tms', 'ticket_system', 'videoposter'], route[1]) ? route[1] : '';

	return serviceConvention[serviceRouteForInactiveSubscription];
};

export const getHasUserAdvancedInfo = createSelector(
	getUserAdvancedInfoDate, state => state.userReducer,
	(date, user) => (!user.birth_date || !user.user_position) && !_.isNull(date)
		&& moment().isSame(date, 'day'),
);

export const getURLForRequest = (url: string, isWithoutBaseURL: boolean) =>
	isWithoutBaseURL ? url : `${window.API_URL || ''}${url}`;
