import { localeProps } from 'app/app/types';

export const USER_CHANGE_CURRENT_CINEMA = 'USER_CHANGE_CURRENT_CINEMA' as const;

export const USER_CHANGE_CURRENT_DATE = 'USER_CHANGE_CURRENT_DATE' as const;
export const USER_ADD_BAN = 'USER_ADD_BAN' as const;
export const USER_UPDATE_CINEMA_TMS_SETTINGS = 'USER_UPDATE_CINEMA_TMS_SETTINGS' as const;
export const USER_UPDATE_HALLS_TMS_SETTINGS = 'USER_UPDATE_HALLS_TMS_SETTINGS' as const;
export const USER_UPDATE_HALL_TMS_SETTINGS = 'USER_UPDATE_HALL_TMS_SETTINGS' as const;
export const USER_UPDATE_CINEMA_ID_TMS_SETTINGS = 'USER_UPDATE_CINEMA_ID_TMS_SETTINGS' as const;

export const USER_SET_KDM_BLOCKED = 'USER_SET_KDM_BLOCKED' as const;
export const USER_SET_TX = 'USER_SET_TX' as const;

export const USER_EDIT_INFO_REQUEST = 'USER_EDIT_INFO_REQUEST' as const;
export const USER_EDIT_INFO_SUCCESS = 'USER_EDIT_INFO_SUCCESS' as const;
export const USER_EDIT_INFO_FAIL = 'USER_EDIT_INFO_FAIL' as const;

export const USER_SET_UPDATED_CINEMAS = 'USER_SET_UPDATED_CINEMAS' as const;
export const USER_READ_CHAT_SUCCESS = 'USER_READ_CHAT_SUCCESS' as const;
export const USER_READ_CHAT_FAIL = 'USER_READ_CHAT_FAIL' as const;
export const USER_SET_FEATURE_AWARENESS = 'USER_SET_FEATURE_AWARENESS' as const;

export const USER_SET_USER = 'USER_SET_USER' as const;
export const USER_SET_CINEMA_DATA = 'USER_SET_CINEMA_DATA' as const;
export const USER_SET_CINEMA_DATA_FETCHING = 'USER_SET_CINEMA_DATA_FETCHING' as const;
export const USER_UPDATE_CINEMA_DATA = 'USER_UPDATE_CINEMA_DATA' as const;
export const USER_SET_HALLS_LICENSES = 'USER_SET_HALLS_LICENSES' as const;
export const USER_SET_UNREADS_COUNT = 'USER_SET_UNREADS_COUNT' as const;
export const USER_SET_ADDITIONAL_INFO = 'USER_SET_ADDITIONAL_INFO' as const;

// Cinema Network
export const MIRAGE_CINEMA_NETWORK_ID = 257;
export const KINOMAX_CINEMA_NETWORK_ID = 680;
export const CINEMA_PARK_CINEMA_NETWORK_ID = 484;
export const FORMULA_KINO_CINEMA_NETWORK_ID = 512;

export const serverProps = PropTypes.shape({
	full_storage: PropTypes.number,
	used_storage: PropTypes.number,
	serial: PropTypes.string,
	type: PropTypes.string,
	version: PropTypes.string,
	model: PropTypes.string,
});

export const hallProps = PropTypes.shape({
	capasity: PropTypes.number,
	cinema_id: PropTypes.number,
	created: PropTypes.string,
	dbox: PropTypes.number,
	encryption: PropTypes.string,
	formats: PropTypes.arrayOf(PropTypes.string),
	formats_id: PropTypes.arrayOf(PropTypes.number),
	glasses: PropTypes.number,
	id: PropTypes.number.isRequired,
	installed: PropTypes.string,
	non_commercial: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
	]),
	number: PropTypes.number,
	projector: PropTypes.object,
	projectors: PropTypes.array,
	screen: PropTypes.object,
	server: serverProps,
	support: PropTypes.string,
	title: PropTypes.string,
	type3d: PropTypes.string,
	type_dbox: PropTypes.string,
	updated: PropTypes.number,
	upgrade: PropTypes.array,
});

export const cinemaProps = PropTypes.shape({
	address: PropTypes.string.isRequired,
	cinema_network: PropTypes.shape({
		id: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
		]).isRequired,
		name: PropTypes.string,
	}),
	city: PropTypes.shape({
		id: PropTypes.number.isRequired,
		lat: PropTypes.number,
		lon: PropTypes.number,
		population: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string,
		]).isRequired,
		region_id: PropTypes.number,
		title: localeProps.isRequired,
		updated: PropTypes.number,
	}),
	region_id: PropTypes.number,
	connection_type: PropTypes.string,
	contact_email: PropTypes.string,
	connection_person: PropTypes.string,
	connection_phone: PropTypes.string,
	contacts: PropTypes.arrayOf(PropTypes.shape({
		comment: PropTypes.string,
		email: PropTypes.string,
		phone: PropTypes.arrayOf(PropTypes.string),
		fio: PropTypes.string,
		position: PropTypes.string,
	})),
	country: PropTypes.shape({
		id: PropTypes.number.isRequired,
		title: localeProps.isRequired,
		updated: PropTypes.number,
	}),
	crm_status: PropTypes.string,
	dd24_pro: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.bool,
	]).isRequired,
	dd24status: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	deleted: PropTypes.number,
	ext_id: PropTypes.number,
	formats: PropTypes.arrayOf(PropTypes.string),
	formats_id: PropTypes.arrayOf(PropTypes.number),
	halls: PropTypes.arrayOf(hallProps),
	id: PropTypes.number.isRequired,
	inn: PropTypes.number,
	kdm_blocked: PropTypes.number,
	kino_wifi: PropTypes.number,
	lat: PropTypes.string,
	lon: PropTypes.string,
	logistic_email: PropTypes.string,
	logistic_person: PropTypes.string,
	logistic_phone: PropTypes.string,
	master: PropTypes.number,
	monitoring: PropTypes.number,
	owner: PropTypes.string,
	region: PropTypes.shape({
		country_id: PropTypes.number,
		id: PropTypes.number.isRequired,
		time_zone: PropTypes.number.isRequired,
		title: PropTypes.shape({
			en: PropTypes.string,
			ru: PropTypes.string,
		}),
		updated: PropTypes.number,
	}),
	rights: PropTypes.string,
	rights_id: PropTypes.number,
	subscriptions: PropTypes.shape({
		booking: PropTypes.shape({
			end: PropTypes.number,
			start: PropTypes.number,
			status: PropTypes.string,
			tariff: localeProps,
		}),
	}).isRequired,
	title: localeProps,
	tms_system: PropTypes.string,
	work_hours: PropTypes.object,
	www: PropTypes.string,
	zip_code: PropTypes.string,
});

export const CinemaSettingProps = PropTypes.shape({
	cinema_close: PropTypes.string,
	cinema_open: PropTypes.string,
	cinema_weekend_close: PropTypes.string,
	cinema_weekend_open: PropTypes.string,
	cinema_id: PropTypes.number.isRequired,
	default_cinema_advertising_block: PropTypes.string,
	occupancy_time_format: PropTypes.string,
	halls_settings: PropTypes.arrayOf(PropTypes.shape({
		default_hall_close: PropTypes.string.isRequired,
		default_hall_open: PropTypes.string.isRequired,
		default_hall_pause: PropTypes.number.isRequired,
		id: PropTypes.number.isRequired,
		active: PropTypes.shape({
			value: PropTypes.bool.isRequired,
			updated: PropTypes.number.isRequired,
		}).isRequired,
	})).isRequired,
});

export const StorageProps = PropTypes.shape({
	title: PropTypes.string.isRequired,
	id: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]).isRequired,
	server: PropTypes.shape({
		full_storage: PropTypes.number,
		used_storage: PropTypes.number,
	}),
});

export const DEFAULT_AVATAR = '/img/avatar.png';
