export const BUSINESS_DAY_LIMIT = 7;
export const BUSINESS_DAY_LIMIT_TIME = '07:00';

export const SET_APP_DATA = 'SET_APP_DATA' as const;

export const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE' as const;

export const REQUEST_START = 'REQUEST_START' as const;
export const REQUEST_END = 'REQUEST_END' as const;

export const SHOW_APP_LOADER = 'SHOW_APP_LOADER' as const;
export const HIDE_APP_LOADER = 'HIDE_APP_LOADER' as const;

export const REQUEST_ERROR = 'REQUEST_ERROR' as const;
export const SHOW_OVERLAY = 'SHOW_OVERLAY' as const;
export const TOGGLE_POPUP = 'TOGGLE_POPUP' as const;

export const APP_SWITCH_MAIN_VIEW = 'APP_SWITCH_MAIN_VIEW' as const;
export const APP_SET_HEADER_TITLE = 'APP_SET_HEADER_TITLE' as const;
export const APP_SET_NEW_SIDEBAR = 'APP_SET_NEW_SIDEBAR' as const;

export const DOWNLOAD_FILE = 'DOWNLOAD_FILE' as const;

export const APP_KEY_DOWN = 'APP_KEY_DOWN' as const;
export const APP_KEY_UP = 'APP_KEY_UP' as const;
export const APP_CLEAR_PRESSED_KEYS = 'APP_CLEAR_PRESSED_KEYS' as const;

export const SUPPORT_PHONE = '+7 495 980-17-27';
export const SALES_ADDITIONAL_PHONE = '210';
export const SALES_EMAIL = 'sales@kinoplan.ru';

export const ADD_DEFAULT_TOAST = 'ADD_DEFAULT_TOAST' as const;
export const ADD_SUCCESS_TOAST = 'ADD_SUCCESS_TOAST' as const;
export const ADD_WARNING_TOAST = 'ADD_WARNING_TOAST' as const;
export const ADD_ERROR_TOAST = 'ADD_ERROR_TOAST' as const;
export const REMOVE_TOAST = 'REMOVE_TOAST' as const;
export const REMOVE_ALL_TOASTS = 'REMOVE_ALL_TOASTS' as const;

export const MARK_READ_FEATURE_ONBOARDING = 'MARK_READ_FEATURE_ONBOARDING' as const;

export const SET_TOP_NOTIFICATION_CLOSE_TIMESTAMP = 'SET_TOP_NOTIFICATION_CLOSE_TIMESTAMP' as const;
export const SET_USER_ADVANCED_INFO_DATE = 'SET_USER_ADVANCED_INFO_DATE' as const;

export const KINOPULSE_SPECIAL_USER_ID = '5d68ccc92f000025aab22c13';

export const YA_METRIKA_ID = 52340668; // for kinoplan.io

export const calendarFormat = {
	lastWeek: 'D MMMM, HH:mm',
	sameElse: 'D MMMM, HH:mm',
};

export const BREAKPOINT_TABLET = 1024;
export const BREAKPOINT_MOBILE = 790;
