import {createSelector} from 'reselect';
import {emptyRelease} from 'app/schedule/scheduleConstants';
import {getVideoFormatsID, FORMAT_4K_ID} from 'app/Reference';
import {
	getFoundReleasesByTitle,
	getPlannedReleases,
	getReleaseListSearchLocation,
} from 'app/repertory/selectors/repertorySelectors';
import {
	getUserCurrentCinema,
	getCinemasWithBookingSubscription,
	getCinemasWithWritingAccess,
	getHall,
	hasActiveTMSSubscription,
	isReadOnly,
	isMediaplanOnly,
	isInSandbox,
	makeGetHall,
	getCinemasData,
	getCinemaSettingsByID,
} from 'app/user/userSelectors';
import {BUSINESS_DAY_LIMIT_TIME} from 'app/app/AppConstants';
import {COLORS} from 'app/app/ColorsConstants';
import {getEditOnlyCurrentDay} from 'app/schedule/selectors/scheduleCommonSelectors';
import {checkHallLicense, getReleaseWeekCount} from 'app/schedule/scheduleHelpers';
import { sortByNat } from 'app/mixins';

export const getSeances = state => state.scheduleReducer.scheduleMainReducer.present.seances;

export const getDateStart = state => state.scheduleReducer.scheduleMainReducer.present.date_start;
export const getDatePopupStart = state => state.scheduleReducer.scheduleMainReducer.present.dateStartOnPopup;
export const getDateEnd = createSelector(
	[getDateStart],
	dateStart => moment(dateStart).endOf('week').format(),
);
export const getChosenWeekday = state => state.scheduleReducer.scheduleMainReducer.present.chosenWeekday;
export const getChosenDate = state => moment(getDateStart(state)).weekday(getChosenWeekday(state));
export const getReleases = state => state.scheduleReducer.scheduleMainReducer.present.releases;
export const getPlannedScheduleReleases = createSelector(
	[getPlannedReleases],
	repertoryReleases => repertoryReleases.concat(emptyRelease),
);
export const getAllScheduleReleases = createSelector(
	[getPlannedScheduleReleases, getReleases],
	(repertoryReleases, scheduleReleases) => repertoryReleases.concat(scheduleReleases),
);

export const getAllReleasesByID = createSelector(
	[getPlannedScheduleReleases, getReleases],
	(repertoryReleases, scheduleReleases) => _.indexBy(scheduleReleases.concat(repertoryReleases), 'release_id'),
);

export const needShowUnplannedReleases = state =>
	state.scheduleReducer.scheduleMainReducer.present.showUnplannedReleases;
export const needSortByStartWeek = state =>
state.scheduleReducer.scheduleMainReducer.present.sortByStartWeek;
export const getScheduleGenerationSettings = state =>
	state.scheduleReducer.scheduleMainReducer.present.scheduleGenerationSettings;

export const getScheduleSettings = state => state.scheduleReducer.scheduleSettingsReducer;

const getReleaseID = (state, props) => props.releaseID;
export const makeGetReleaseByID = () => createSelector(
	[getAllScheduleReleases, getReleaseID],
	(allScheduleReleases, releaseID) => (releaseID === 0
		? emptyRelease
		: _.findWhere(allScheduleReleases, {release_id: releaseID}) || emptyRelease),
);

export const getChosenHalls = state => state.scheduleReducer.scheduleCopyingReducer.chosenHalls;
export const getChosenCinemas = state => state.scheduleReducer.scheduleCopyingReducer.chosenCinemas;
export const getChosenDates = state => state.scheduleReducer.scheduleCopyingReducer.chosenDates;

export const getChangedDays = changedSeances => _.uniq(_.pluck(changedSeances, 'date_start'));
export const getSeancesGrouped = state => state.scheduleReducer.scheduleMainReducer.present.seancesGrouped;

const sortReleasesForReleaseList = (releases, sortByStartWeek = null) => (
	_.chain(releases)
		.sortBy(release => release.week.formats.length)
		.sortBy(release => _.min(release.week.formats))
		.sortBy(release => _.getLocalize(release.title))
		.sortBy(release => sortByStartWeek
			? !moment(release.date, 'YYYY-MM-DD').isSame(release.week.date_start, 'week')
			: release)
		.value()
);

// (YYYY-MM-DD, YYYY-MM-DD) => [YYYY-MM-DD]
export const getDatesBetweenStartEnd = (dateStart, dateEnd) => {
	const dateStartMoment = moment(dateStart, 'YYYY-MM-DD');
	const dateEndMoment = moment(dateEnd, 'YYYY-MM-DD');

	return _.map(
		_.range(0, dateEndMoment.diff(dateStartMoment, 'days') + 1),
		day => dateStartMoment.clone().add(day, 'days').format(),
	);
};

const getColorToReleases = (release, scheduleReleases) => {
	const releaseWithColor = _.extend(release, _.findWhere(scheduleReleases, {release_id: release.release_id}));

	return ({
		...releaseWithColor,
		color: _.get(release, 'release_id') === 0 || _.isEmpty(release)
			? COLORS.dimgray
			: _.get(releaseWithColor, 'color', COLORS.dimgray),
	});
};

export const getReleasesFromSearch = createSelector(
	[getFoundReleasesByTitle, getDateStart, getReleases],
	(releases, dateStart, scheduleReleases) => {
		const releasesForReleaseList = _.chain(releases)
			.map(release => (
				_.map(release.formats, format => ({
					...release,
					week: {
						formats: [format],
						date_start: dateStart,
						status: 'default',
						count: 0,
					},
				}))
			))
			.flatten()
			.map(release => getColorToReleases(release, scheduleReleases))
			.value();

		return sortReleasesForReleaseList(releasesForReleaseList);
	},
);

export const getReleasesByHall = createSelector(
	// Get hall releases grouped by hall id for current week
	[getPlannedReleases, getDateStart, needShowUnplannedReleases, needSortByStartWeek, getReleases],
	(releases, dateStart, showUnplannedReleases, sortByStartWeek, scheduleReleases) => {
		const releasesByHall = _.chain(releases)
			.map(release => getColorToReleases(release, scheduleReleases))
			.map(release => _.map(release.weeks, week =>
				({..._.omit(release, 'weeks'), week, startWeek: getReleaseWeekCount(release.date, dateStart)})))
			.flatten()
			.map(release => (release.week.halls.length ?
				_.map(release.week.halls, releaseHall => (
					{...release, week: {..._.omit(release.week, 'halls'), hall: releaseHall}})) :
				{...release, week: {..._.omit(release.week, 'halls'), hall: {}}}))
			.flatten()
			.filter(release => moment(dateStart, 'YYYY-MM-DD').isSame(release.week.date_start, 'week') &&
				(showUnplannedReleases || release.week.count))
			.groupBy(release => release.week.hall.hall_id || 0)
			.mapObject(hallReleases => !_.isFinite(sortByStartWeek)
				? sortReleasesForReleaseList(hallReleases, sortByStartWeek)
				: sortByNat(
					sortReleasesForReleaseList(hallReleases),
					'startWeek',
					undefined,
					{ direction: sortByStartWeek ? 'ASC' : 'DESC' },
				))
			.value();

		return {...releasesByHall, 0: [...releasesByHall[0] || [], emptyRelease]};
	},
);

/**
 * Get used video formats in current cinema halls.
 * @param {object} currentCinema
 * @param {object} seancesGrouped - Seances grouped by hall id and date
 * @param {array} videoFormatsID - Ids of all existent video formats
 *
 * @returns {object} usedFormatsInCurrentHalls - Used video formats in halls grouped by hall id
 */
export const getUsedFormatsInCurrentHalls = createSelector(
	[getUserCurrentCinema, getSeancesGrouped, getVideoFormatsID],
	(currentCinema, seancesGrouped, videoFormatsID) =>
		_.object(_.pluck(currentCinema.halls, 'id'), _.map(currentCinema.halls, hall => (
			!_.has(seancesGrouped, hall.id) ? [] : _.chain(seancesGrouped[hall.id])
				.map(daySeances => _.pluck(daySeances, 'formats'))
				.flatten()
				.uniq()
				.intersection(videoFormatsID)
				.value()
		))),
);

export const getHallsIDsAvailableForCopying = createSelector(
	[getUserCurrentCinema, getCinemasWithWritingAccess, getUsedFormatsInCurrentHalls, (state, hallID) => hallID],
	(currentCinema, cinemas, videoFormatsInCurrentHalls, sourceHallID) => _.chain(cinemas)
		.map(cinema => _.filter(
			cinema.halls,
			hall => _.isEmpty(_.difference(videoFormatsInCurrentHalls[sourceHallID], hall.formats_id)),
		))
		.flatten()
		.pluck('id')
		.reduce((obj, id) => {
			// eslint-disable-next-line no-param-reassign
			obj[id] = true;

			return obj;
		}, {})
		.value(),
);

export const getCinemasAvailableForCopying = createSelector(
	[getUserCurrentCinema, getCinemasWithBookingSubscription, getUsedFormatsInCurrentHalls],
	(currentCinema, cinemas, videoFormatsInCurrentHalls) => _.filter(cinemas, cinema => (
		cinema.id !== currentCinema.id &&
		cinema.halls.every((destHall, index) =>	!(currentCinema.halls[index] &&
			_.difference(videoFormatsInCurrentHalls[currentCinema.halls[index].id], destHall.formats_id).length
		))
	)),
);

export const isHallSupportsFormats = (seances, hallFormatsID, upgradeFormatsID) => {
	const availableFormatsIds = [...hallFormatsID, ...upgradeFormatsID, FORMAT_4K_ID];

	return _.every(seances, seance => _.isEmpty(_.difference(_.get(seance, 'formats'), availableFormatsIds)));
};

export const getCinemaTimeOpen = (cinemaSettings = {}, weekday) => (
	(weekday === 2 || weekday === 3 // saturday or sunday
		? cinemaSettings.cinema_weekend_open
		: cinemaSettings.cinema_open
	) || BUSINESS_DAY_LIMIT_TIME
);

const getCinemaID = (state, props) => props.cinemaID;
const getHallID = (state, props) => props.hallID;
export const makeGetHallPause = () => createSelector(
	[getCinemasData, getCinemaID, getHallID],
	(cinemasData, cinemaID, hallID) => _.chain(cinemasData[cinemaID])
		.get('cinema_settings.halls_settings', [])
		.findWhere({id: hallID})
		.get('default_hall_pause', 10)
		.value(),
);

export const getUniqHallsNumber = cinemas => _.chain(cinemas)
	.map(cinema => cinema.halls.length)
	.sortBy()
	.uniq(true)
	.value();

export const makeGetHallFormats = () => {
	const getThisHall = makeGetHall();

	return createSelector(
		getThisHall,
		hall => hall.formats_id,
	);
};

export const makeGetHallCapacity = () => {
	const getThisHall = makeGetHall();

	return createSelector(
		getThisHall,
		hall => hall.capasity,
	);
};

export const isTranslationEnabledInHall = (state, cinemaID, hallID) => {
	const {stream} = getHall(state, cinemaID, hallID);

	return Boolean(stream);
};

export const getAdvertisingFeatureTitle = advertisingFeature => {
	const commoni18nPath = 'UserNotifications.Notifications.type';

	switch (advertisingFeature) {
		case 'kinoplan': return i18n.t(`${commoni18nPath}.Advertising`);
		case 'stream': return i18n.t(`${commoni18nPath}.Translation`);
		case 'tms_off': return i18n.t(`${commoni18nPath}.WithoutPlaylist`);
		case 'off': return i18n.t(`${commoni18nPath}.WithoutAdvertising`);
		default: return '';
	}
};

export const getSeancePropertyTooltip = property => {
	const titles = [
		property.is_auto_cancel_disabled ? i18n.t('schedule:Properties.IgnoreTMSCancel') : '',
		getAdvertisingFeatureTitle(property.advertising_feature),
	];

	return _.compact(titles).map(title => <div key={title}>{title}</div>);
};

export const canChangeAdvertisingSource = state =>
	hasActiveTMSSubscription(state) &&
	!isReadOnly(state) &&
	!isMediaplanOnly(state) &&
	!isInSandbox(state);

const getSchedulePast = state => state.scheduleReducer.scheduleMainReducer.past;
const getScheduleFuture = state => state.scheduleReducer.scheduleMainReducer.future;

export const scheduleSeancesNoPast = createSelector(
	getSchedulePast, schedulePast => _.isEmpty(schedulePast),
);

export const scheduleSeancesNoFuture = state => _.isEmpty(getScheduleFuture(state));

export const getPastSeances = state => {
	const schedulePast = getSchedulePast(state);

	return scheduleSeancesNoPast(state) ? {} : schedulePast[schedulePast.length - 1].seances;
};

export const getFutureSeances = state => {
	const scheduleFuture = getScheduleFuture(state);

	return scheduleSeancesNoFuture(state) ? {} : scheduleFuture[0].seances;
};

export const isAllSeancesLocked = createSelector(
	getSeances, getEditOnlyCurrentDay, getDateStart, getChosenWeekday, (_state, isMainSection) => isMainSection,
	(seances, editOnlyCurrentDay, dateStart, chosenWeekday, isMainSection) => {
		if (editOnlyCurrentDay && !isMainSection) {
			const neededDate = moment(dateStart).weekday(chosenWeekday).format();

			return _.every(
				_.filter(seances, seance => seance.date_start === neededDate),
				seance => seance.is_locked,
			);
		}

		return _.every(seances, seance => seance.is_locked);
	},
);

export const getChoosenCinemasHalls = createSelector(
	getChosenCinemas,
	cinemas => _.flatten(_.map(cinemas, cinema => cinema.halls)),
);

const getPlannedDayStart = (state, date) => date;
export const makeGetIsDayBeforeStartRelease = () => createSelector(
	[getDatePopupStart, getDateStart, getChosenWeekday, getPlannedDayStart],
	(dateStartOnPopup, dateStart, choosenWeekday, plannedDayStart) => (
		dateStartOnPopup
			? moment(dateStartOnPopup)
			: moment(dateStart).weekday(choosenWeekday)
	).isBefore(moment(plannedDayStart)),
);

export const isEditDayPopupOpen = state => getReleaseListSearchLocation(state) === 'ScheduleEditDay';

export const getUCSScheduleNotificationEventTypes = _.memoize(advertisingFeature => ({
	added_seance: i18n.t('UserNotifications.UCSSchedule.AddedSeance'),
	removed_seance: i18n.t('UserNotifications.UCSSchedule.RemovedSeance'),
	new_schedule: i18n.t('UserNotifications.UCSSchedule.NewSchedule'),
	added_advertising_feature: i18n.t('UserNotifications.UCSSchedule.AddProperty', {
		name: getAdvertisingFeatureTitle(advertisingFeature),
	}),
	removed_advertising_feature: i18n.t('UserNotifications.UCSSchedule.RemoveProperty', {
		name: getAdvertisingFeatureTitle(advertisingFeature),
	}),
	activated_auto_cancel: i18n.t('UserNotifications.UCSSchedule.RemoveProperty', {
		name: i18n.t('notifications.IgnoreTMSCancel'),
	}),
	disabled_auto_cancel: i18n.t('UserNotifications.UCSSchedule.AddProperty', {
		name: i18n.t('notifications.IgnoreTMSCancel'),
	}),
}));

export const isSeancesLockedForCurrentDayInHall = (seancesInHall, chosenDate) =>
	_.chain(seancesInHall)
		.get(chosenDate, [])
		.some(seance => seance.is_locked || seance.is_approved)
		.value();

export const hasHallLicense = hallID => createSelector(
	getCinemaSettingsByID,
	cinemaSettings => checkHallLicense(cinemaSettings.halls_settings, hallID),
);
