const statusCodes = {
	DEFAULT: null,
	END_OF_DAY: 15901,
};

const notifications = {
	[statusCodes.DEFAULT]: {
		title: 'notifications.Error',
		message: 'validations.ConnectionError',
	},
	[statusCodes.END_OF_DAY]: {
		message: 'schedule:alerts.EndOfDay',
	},
};

function parseResponseStatusCode(response) {
	try {
		return JSON.parse(response).code;
	} catch (error) {
		return null;
	}
}

export default function getNotificationsContent(xhrInstance) {
	const statusCode = parseResponseStatusCode(xhrInstance.response);
	const hasStatusCodeInList = !_.isEmpty(notifications[statusCode]);

	if (hasStatusCodeInList) {
		const {title, message} = notifications[statusCode];

		return {
			...!_.isUndefined(title) ? {title: i18n.t(title)} : null,
			subtitle: i18n.t(message),
		};
	}

	const defaultMessage = notifications[statusCodes.DEFAULT];

	return {
		title: i18n.t(defaultMessage.title),
		subtitle: i18n.t(defaultMessage.message),
	};
}
