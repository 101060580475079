import { Type, never } from 'io-ts';

export default class EptRawData<T> {
	private data: T;
	private runtimeType: Type<T> | Type<never>;
	readonly source: string;

	constructor(data: T, runtimeType: Type<T> | Type<never> = never, source: string) {
		this.data = data;
		this.runtimeType = runtimeType;
		this.source = source;
	}

	decode() {
		return this.runtimeType.decode(this.data);
	}

	testDecodeFail() {
		return this.runtimeType.decode(
			'This is fail decode feature for testing custom EptProcessor failure behavior.'
			+ ' Look for testDecodeFail method call to remove it.',
		);
	}
}
