import {createSelector} from 'reselect';
import {ContentCriticalStatuses} from 'app/tms_playlists/constants/PlaylistsConstants';
import getPlaylistsDate from 'app/tms_playlists/selectors/getPlaylistsDate';
import {currentCinemaTimeZone, getUserCurrentCinema} from 'app/user/userSelectors';
import {BUSINESS_DAY_LIMIT} from 'app/app/AppConstants';
import {getCurrentURL} from 'app/app/AppSelectors';

// Main Playlist State selectors
export const getPlaylists = state => state.playlistsReducer.PlaylistsMainReducer.playlists;
export const getPlayservers = state => state.playlistsReducer.PlaylistsMainReducer.playservers;
export const lastUCSDownload = state => state.playlistsReducer.PlaylistsMainReducer.lastUCSDownload;
export const hasPreloadAccess = state => state.playlistsReducer.PlaylistsMainReducer.hasPreloadAccess;
export const getPlaylistsHistory = state => state.playlistsReducer.PlaylistsMainReducer.historyReducer;
export const getCheckGenerationItems = state => state.playlistsReducer.PlaylistsMainReducer.checkGenerationItems;
export const isCheckingGenerationPlaylists = state => state.playlistsReducer.PlaylistsMainReducer.isCheckingGenerationPlaylists;
export const isFetching = state => state.playlistsReducer.PlaylistsMainReducer.isFetching;
export const getGeneratingDaysByHall = state => state.playlistsReducer.PlaylistsMainReducer.generatingDaysByHall;
export const isChangeStatusGenerating = state => state.playlistsReducer.PlaylistsMainReducer.isChangeStatusGenerating;
export const isFetchingGeneration = state => state.playlistsReducer.PlaylistsMainReducer.isFetchingGeneration;
export const isPackageChangedForSimilarPlaylists = state =>
	state.playlistsReducer.PlaylistsMainReducer.isPackageChangedForSimilarPlaylists;

export const getPlaylistsWeekDates = createSelector(
	[getPlaylistsDate],
	date => _.map(_.range(1, 8), (day, index) => moment(date).add(index, 'day').format()),
);

export const hasMovieShows = createSelector([getPlaylists], playlists => Boolean(playlists.length));
export const areAnyPlaylistsUploading = createSelector(
	[getPlaylists],
	playlists => playlists.some(playlist => playlist.playlist_status === 'pending'),
);

const isWeekIncludesCurrentDay = (day, date, endOfWeek) => (day.isBetween(date, endOfWeek, null, '(]') ? day : date);

export const uploadPlaylistScheduleDatePickerDates = (type, date, halls, weekDay = null) => {
	const today = moment();
	const currentDay = moment(weekDay);
	const endOfWeek = date.clone().endOf('week');

	if (type === 'start') {
		if (_.isNull(weekDay)) {
			return isWeekIncludesCurrentDay(today, date, endOfWeek);
		}

		return isWeekIncludesCurrentDay(currentDay, date, endOfWeek);
	} else if (type === 'end') {
		if (_.isNull(weekDay)) {
			return endOfWeek;
		}

		return isWeekIncludesCurrentDay(currentDay, date, endOfWeek) && halls.length > 1 ? endOfWeek : currentDay;
	}
};

const getVisiblePlaylists = createSelector(
	[getPlaylists, currentCinemaTimeZone],
	(playlists, cinemaTimeZone) => _.chain(playlists)
		.map(playlist => ({
			...playlist,
			start: moment.utc(playlist.start * 1000).utcOffset(cinemaTimeZone),
			tried_upload: playlist.tried_upload ? moment.utc(playlist.tried_upload * 1000).utcOffset(cinemaTimeZone) : null,
		}))
		.sortBy('start')
		.value(),
);

export const getAutouploadsErrorMessage = _.memoize(code => {
	switch (code) {
		case 400:
			return 'tmsPlaylists:MissingRightsToChangeStatus';
		case 401:
			return 'tmsPlaylists:AutouploadTurnOnError';
		case 402:
			return 'tmsPlaylists:AutouploadTurnOffError';
		default:
			return 'notifications.ConnectionError';
	}
});

export const getRequestErrorMessage = _.memoize(code => {
	switch (code) {
		case 403:
			return 'tmsPlaylists:MissingRightsToChangeStatus';
		default:
			return 'notifications.ConnectionError';
	}
});

export const getPlaylistsGroupped = createSelector(
	[getVisiblePlaylists],
	playlists => _.map(_.groupBy(playlists, 'hall_id'), (movieShows, hallID) => ({
		hallID: parseInt(hallID, 10),
		movieShows: _.groupBy(movieShows, movieShow => _.getBusinessDay(movieShow.start).format()),
	})),
);

export const makeGetPlaylistsForHallByWeekDate = () => createSelector(
	getPlaylistsGroupped,
	(_, props) => props.hallID,
	(_, props) => props.weekDate,
	(playlistsGroups, hallID, weekDate) => {
		const playlistsForHall = _.findWhere(playlistsGroups, {hallID});

		return playlistsForHall ? playlistsForHall.movieShows[weekDate] : [];
	},
);

export const doesPlaylistHasReadyContent = ({content_status: status, is_manual: isManual} = {}) =>
	isManual || !status || status === 'ready';

export const doesKeyExist = ({key_status: keyStatus, content_status: contentStatus} = {}) =>
	contentStatus === 'link_required'
	|| contentStatus === 'missing'
	|| keyStatus !== 'not_exists';

export const doesKeyNotFullExist = ({key_status: status} = {}) => status === 'not_full';

const getHasCriticalContentStatus = ({content_status: status} = {}) => ContentCriticalStatuses.includes(status);

const getHasContentError = playlist =>
	![playlist, playlist.advertising_status, playlist.trailers_status, playlist.transitional_status]
		.every(doesPlaylistHasReadyContent);

const getHasKeyError = playlist =>
	![playlist, playlist.advertising_status, playlist.trailers_status, playlist.transitional_status].every(doesKeyExist);

const getHasContentCriticalError = playlist =>
	[playlist, playlist.advertising_status, playlist.trailers_status, playlist.transitional_status]
		.some(getHasCriticalContentStatus);

const getHasWarningContentStatus = ({content_status: contentStatus} = {}) => contentStatus === 'queued';

export const getIsSalesClosed = ({on_sale: onSale} = {}) => onSale === 0;

export const getIsNextSeanceTooClose = ({next_seance_status: nextSeanceStatus}) => nextSeanceStatus === 'too_close';

export const getIsLightMarkMissing = ({light_status: lightStatus} = {}) => !lightStatus;

export const getIsTrailersLightMarkMissing = ({integrated_trailers_statuses: integratedTrailersStatuses = {}}) =>
	integratedTrailersStatuses.light === 0;

export const getIsTrailersSoundMarkMissing = ({integrated_trailers_statuses: integratedTrailersStatuses = {}}) =>
	integratedTrailersStatuses.sound === 0;

export const getHasPlaylistWarning = playlist =>
	getIsSalesClosed(playlist) ||
	getHasWarningContentStatus(playlist) ||
	getIsTrailersSoundMarkMissing(playlist) ||
	getIsTrailersLightMarkMissing(playlist) ||
	getIsLightMarkMissing(playlist);

export const getHasPlaylistError = playlist =>
	getHasContentError(playlist) ||
	getHasKeyError(playlist) ||
	getIsNextSeanceTooClose(playlist);

export const makeGetPlaylistsProblems = () => createSelector(
	(_, props) => props.playlists,
	playlists => {
		const playlistsNotManual = _.filter(playlists, playlist => !playlist.is_manual);

		const problems = _.reduce(playlistsNotManual, (memo, playlist) => {
			if (!memo.nextTooClose.isExist && getIsNextSeanceTooClose(playlist)) {
				memo.nextTooClose.isExist = true;
			}

			if (!memo.key.statuses.missing && getHasKeyError(playlist)) {
				memo.key.isExist = true;
				memo.key.statuses.missing = true;
			}

			if (!memo.key.statuses.not_full && doesKeyNotFullExist(playlist)) {
				memo.key.isExist = true;
				memo.key.statuses.not_full = true;
			}

			if (!memo.content.isExist && getHasContentError(playlist)) {
				memo.content.isExist = true;
			}

			if (!memo.light.isExist && (getIsLightMarkMissing(playlist) || getIsTrailersLightMarkMissing(playlist))) {
				memo.light.isExist = true;
			}

			if (!memo.sound.isExist && getIsTrailersSoundMarkMissing(playlist)) {
				memo.sound.isExist = true;
			}

			return memo;
		}, {
			nextTooClose: {isExist: false, statuses: {}},
			content: {isExist: false, statuses: {}},
			key: {isExist: false, statuses: {}},
			sound: {isExist: false, statuses: {}},
			light: {isExist: false, statuses: {}},
		});

		return _.chain(problems)
			.pick(({isExist}) => isExist)
			.reduce((memo, {statuses}, type) => [...memo, {type, statuses: _.keys(statuses)}], [])
			.map(
				({type, statuses}) => (!_.isEmpty(statuses)
					? statuses.map(status => ({type, status}))
					: {type}),
			)
			.flatten()
			.value();
	},
);

export const makeGetWholeDayPlaylistsStatus = () => createSelector(
	(_, props) => props.playlists,
	currentCinemaTimeZone,
	(playlists = [], timezone) => {
		const filteredPlaylists = playlists.filter(({start}) => start.unix() > moment().utcOffset(timezone).unix());

		if (!filteredPlaylists.length) return null;

		if (filteredPlaylists.some(({playlist_status: status}) => status === 'error')) {
			return 'error';
		}

		if (filteredPlaylists.every(({playlist_status: status}) => status === null)) {
			return 'allNotReady';
		}

		if (
			filteredPlaylists.some(({playlist_status: status}) => status === null) &&
			filteredPlaylists.some(({playlist_status: status}) => status === 'ready')
		) {
			return 'someNotReady';
		}

		if (filteredPlaylists.every(({playlist_status: status}) => status === 'ready')) {
			if (filteredPlaylists.some(getHasContentCriticalError) || filteredPlaylists.some(getHasKeyError)) {
				return 'readyWithErrors';
			}
			if (filteredPlaylists.some(getHasContentError)) {
				return 'contentNotReady';
			}

			return 'ready';
		}

		if (filteredPlaylists.every(({playlist_status: status}) => status === 'pending')) {
			return 'pending';
		}
	},
);

export const transformChannelValues = values => _.map(values, item => ({
	title: item,
	value: item,
}));

export const getPlaylistsByHallsAndDateRange = (playlists, dateStart, dateEnd, halls) =>
	_.filter(playlists, playlist => _.contains(halls, playlist.hall_id)
		&& playlist.start >= dateStart && playlist.start <= dateEnd);

export const getUploadHallsOptions = createSelector(
	[getUserCurrentCinema, getPlayservers],
	(cinema, playservers) => _.map(cinema.halls, hall => {
		const server = _.findWhere(playservers, {hall_id: hall.id});

		return {
			id: hall.id,
			title: `${i18n.t('common.Hall')} ${hall.number || '-'}`,
			isDisabled: !(server && server.has_macroses && server.state),
		};
	}),
);

export const areAnyPlayserversAvailable = createSelector(
	[getPlayservers],
	playservers => _.some(playservers, server => server.has_macroses && server.state),
);

export const makeGetGeneratingStatusByDayAndHall = () => createSelector(
	getGeneratingDaysByHall, isChangeStatusGenerating,
	(_, props) => props,
	(daysByHall, isStatusChange, props) => (daysByHall[props.hallID] || []).includes(props.weekDate) && !isStatusChange,
);

export const playlistsIsGenerating = createSelector(
	getGeneratingDaysByHall, isChangeStatusGenerating,
	(daysByHall, isStatusChange) =>
		!_.isEmpty(daysByHall) && !isStatusChange &&
		_.keys(daysByHall).some(hallId => daysByHall[hallId].length),
);

export const isPlaylistsRoute = state => getCurrentURL(state).includes('playlists');

export const getCurrentLightOffset = content => {
	const currentLightCue = _.find(content.cue, cue => cue.type === 'light' && cue.offset !== 0);

	return currentLightCue ? currentLightCue.offset : 0;
};

const getWeek = (state, date) => date;
const getHallID = (state, date, hallID) => hallID;

const isEditableSoundCue = cue => (_.contains([cue.type, cue.sub_type], 'sound') || cue.sub_type === 'imax_sound')
	&& cue.block_type !== 'main_after_trailers';

export const getCurrentSoundValue = (timeline, contentIndex) => {
	const showsBeforeCurrent = timeline.slice(0, contentIndex + 1).reverse();
	const lastSoundCue = _.chain(showsBeforeCurrent)
		.pluck('cue')
		.map(cues => _.reverse(cues))
		.flatten()
		.find(isEditableSoundCue)
		.value();
	const value = _.property('value')(lastSoundCue);

	return _.isUndefined(value) ? null : value;
};

export const getTimelineChanges = (playlistChanges, packageID, data) => (
	_.findWhere(playlistChanges, {id: packageID})
		? _.map(playlistChanges, item => {
			if (item.id === packageID) return {...item, ...data};

			return item;
		})
		: [...playlistChanges, data]
);

export const combineCues = (packageCues, rewindCues = []) => {
	const contentCues = _.chain(packageCues)
		.groupBy('offset')
		.map((cues, offset) => ({
			offset: parseInt(offset, 10),
			cues,
		}))
		.value();

	return _.sortBy(
		[
			...contentCues,
			...rewindCues.map(rewind => ({type: 'rewind', cues: rewind, offset: rewind.from})),
		],
		'offset',
	);
};
/**
 * Compares passed dates in business way.
 *
 * @param {string} date Date as YYYY-MM-DD
 * @param {number} currentDate unix timestamp
 * @returns {boolean}
 */
export function hasBusinessDayPassed(date, currentDate) {
	const businessDayEndTimestamp = moment(date).add(BUSINESS_DAY_LIMIT + 24, 'h').unix();

	return businessDayEndTimestamp < currentDate;
}
