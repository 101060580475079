import {getURLForRequest} from 'app/app/AppSelectors';
import {setAuthentication, getAuthUserID, getAuthToken} from 'app/authentication/common';
import {routes} from 'app/authentication/constants';

export class SyncRequest {
	static parseResponse(response) {
		let result;

		try {
			result = JSON.parse(response);
		} catch (error) {
			result = null;
		}

		return result;
	}

	request({method, url, data, onSuccess, onError}) {
		const requestURL = this.isWithoutBaseURL ? url : `${getURLForRequest(url) || ''}${url}`;

		this.xhr = new XMLHttpRequest();
		this.xhr.open(method, requestURL, false);
		this.xhr.send(JSON.stringify(data));

		if (this.xhr.status >= 200 && this.xhr.status < 300 && onSuccess) {
			onSuccess(this.constructor.parseResponse(this.xhr.responseText));
		} else if (onError) {
			onError(this.xhr.status);
		}
	}

	checkAuth(onSuccess, onError) {
		this.isWithoutBaseURL = true;
		this.request({
			method: 'GET',
			url: '/api/account/check_auth',
			onSuccess: response => {
				setAuthentication(response.api_token, response.user_id);

				if (onSuccess) onSuccess(response);
			},
			onError: error => {
				this.request({
					method: 'POST',
					url: '/api/account/logout',
					data: {user_id: getAuthUserID(), api_token: getAuthToken()},
				});

				if (onError) onError(error);
				setAuthentication();

				if (window.REDIRECT_URL) {
					document.location.href = window.REDIRECT_URL;
				} else {
					document.location.href = routes.LOGIN;
				}
			},
		});
	}
}
