import {createAction} from 'redux-actions';
import Request from 'app/common/Request';
import {getUserCurrentCinemaID} from 'app/user/userSelectors';
import {updateCinemaTmsSettings} from 'app/user/actions/UserActions';
import * as types from 'app/tms_playlists_legacy/constants/PlaylistsConstants';
import {
	showLoader,
	hideLoader,
	showPopup,
	handleError,
	addErrorToast,
	addSuccessToast,
} from 'app/app/AppActions';
import {hidePopup} from 'app/app/appHelpers';
import {
	getAutouploadsErrorMessage,
	getRequestErrorMessage,
	isPackageChangedForSimilarPlaylists,
} from 'app/tms_playlists_legacy/selectors/PlaylistsSelectors';
import {batch} from 'react-redux';

const requestPlaylistsSuccess = createAction(
	types.SET_PLAYLISTS_CONTENT,
	response => ({...response}),
);

const setLastUCSDownloadDate = createAction(
	types.SET_LAST_UCS_DOWNLOAD_DATE,
	commonUCSDownloadDate => ({commonUCSDownloadDate}),
);

export const setPlaylistsAutoUploadInfo = createAction(
	types.PLAYLIST_SET_AUTO_UPLOAD_INFO,
	autoUploadInfo => ({autoUploadInfo}),
);

export const setIsPackageChangedForSimilarPlaylists = createAction(
	types.SET_IS_PACKAGE_CHANGED_FOR_SIMILAR_PLAYLISTS,
	flag => ({flag}),
);

export const setGeneratingDays = createAction(
	types.SET_GENERATING_DAYS,
	dates => ({dates}),
);

export const initializePlaylistSocketQueue = createAction(
	types.PLAYLIST_INITIALIZE_SOCKET_QUEUE,
);

export const applyPlaylistSocketQueue = createAction(
	types.PLAYLIST_APPLY_SOCKET_QUEUE,
);

export const addPlaylistsToSocketQueue = createAction(
	types.PLAYLIST_ADD_TO_SOCKET_QUEUE,
	message => ({message}),
);

export const getPlaylists = (cinemaID, date) => (dispatch, getState) => {
	const momentDate = moment(date);
	const state = getState();

	return Promise.all([
		!isPackageChangedForSimilarPlaylists(state)
			? new Request().get(`${types.PlaylistsAPIRootURL}/${cinemaID}/${momentDate.year()}/${momentDate.week()}`)
			: Promise.resolve(),
		new Request().get(`/api/tms/v1/${cinemaID}/playservers`),
		new Request().get(`/api/tms/v3/${cinemaID}/ucs_last_update`),
	])
		.then(response => _.object(['playlists', 'playservers', 'commonUCSDownloadDate'], response))
		.then(({playlists, playservers, commonUCSDownloadDate: {time}}) => {
			batch(() => {
				dispatch(setLastUCSDownloadDate(time));

				if (!isPackageChangedForSimilarPlaylists(state)) {
					dispatch(requestPlaylistsSuccess({playlists, playservers}));
				}
			});
		})
		.catch(error => {
			batch(() => {
				dispatch(handleError(error));
				dispatch(addErrorToast({subtitle: i18n.t('notifications.ConnectionError')}));
			});
		})
		.finally(() => {
			dispatch(setIsPackageChangedForSimilarPlaylists(false));
		});
};

export const setPreloadAccessStatus = createAction(
	types.PLAYLIST_SET_PRELOAD_ACCESS_STATUS,
	status => ({status}),
);

export const getLastUCSDownloadDate = cinemaID => dispatch =>
	new Request().get(`/api/tms/v3/${cinemaID}/ucs_last_update`)
		.then(({time}) => dispatch(setLastUCSDownloadDate(time)))
		.catch(() => {
			dispatch(addErrorToast({
				subtitle: i18n.t('notifications.ConnectionError'),
			}));
		});

export const changeDate = createAction(
	types.PLAYLISTS_CHANGE_DATE,
	date => date.startOf('week'),
);

export const updatePlaylists = createAction(
	types.PLAYLISTS_UPDATE,
	shows => ({shows}),
);

export const addPlaylists = createAction(
	types.PLAYLISTS_ADD,
	shows => ({shows}),
);

export const deletePlaylists = createAction(
	types.PLAYLISTS_DELETE,
	ids => ({ids}),
);

const setPlaylistsUploadTimeouts = createAction(
	types.SET_UPLOAD_PLAYLISTS_TIMEOUTS,
	halls => ({halls}),
);

const clearPlaylistsUploadTimeouts = createAction(
	types.CLEAR_UPLOAD_PLAYLISTS_TIMEOUTS,
	halls => ({halls}),
);

export const setGeneratingDaysByHall = createAction(
	types.SET_GENERATING_DAYS_BY_HALL,
	(halls, dateStart, dateEnd) => ({halls, dateStart, dateEnd}),
);

export const removeGeneratingDayByHall = createAction(
	types.REMOVE_GENERATING_DAY_BY_HALL,
	(hallId, date) => ({hallId, date}),
);

export const resetStatusGenerating = createAction(
	types.RESET_STATUS_GENERATING,
);

export function handleUploadToTms({start, end, halls, cinemaID}) {
	return dispatch => {
		const hallIDs = _.pluck(halls, 'id');

		batch(() => {
			dispatch(setPlaylistsUploadTimeouts(hallIDs));
			dispatch(showLoader());
		});

		setTimeout(() => {
			dispatch(clearPlaylistsUploadTimeouts(hallIDs));
		}, 15000);

		return new Request().put(`/api/tms/shows/v2/${cinemaID}/upload`, {
			start,
			end,
			halls: hallIDs,
		})
			.then(() => {
				batch(() => {
					dispatch(showPopup(null));
					dispatch(addSuccessToast({title: i18n.t('tmsPlaylistsLegacy:TimeWarning')}));
				});
			})
			.catch(error => {
				batch(() => {
					dispatch(handleError(error));
					dispatch(addErrorToast({subtitle: i18n.t('tmsPlaylistsLegacy:UploadError')}));
				});
			}).finally(() => {
				dispatch(hideLoader());
			});
	};
}

/**
 * Make request to set auto_upload_playlists flag for cinema.
 *
 * @param {number} cinemaID
 * @param {0|1} flagValue
 */
export function toggleAutoUploadRequest(cinemaID, flagValue) {
	return async function(dispatch) {
		try {
			const result = await new Request().post(`/api/tms/shows/v2/${cinemaID}/auto_upload_playlists`, {
				auto_upload_playlists: flagValue,
			});

			const code = _.get(result.error, 'code');

			batch(() => {
				dispatch(updateCinemaTmsSettings({
					cinema_id: cinemaID,
					auto_upload_playlists: flagValue,
				}));

				if (code) {
					dispatch(addErrorToast({subtitle: i18n.t(getAutouploadsErrorMessage(code))}));
				}
			});

			return result;
		} catch (error) {
			dispatch(addErrorToast({subtitle: i18n.t(getRequestErrorMessage(error.status))}));
		}
	};
}

export const autoUploadStatusesRequest = (cinemaID, date) => async dispatch => {
	try {
		const getMomentDate = moment(date);

		const response = await new Request()
			.get(`/api/tms/shows/v1/${cinemaID}/${getMomentDate.year()}/${getMomentDate.week()}/auto_upload`);

		dispatch(setPlaylistsAutoUploadInfo(response));
	} catch (error) {
		dispatch(handleError(error));
	}
};

export const getPreloadStatusRequest = cinemaID => async dispatch => {
	try {
		const {count} = await new Request().get(`/api/tms/shows/v3/${cinemaID}/preload/status`);

		dispatch(setPreloadAccessStatus(Boolean(count)));
	} catch (error) {
		dispatch(handleError(error));
	}
};

export const getPreloadStatusRequestDebounced = _.debounce(
	(cinemaID, dispatch) => dispatch(getPreloadStatusRequest(cinemaID)), 5000,
);

// Generation
const checkPlaylistsGenerationSuccess = createAction(
	types.CHECK_PLAYLISTS_GENERATION_SUCCESS,
	checkItems => ({checkItems}),
);

const setPlaylistsFetchingStatus = createAction(
	types.SET_PLAYLISTS_FETCHING_STATUS,
	status => ({status}),
);

const setFetchingGeneration = createAction(
	types.SET_FETCHING_GENERATION,
	status => ({status}),
);

export const checkPlaylists = (dateStart, dateEnd, halls) => async (dispatch, getState) => {
	try {
		dispatch(setPlaylistsFetchingStatus(true));

		const cinemaID = getUserCurrentCinemaID(getState());
		const params = decodeURIComponent($.param({
			date_start: dateStart.format(),
			date_end: dateEnd.format(),
			halls: halls.join(','),
		}));

		const response = await new Request().get(`/api/tms/shows/v3/${cinemaID}/check_playlist?${params}`);

		dispatch(checkPlaylistsGenerationSuccess(response));
	} catch (error) {
		dispatch(handleError(error));
	} finally {
		setPlaylistsFetchingStatus(false);
	}
};

export function generatePlaylists(dateStart, dateEnd, halls) {
	return (dispatch, getState) => {
		dispatch(setFetchingGeneration(true));

		const cinemaID = getUserCurrentCinemaID(getState());
		dispatch(setGeneratingDaysByHall(halls, dateStart, dateEnd));

		return new Request().post(`/api/tms/shows/v2/${cinemaID}/generate`, {
			date_start: dateStart.format(),
			date_end: dateEnd.format(),
			halls,
		})
			.then(() => {
				dispatch(hidePopup());
			})
			.catch(() => {
				batch(() => {
					dispatch(setGeneratingDaysByHall([], dateStart, dateEnd));
					dispatch(addErrorToast({subtitle: i18n.t('tmsPlaylistsLegacy:UploadError')}));
				});
			})
			.finally(() => {
				dispatch(setFetchingGeneration(false));
			});
	};
}
