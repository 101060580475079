import {createSelector} from 'reselect';
import {
	canUserEditRepertory,
	isUserMultiplex as isUserMultiplexSelector,
	getUserCurrentDate,
	getUserCurrentCinemaID,
	getUserFeatureAwareness,
} from 'app/user/userSelectors';
import {
	getCurrentCinemaViewMode,
	getWeekIntervals,
} from 'app/repertory/selectors/repertoryCommonSelectors';
import { makeRelease } from 'app/repertory/RepertoryHelpers';

export const isUserAwarenessToRepertory = createSelector(
	getUserFeatureAwareness,
	userFeatureAwareness => _.get(userFeatureAwareness, 'tutorial') === 1,
);

export const getPlannedReleases = state => state.repertoryReducer.releases;
export const getRepertoryCurrentSection = state => state.repertoryReducer.currentSection;

export const isReleaseInRepertory = (state, releaseID) => (
	!!_.findWhere(getPlannedReleases(state), {release_id: releaseID})
);

const getReleases = state => (
	state.repertoryReducer.isActiveSearch ?
		state.repertoryReducer.foundReleasesByTitle :
		state.repertoryReducer.foundReleasesByDate
);

export const getReleaseByCinema = (releases, releaseID, cinemaID) => _.findWhere(
	releases,
	{release_id: releaseID, cinema_id: cinemaID},
) || {};

export const sortByTitle = state => state.repertoryReducer.sortedByTitle;
export const sortByRating = state => state.repertoryReducer.sortedByRating;
export const groupByRegion = state => state.repertoryReducer.groupByRegion;
export const getFoundReleasesByTitle = state => state.repertoryReducer.foundReleasesByTitle;

export const getReleasesHighlight = state => state.repertoryReducer.releasesHighlight;
export const getLastViewMode = state => state.repertoryReducer.lastCinemaViewMode;
export const getCurrentReleasesViewMode = state => state.repertoryReducer.currentReleasesViewMode;

export const getGroupedReleases = createSelector(
	[getReleases, sortByTitle, sortByRating, groupByRegion],
	(releases, byTitle, byRating, byRegion) => {
		let sortedReleases = releases;
		const sortingFunc = getCriteria => _.chain(sortedReleases)
			.sortBy(release => getCriteria(release))
			.sortBy(release => !!release.date_end)
			.value();

		if (byTitle) sortedReleases = sortingFunc(release => _.getLocalize(release.title));
		if (byRating) sortedReleases = sortingFunc(release => -parseFloat(release.analyze_percent));

		return _.partition(sortedReleases, release => release.russian_release && byRegion);
	},
);

export const getSumCount = (weeks = []) => _.chain(weeks)
	.pluck('count')
	.reduce((memo, num) => (memo + num), 0)
	.value();

export const getPlanRange = (weeks = []) => (weeks.length ? {
	start: moment.min(_.map(weeks, week => moment(week.date_start))),
	end: moment.max(_.map(weeks, week => moment(week.date_end))),
	count: getSumCount(weeks),
	status: _.first(weeks).status,
} : {});

export const getGroupedRows = (restReleases, groupedReleases = []) => {
	if (restReleases.length === 0) return groupedReleases;
	if (restReleases.length === 1) return [...groupedReleases, restReleases];

	const newGroupedReleases = restReleases.reduce((memo, nextRelease) => {
		const prevRelease = _.last(memo);

		const prevReleasePlan = getPlanRange(prevRelease.weeks);
		const nextReleasePlan = getPlanRange(nextRelease.weeks);

		if (moment(prevReleasePlan.end).isBefore(nextReleasePlan.start, 'day')) {
			return [...memo, nextRelease];
		}

		return memo;
	}, [_.first(restReleases)]);

	return getGroupedRows(
		restReleases.filter(release => !_.contains(newGroupedReleases, release)),
		[...groupedReleases, newGroupedReleases],
	);
};

/**
 * Get release weeks that will be used to add release
 * @param {Object} release - Release from repertory
 * @param {string} date - YYYY-MM-DD
 * @param {Array.<number>} cinemaFormatsID - Array of video formats supported in cinema
 * @param {number} cinemaReleasePeriod - Number of weeks to add
 * @param {Array.<Object>} cinemaHalls
 * @param {Array.<{hall_id: number, comment: string}>} hallsToAdd - Release will be added to this halls
 *
 * @return {Array.<Object>} newWeeks - Weeks of release in specified halls
 */

export const getReleaseWeeksForSave = ({release, date, cinemaFormatsID, cinemaReleasePeriod, cinemaHalls, hallsToAdd = []}) => {
	const formats = _.intersection(release.formats, cinemaFormatsID);
	const dateMoment = moment(date, 'YYYY-MM-DD');
	const newWeeks = [];

	_.times(cinemaReleasePeriod, index => {
		const weekStart = dateMoment.clone().add(index, 'w').startOf('w').format();
		const weekEnd = dateMoment.clone().add(index, 'w').endOf('w').format();
		const dateStartOnWeek = moment(release.date).isBetween(weekStart, dateMoment.clone().weekday(6), 'day', '[]');

		const week = _.map(formats, format => ({
			release_id: release.release_id,
			date_start: dateStartOnWeek ? release.date : dateMoment.clone().add(index, 'w').weekday(0).format(),
			date_end: weekEnd,
			formats: [format],
			count: 0,
			status: 'planned',
			halls: _.filter(hallsToAdd, hall => (
				_.contains((_.findWhere(cinemaHalls, {id: hall.hall_id}) || {}).formats_id, format)
			)),
		}));
		newWeeks.push(...week);
	});

	return newWeeks;
};

export function getOccupancyMessage(freeMinutes, format) {
	const momentDuration = moment.duration(Math.abs(freeMinutes), 'minutes');
	const minutes        = momentDuration.minutes();
	const hours          = Math.floor(momentDuration.asHours());

	let occupancyTime = freeMinutes < 0 ? '- ' : '';

	if (format === 'minutes') {
		occupancyTime = `${freeMinutes} ${i18n.t('shortTime.minutes')}.`;
	} else {
		occupancyTime = hours ? `${hours} ${i18n.t('shortTime.hours')}.` : '';
		occupancyTime += ` ${minutes} ${i18n.t('shortTime.minutes')}. `;
	}

	return occupancyTime;
}

export const isReleaseEditable = (state, weeks = []) => canUserEditRepertory(state) &&
	(_.first(weeks) || {}).status !== 'approved_by_distributor';

const getProposals = state => state.repertoryReducer.proposals;

export const getProposalForCurrentCinema = (state, cinemaID) =>
	_.find(getProposals(state), proposal => proposal.delivery.cinema_id === cinemaID);

export const parseProposal = proposal =>
	_.pluck((proposal || {}).weeks, 'count');

export const hasReleaseProposal = (state, releaseID) => {
	const release = _.findWhere(getPlannedReleases(state), {release_id: releaseID}) || {};

	return _.some(release.weeks, week => !_.isNull(week.proposals));
};

export const getReleaseListSearchValue = state => state.repertoryReducer.searchValue;
export const getReleaseListSearchLocation = state => state.repertoryReducer.location;

export const getMonth = createSelector(
	getWeekIntervals,
	weekIntervals => _.groupBy(weekIntervals, weekInterval => _.first(weekInterval).format('YYYYMM')),
);

export const getTabs = createSelector(
	isUserMultiplexSelector,
	isUserMultiplex => _.compact([
		isUserMultiplex && {
			tab: 'network',
			title: i18n.t('repertory:ByNetwork'),
		}, {
			tab: 'cinema',
			title: i18n.t('repertory:ByCinema'),
		},
	]),
);

export const getCountSeancesAndPercent = createSelector(
	getPlannedReleases,
	getUserCurrentDate,
	getCurrentCinemaViewMode,
	(releases, currentDate, mode) => {
		const date = currentDate.clone().startOf('month').add(6, 'days').weekday(0);
		const firstDay = _.first(_.first(getWeekIntervals.resultFunc(date, mode)));
		const lastDay = _.last(_.last(getWeekIntervals.resultFunc(date, mode)));

		const seances = _.chain(releases)
			.pluck('weeks')
			.flatten()
			.reduce((memo, week) => {
				const release = getReleaseByCinema(releases, week.release_id, week.cinema_id);
				const firstWeekDay = moment(week.date_start);
				const lastWeekDay = moment(week.date_end);
				const plannedWeekDays = lastWeekDay.diff(firstWeekDay, 'days') + 1;

				if (firstWeekDay.isBetween(firstDay, lastDay, 'day', '[]')) {
					if (release.russian_release === 1) {
						memo.ru += week.count * plannedWeekDays;
					} else {
						memo.ext += week.count * plannedWeekDays;
					}
				}

				return memo;
			}, {ru: 0, ext: 0}).value();

		const seancesRu  = seances.ru;
		const seancesExt = seances.ext;
		let percentsRu   = 0;
		let percentsExt  = 0;

		if ((seancesRu || seancesExt) !== 0) {
			const sum = seancesRu + seancesExt;
			percentsRu  = Math.round(seancesRu / sum * 100);
			percentsExt = 100 - percentsRu;
		}

		return {percentsRu, seancesRu, percentsExt, seancesExt};
	},
);

export const getWeekIntervalsRange = createSelector(
	getWeekIntervals,
	weekIntervals => (
		weekIntervals.length
			? {start: _.first(_.first(weekIntervals)), end: _.last(_.last(weekIntervals))}
			: {}
	),
);

export const getCellsCount = createSelector(
	getWeekIntervalsRange,
	weekIntervalsRange => weekIntervalsRange.end.diff(weekIntervalsRange.start, 'days') + 1,
);

export const getRenderedReleases = createSelector(
	getPlannedReleases,
	getCurrentReleasesViewMode,
	getWeekIntervalsRange,
	(releases, releasesViewMode, weekIntervalsRange) => {
		const filteredReleases = _.chain(releases)
			.filter(release => {
				const planRange = getPlanRange(release.weeks);

				return _.isEmpty(planRange) ? false : _.getDatesIntersection(planRange, weekIntervalsRange);
			})
			.map(makeRelease)
			.sortBy(release => {
				const {plan_start: planStart, title, cinema_id: cinemaID} = release;

				return [moment(planStart).unix(), _.getLocalize(title), cinemaID];
			})
			.value();

		return releasesViewMode === 'compact'
			? getGroupedRows(filteredReleases)
			: filteredReleases.map(release => [release]);
	},
);

export const getAllReleasesWeeks = createSelector(
	getPlannedReleases,
	getUserCurrentCinemaID,
	(releases, cinemaID) => _.chain(releases)
		.filter(release => release.cinema_id === cinemaID)
		.pluck('weeks')
		.flatten()
		.value(),
);
