import Cookies from 'js-cookie';
import {googleTMAction} from 'app/common/googleTM';

export const GET_REPERTORY_SUCCESS = 'GET_REPERTORY_SUCCESS';

export const REPERTORY_SET_CURRENT_SECTION = 'REPERTORY_SET_CURRENT_SECTION';
export const REPERTORY_ADD_WEEKS_BY_SOCKET = 'REPERTORY_ADD_WEEKS_BY_SOCKET';
export const REPERTORY_CHANGE_WEEKS_BY_SOCKET = 'REPERTORY_CHANGE_WEEKS_BY_SOCKET';
export const REPERTORY_DELETE_WEEKS_BY_SOCKET = 'REPERTORY_DELETE_WEEKS_BY_SOCKET';
export const REPERTORY_DELETE_RELEASE_BY_SOCKET = 'REPERTORY_DELETE_RELEASE_BY_SOCKET';

export const GET_REPERTORY_DISTRIBUTOR_PROPOSAL_REQUEST = 'GET_REPERTORY_DISTRIBUTOR_REQUEST';
export const GET_REPERTORY_DISTRIBUTOR_PROPOSAL_SUCCESS = 'GET_REPERTORY_DISTRIBUTOR_SUCCESS';

export const GET_FOUND_RELEASES_BY_DATE_REQUEST = 'GET_FOUND_RELEASES_REQUEST';
export const GET_FOUND_RELEASES_BY_DATE_SUCCESS = 'GET_FOUND_RELEASES_BY_DATE_SUCCESS';
export const GET_FOUND_RELEASES_BY_DATE_FAIL = 'GET_FOUND_RELEASES_FAIL';

export const GET_FOUND_RELEASES_BY_TITLE_REQUEST = 'GET_FOUND_RELEASES_BY_TITLE_REQUEST';
export const GET_FOUND_RELEASES_BY_TITLE_SUCCESS = 'GET_FOUND_RELEASES_BY_TITLE_SUCCESS';
export const GET_FOUND_RELEASES_BY_TITLE_FAIL = 'GET_FOUND_RELEASES_BY_TITLE_FAIL';
export const SET_RELEASE_LIST_SEARCH_VALUE = 'SET_RELEASE_LIST_SEARCH_VALUE';
export const SET_RELEASE_LIST_SEARCH_LOCATION = 'SET_RELEASE_LIST_SEARCH_LOCATION';

export const REPERTORY_ADD_RELEASE = 'REPERTORY_ADD_RELEASE';
export const REPERTORY_ADD_RELEASE_FROM_SEARCH = 'REPERTORY_ADD_RELEASE_FROM_SEARCH';
export const REPERTORY_DELETE_RELEASE = 'REPERTORY_DELETE_RELEASE';
export const REPERTORY_DELETE_RELEASE_FROM_SEARCH = 'REPERTORY_DELETE_RELEASE_FROM_SEARCH';

export const TOGGLE_ADD_RELEASE_SORT = 'TOGGLE_ADD_RELEASE_SORT';
export const DISABLE_ADD_RELEASE_SEARCH = 'DISABLE_ADD_RELEASE_SEARCH';

export const CHANGE_RELEASES_HIGHLIGHT = 'CHANGE_RELEASES_HIGHLIGHT';

export const CHANGE_CURRENT_CINEMA_VIEW_MODE = 'CHANGE_CURRENT_CINEMA_VIEW_MODE';
export const CHANGE_CURRENT_RELEASES_VIEW_MODE = 'CHANGE_CURRENT_RELEASES_VIEW_MODE';

export const SET_LAST_CINEMA_VIEW_MODE = 'SET_LAST_CINEMA_VIEW_MODE';

export const SET_NOTE = 'SET_NOTE';
export const REMOVE_NOTE = 'REMOVE_NOTE';

export const WEEK_TABLE_CELL_WIDTH = 85;
export const MAX_FORMATS_IN_DEFAULT_WIDTH_POPUP = 5;

export const REPERTORY_UPDATE_RELEASE_AND_WEEKS = 'REPERTORY_UPDATE_RELEASE_AND_WEEKS';
export const REPERTORY_SET_CURRENT_RELEASE = 'REPERTORY_SET_CURRENT_RELEASE';

export const REPERTORY_NETWORK_CHANGE_CINEMAS_ORDER = 'REPERTORY_NETWORK_CHANGE_CINEMAS_ORDER';
export const REPERTORY_NETWORK_SET_CINEMAS = 'REPERTORY_NETWORK_SET_CINEMAS';
export const REPERTORY_NETWORK_SET_CINEMA_FILTER = 'REPERTORY_NETWORK_SET_CINEMA_FILTER';
export const REPERTORY_NETWORK_SET_ALREADY_CALLED = 'REPERTORY_NETWORK_SET_ALREADY_CALLED';
export const REPERTORY_NETWORK_SET_COPY_RELEASES = 'REPERTORY_NETWORK_SET_COPY_RELEASES';
export const REPERTORY_NETWORK_SET_RELEASES = 'REPERTORY_NETWORK_SET_RELEASES';
export const REPERTORY_NETWORK_SET_RELEASES_IS_LOADING = 'REPERTORY_NETWORK_SET_RELEASES_IS_LOADING';
export const REPERTORY_NETWORK_SET_RELEASES_TOTAL = 'REPERTORY_NETWORK_SET_RELEASES_TOTAL';
export const REPERTORY_NETWORK_DELETE_RELEASE = 'REPERTORY_NETWORK_DELETE_RELEASE';
export const REPERTORY_NETWORK_UPDATE_RELEASE_AND_WEEKS = 'REPERTORY_NETWORK_UPDATE_RELEASE_AND_WEEKS';
export const REPERTORY_ADD_NEW_RELEASE_AND_WEEKS = 'REPERTORY_ADD_NEW_RELEASE_AND_WEEKS';
export const REPERTORY_NETWORK_ADD_RELEASE = 'REPERTORY_NETWORK_ADD_RELEASE';
export const REPERTORY_NETWORK_ADD_WEEKS_BY_SOCKET = 'REPERTORY_NETWORK_ADD_WEEKS_BY_SOCKET';
export const REPERTORY_NETWORK_CHANGE_WEEKS_BY_SOCKET = 'REPERTORY_NETWORK_CHANGE_WEEKS_BY_SOCKET';
export const REPERTORY_NETWORK_DELETE_WEEKS_BY_SOCKET = 'REPERTORY_NETWORK_DELETE_WEEKS_BY_SOCKET';
export const REPERTORY_UPDATE_RELEASE_LAST_ORDER = 'REPERTORY_UPDATE_RELEASE_LAST_ORDER';
export const REPERTORY_UPDATE_RELEASE_WEEKS_STATUS = 'REPERTORY_UPDATE_RELEASE_WEEKS_STATUS';
export const REPERTORY_UPDATE_RELEASE_COLOR = 'REPERTORY_UPDATE_RELEASE_COLOR';

const cinemaViewModes = {
	'fullMonth': 'full-month',
	'fullWeeks': 'full-week',
	'threeMonths': 'three-months',
};

export const cinemaViewModeTitle = () => {
	const cinemaViewMode = Cookies.get('currentCinemaViewMode') || 'fullWeeks';

	return cinemaViewModes[cinemaViewMode];
};

export const CinemaProps = PropTypes.shape({
	city: PropTypes.shape({
		en: PropTypes.string,
		ru: PropTypes.string,
		id: PropTypes.number,
		lat: PropTypes.number,
		lon:  PropTypes.number,
		population: PropTypes.number,
		region_id: PropTypes.number,
		special_uais_region_id: PropTypes.string,
		title: PropTypes.shape({
			en: PropTypes.string.isRequired,
			ru: PropTypes.string.isRequired,
		}),
	}),
	hall_count: PropTypes.number,
	id: PropTypes.number.isRequired,
	region_title: PropTypes.shape({
		en: PropTypes.string.isRequired,
		ru: PropTypes.string.isRequired,
	}),
	time_zone: PropTypes.number,
	title: PropTypes.shape({
		en: PropTypes.string.isRequired,
		ru: PropTypes.string.isRequired,
	}),
	cinema_network: PropTypes.object,
	has_proposals: PropTypes.bool,
	manager: PropTypes.string,
});

export const changeWeeksPopupTabs = {
	TIMETABLE: 'timetable',
	NOTE: 'note',
};

export const seancesStatuses = {
	PLANNED: 'planned',
	PROCESS: 'process',
	DISCUSS: 'discuss',
	APPROVED: 'approved',
	APPROVED_BY_DISTRIBUTOR: 'approved_by_distributor',
};

export const trackerHelper = event => {
	googleTMAction(`repertory_by-cinema_popup-edit-release_${event}`);
};

export const IMAX3DID = 17;

export const CINEMAS_TO_LOAD_COUNT = 5;
export const COUNT_OF_LETTERS_FOR_CINEMA_TITLE_SEARCH = 2;

export const makeFilterCinemaFormats = releaseFormats => format => (
	(format.id === IMAX3DID && _.contains(releaseFormats, format.id)) ||
	(format.exception && format.id !== IMAX3DID) ||
	_.contains(releaseFormats, format.id)
);
