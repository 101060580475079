import {createSelector} from 'reselect';
import {
	atLeastOneCinemaMaster,
	atLeastOneCinemaHasFullAccess,
	getUserCinemas,
	isUserMaster,
	hasFullAccess,
	isInSandbox,
	isRepertoireReadOnly,
	getUserCurrentCountry,
	isDistributor,
	hasActiveReleasesSubscription,
	getReleasesAccess,
} from 'app/user/userSelectors';
import {getIndexedGenres, getVideoFormats, getNotVideoFormats} from 'app/Reference';

export const canCreateRelease = state => (
	atLeastOneCinemaMaster(state) || atLeastOneCinemaHasFullAccess(state)
);

export const getBookerEmail = state => state.releasesReducer.bookerEmail;
export const getDistributorEmails = state => state.releasesReducer.distributorsEmails;
export const getAllDistributorsInCinema = state => state.releasesReducer.allDistributorsInCinema;
export const getLastReleaseDate = state => state.releasesReducer.lastReleaseDate;
export const getGenresList = state => state.releasesReducer.genresList;
export const getActorsList = state => state.releasesReducer.actorsList;
export const getDistributorInfo = state => state.releasesReducer.distributorInfo;
export const getCodeDistributorInfo = state => state.releasesReducer.distributorCode;
export const getStateCodeDistributorInfo = state => state.releasesReducer.invalidCode;

export const getDialogIDForBookerChat = createSelector(
	[getAllDistributorsInCinema],
	distributors => (_.first(distributors) || {}).dialog_id,
);

export const getAllNamesDistributorsInCinema = createSelector(
	[getAllDistributorsInCinema],
	distributors => _.pluck(distributors, 'name'),
);

export const getTimetableApprovedTime = createSelector(
	[getAllDistributorsInCinema],
	distributors => (_.first(distributors) || {}).approved_by_distributor,
);

export const getDeliveryInfo = (laboratories, labs, name) =>
	_.chain(laboratories)
		.pick(labs)
		.pluck(name)
		.compact()
		.uniq()
		.value();

export function deliveryByDist(id) {
	const deliveryByDistLaboratoryID = 8;

	return id === deliveryByDistLaboratoryID;
}

export const getSortedDatePoints = dates => _.chain(dates)
	.sort((date1, date2) => {
		date1.end = date1.end || date1.start;
		date2.end = date2.end || date2.start;

		if (moment(date1.start).isBefore(moment(date2.start))) {
			return -1;
		}
		if (moment(date2.start).isBefore(moment(date1.start))) {
			return 1;
		}

		return moment(date2.end).diff(date2.start) - moment(date1.end).diff(date1.start);
	})
	.pluck('string')
	.compact()
	.uniq()
	.value();

export const canUserEditNote = state => id =>
	isUserMaster(state, id) || hasFullAccess(state, id) || isInSandbox(state, id);

export const getCinemasForNoteDropdownOptions = createSelector(
	getUserCinemas,
	canUserEditNote,
	(cinemas, canEditNote) => _.chain(cinemas)
		.filter(cinema => canEditNote(cinema.id))
		.map(({id, title, city = {}}) => ({
			value: id,
			title: (
				<span>
					<span>{_.getLocalize(title)}</span>
					{' '}
					<span className="text text--gray600">{_.getLocalize(city.title)}</span>
				</span>
			),
		}))
		.value(),
);

export const hasReleaseAddProposalButton = (state, cinemaID, weeks) =>
	!isRepertoireReadOnly(state, cinemaID) &&
	(_.first(weeks) || {}).status !== 'approved_by_distributor';

export const getMultiSelectGenres = state => getGenresList(state)
	.filter(genre => genre.country === getUserCurrentCountry(state))
	.map(genre => ({value: genre.id, title: genre.title}));

export const getGenresIDs = (state, genresIDs = []) =>
	genresIDs.filter(id => (getIndexedGenres(state)[id] || {}).country === getUserCurrentCountry(state));

export const getSortedFormatsOptions = createSelector(
	getVideoFormats,
	videoFormats => _.sortBy(videoFormats, 'id')
		.map(format => ({
			value: format.id,
			name: _.getLocalize(format.title),
		})),
);

export const getSortedModiferOptions = createSelector(
	getNotVideoFormats,
	otherFormats => _.sortBy(otherFormats, 'id')
		.map(format => ({
			value: format.id,
			name: _.getLocalize(format.title),
		})),
);

export const getHasReleasesLicense = createSelector(
	isDistributor, getUserCinemas, getReleasesAccess, hasActiveReleasesSubscription,
	(isUserDistributor, cinemas, hasReleasesAccess, hasReleasesLicense) =>
		isUserDistributor || (hasReleasesAccess ? hasReleasesLicense : cinemas.length > 0),
);
