export const RELEASE_CARD_SET_RELEASE = 'RELEASE_CARD_SET_RELEASE' as const;
export const RELEASE_CARD_SET_IS_FETCHING = 'RELEASE_CARD_SET_IS_FETCHING' as const;
export const RELEASE_CARD_ADD_NOTE = 'RELEASE_CARD_ADD_NOTE' as const;
export const RELEASE_CARD_DELETE_NOTE = 'RELEASE_CARD_DELETE_NOTE' as const;
export const RELEASE_CARD_UPDATE_NOTE = 'RELEASE_CARD_UPDATE_NOTE' as const;
export const RELEASE_CARD_SET_COVER = 'RELEASE_CARD_SET_COVER' as const;
export const RELEASE_CARD_ADD_NEW_WEEKS = 'RELEASE_CARD_ADD_NEW_WEEKS' as const;
export const RELEASE_CARD_SET_WEEKS = 'RELEASE_CARD_SET_WEEKS' as const;
export const RELEASE_CARD_DELETE_WEEKS = 'RELEASE_CARD_DELETE_WEEKS' as const;
export const RELEASE_CARD_UPDATE_WEEKS = 'RELEASE_CARD_UPDATE_WEEKS' as const;
export const RELEASE_CARD_MASS_UPDATE = 'RELEASE_CARD_MASS_UPDATE' as const;
export const RELEASE_CARD_CHANGE_WEEKS_STATUS = 'RELEASE_CARD_CHANGE_WEEKS_STATUS' as const;
export const RELEASE_CARD_ADD_MY_CINEMA = 'RELEASE_CARD_ADD_MY_CINEMA' as const;
export const RELEASE_CARD_SET_DURATION = 'RELEASE_CARD_SET_DURATION' as const;
export const RELEASE_CARD_SET_ACTORS = 'RELEASE_CARD_SET_ACTORS' as const;
export const RELEASE_CARD_SET_ANALYTICS = 'RELEASE_CARD_SET_ANALYTICS' as const;
export const RELEASE_CARD_SET_PERSONS_LIST = 'RELEASE_CARD_SET_PERSONS_LIST' as const;
export const RELEASE_CARD_ADD_PERSON = 'RELEASE_CARD_ADD_PERSON' as const;
export const RELEASE_CARD_SET_LANGUAGE_ID = 'RELEASE_CARD_SET_LANGUAGE_ID' as const;
export const RELEASE_CARD_RESET = 'RELEASE_CARD_RESET' as const;
export const RELEASE_CARD_SET_LAST_ORDER = 'RELEASE_CARD_SET_LAST_ORDER' as const;
export const RELEASE_CARD_SET_ALT_INFOS = 'RELEASE_CARD_SET_ALT_INFOS' as const;
