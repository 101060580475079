import { getTx } from 'app/user/userSelectors';
import { getURLForRequest } from 'app/app/AppSelectors';
import { showLoader, hideLoader, handleError, downloadFile } from 'app/app/AppActions';
import { KINOPLAN_TOKEN, getAuthToken } from 'app/authentication/common';
import { SyncRequest } from 'app/common/SyncRequest';
import { Store, Action } from 'redux';
import IReduxState from 'app/store/IReduxState';
import { makeGetString } from 'app/mixins';
import { ReduxTypes } from 'app/store/redux';
import { JSONString } from 'app/common/helpersTypes';
import { Type } from 'io-ts';
import EptRawData from 'app/common/error_processing_tools/EptRawData';
import User from 'app/user/User';

export enum Method {
	GET = 'GET',
	POST = 'POST',
	PUT = 'PUT',
	PATCH = 'PATCH',
	DELETE = 'DELETE',
}

type Options = {
	'Content-Type'?: string;
	handleProgress?: () => void;
	isCORSsafe?: boolean;
	responseType?: XMLHttpRequestResponseType;
	noParse?: boolean;
	method?: Method;
	fileName?: string;
};

type Data = JSONString | FormData;

export class Request<T, A = never> {
	static parseResponse<R>(response: JSONString) {
		let result: R | null;

		try {
			result = JSON.parse(response);
		} catch (error) {
			result = null;
		}

		return result;
	}

	static blobToJSON<T extends object>(blob: Blob): Promise<T> {
		return new Promise((resolve, reject) => {
			try {
				const jsonReader = new FileReader();

				jsonReader.onload = event => {
					try {
						if (typeof event.target?.result !== 'string') {
							throw new Error('Request.ts > blobToJSON Cannot parse result as JSON');
						}

						const result = JSON.parse(event.target.result);
						resolve(result);
					} catch (error) {
						reject(error);
					}
				};
				jsonReader.onerror = reject;
				jsonReader.readAsText(blob);
			} catch (error) {
				reject(error);
			}
		});
	}

	protected isWithoutAuthCheck = false;
	protected isWithoutBaseURL = false;
	protected isEptRequest = false;
	protected runtimeType: Type<A> | undefined;
	protected store?: Store<IReduxState>;
	private xhr: XMLHttpRequest = new XMLHttpRequest();
	private objURL?: string;
	private requestInfo?: {
		method: Method;
		url: string;
		data?: Data;
		options?: Options;
	};

	get xhrInstance() {
		return this.xhr;
	}

	get info() {
		return this.requestInfo;
	}

	private request<T>(
		method: Method,
		url: string,
		data?: Data,
		options?: Options,
	): Promise<T> {
		const promise = new Promise<T>((resolve, reject) => {
			let token = getAuthToken();

			if (!token && !this.isWithoutAuthCheck) {
				new SyncRequest<{ api_token: string }>().checkAuth(
					response => {
						token = response.api_token;
					},
					error => reject(error),
				);
			}

			this.xhr.open(method.toUpperCase(), getURLForRequest(url, this.isWithoutBaseURL));

			if (!_.isUndefined(data) && typeof data === 'string' || options?.['Content-Type']) {
				this.xhr.setRequestHeader('Content-Type', options?.['Content-Type'] || 'application/json');
			}
			if (options?.handleProgress) {
				this.xhr.upload.onprogress = options.handleProgress;
			}
			if (!options?.isCORSsafe) {
				const tx = this.store ? getTx(this.store.getState()) : null;

				if (process.env.__VERSION__) {
					this.xhr.setRequestHeader('X-KINOPLAN-VERSION', process.env.__VERSION__);
				}
				if (tx) {
					this.xhr.setRequestHeader('X-KINOPLAN-TX', tx);
				}
				if (window.CSRFToken) {
					this.xhr.setRequestHeader('Csrf-Token', window.CSRFToken);
				}
				if (token) {
					this.xhr.setRequestHeader(KINOPLAN_TOKEN, token);
				}
			}
			if (options?.responseType) {
				this.xhr.responseType = options.responseType;
			}
			this.xhr.onload = () => {
				const response = options?.noParse
					? this.xhr.response
					: Request.parseResponse<T>(this.xhr.response);

				if (this.xhr.status >= 200 && this.xhr.status < 300) {
					resolve(
						this.isEptRequest
							? new EptRawData(response, this.runtimeType, `${method} ${url}`)
							: response,
					);
				} else if (this.xhr.status === 401) {
					new SyncRequest().checkAuth(
						() => {
							this.request<T>(method, url, data, options)
								.then(newResponse => resolve(newResponse))
								.catch(error => reject(error));
						},
						error => reject(error),
					);

					if (this.store) {
						const user = new User();

						user.getUser(this.store);
					}
				} else {
					reject(this.xhr);
				}
			};
			this.xhr.onerror = () => {
				reject(this.xhr);
			};
			this.xhr.onabort = () => {
				reject(this.xhr);
			};

			this.xhr.send(data);
		});

		this.requestInfo = { method, url, data, options };

		return promise;
	}

	abort() {
		this.xhr.abort();
	}

	get<D extends object>(url: string, data?: D, options?: Options) {
		return this.request<T>(Method.GET, makeGetString(url, data), undefined, options);
	}

	post<D extends object>(url: string, data?: D, options?: Options) {
		return this.request<T>(Method.POST, url, JSON.stringify(data), options);
	}

	put<D extends object>(url: string, data?: D, options?: Options) {
		return this.request<T>(Method.PUT, url, JSON.stringify(data), options);
	}

	patch<D extends object>(url: string, data?: D, options?: Options) {
		return this.request<T>(Method.PATCH, url, JSON.stringify(data), options);
	}

	delete<D extends object>(url: string, data?: D, options?: Options) {
		return this.request<T>(Method.DELETE, url, JSON.stringify(data), options);
	}

	upload<D extends object>(url: string, data?: D, options?: Options) {
		const formData = new FormData();
		_.each(_.keys(data), key => formData.append(key, data?.[key]));

		return this.request<T>(options?.method || Method.POST, url, formData, options);
	}

	createObjectURL<D extends object>(
		url: string,
		method?: Method,
		data?: D,
		options?: Options,
	): Promise<string> {
		const requestOptions: Options = {
			noParse: true,
			responseType: 'blob',
			...options,
		};

		return this[(method || Method.GET).toLowerCase()]<ArrayBuffer>(url, data, requestOptions)
			.then(response => {
				const contentType = this.xhr.getResponseHeader('Content-Type') || undefined;
				const blob = new Blob([response], { type: contentType });
				this.objURL = window.URL.createObjectURL(blob);

				return this.objURL;
			});
	}

	downloadFile<D extends object>(
		url: string,
		method?: Method,
		data?: D,
		options?: Options,
		callback?: () => void,
	) {
		return this.createObjectURL(url, method, data, options)
			.then(() => {
				if (!this.objURL) {
					throw new Error('Request.ts > downloadFile Cannot get objURL to download file');
				}

				const filename = options?.fileName
					|| decodeURI(this.xhr.getResponseHeader('X-KINOPLAN-FILENAME') || 'file_from_kinoplan');

				downloadFile(this.objURL, filename);
				callback?.();
			});
	}
}

const getDefaultOptions = () => ({
	method: 'get',
	url: '/api/test',
	data: undefined,
	beforeRequest: showLoader,
	onSuccess: hideLoader,
	onError: handleError,
	afterRequest: hideLoader,
});

interface IAPIRequestOptions<T, D> {
	method: 'get'
	| 'post'
	| 'put'
	| 'patch'
	| 'delete'
	| 'upload';
	url: string;
	data?: D;
	beforeRequest: () => Action;
	onSuccess: (response: T) => Action;
	onError: (error: string) => Action;
	afterRequest: () => Action;
}

export const apiRequest = <T, D extends object>(
	options: IAPIRequestOptions<T, D>,
	dispatch: ReduxTypes.IDispatch['dispatch'],
) => {
	const { method, url, data, beforeRequest, onSuccess, onError, afterRequest } = { ...getDefaultOptions(), ...options };

	dispatch(beforeRequest());

	return new Request<T>()[method](url, data)
		.then((response: T) => {
			if (afterRequest !== onSuccess) {
				dispatch(afterRequest());
			}
			dispatch(onSuccess(response));

			return response;
		})
		.catch((error: string) => {
			if (afterRequest !== hideLoader) {
				dispatch(afterRequest());
			}
			dispatch(onError(error));
			throw new Error(error);
		});
};

class RequestWithStore<T, A = never> extends Request<T, A> {
	constructor() {
		super();
		this.store = reqres.request<Store<IReduxState>>('get:redux:store');
	}
}

export default RequestWithStore;

export class RequestForStartApp<T> extends Request<T> {
	constructor() {
		super();
		this.isWithoutAuthCheck = true;
		this.isWithoutBaseURL = true;
	}
}

export class RequestWithoutAuthCheck<T> extends Request<T> {
	constructor() {
		super();
		this.isWithoutAuthCheck = true;
	}
}

export class RequestWithoutBaseURL<T> extends Request<T> {
	constructor() {
		super();
		this.isWithoutBaseURL = true;
	}
}
