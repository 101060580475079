export const releaseBase = {
	age: '',
	chosen_duration: '',
	cinema_id: NaN,
	cover: '',
	cover_small: '',
	date: '',
	date_preview: '',
	distributors: [],
	duration: {},
	formats: [],
	keys: [],
	last_order: null,
	plan_end: '',
	plan_start: '',
	preview_formats: [],
	rating_id: NaN,
	release_id: NaN,
	russian_release: NaN,
	title: {},
	user_note: null,
	weeks: [],
};

export const releaseWeekBase = {
	cinema_id: NaN,
	count: 0,
	date_end: '',
	date_start: '',
	formats: [],
	halls: [],
	id: '',
	is_send_distributor: false,
	proposals: null,
	release_id: NaN,
	status: '',
};
