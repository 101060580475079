import * as types from 'app/releases_list/actions/actionsTypes';
import { googleTMAction } from 'app/common/googleTM';
import { IDistributor } from 'app/distributors/types';
import { ReleasesListRelease } from 'app/release_card/releaseRuntimeType';
import { IRelease } from 'app/release_card/releaseCardTypes';

export const setReleasesRoute = (payload: string[]) => ({
	type: types.RELEASES_LIST_SET_ROUTE,
	payload,
});

export const setReleases = (payload?: ReleasesListRelease[]) => ({
	type: types.RELEASES_LIST_SET_RELEASES,
	payload,
});

export const setDistributors = (payload: IDistributor[]) => ({
	type: types.RELEASES_LIST_SET_DISTRIBUTORS,
	payload,
});

export const toggleFilter = (
	payload: 'needShowPosters' | 'withAlternativeContent' | 'isSortingByRating',
) => ({
	type: types.RELEASES_LIST_TOGGLE_FILTER,
	payload,
});

export const setRegionFilter = (payload: number | string | null) => {
	googleTMAction('release-list-region-filter');

	return {
		type: types.RELEASES_LIST_SET_REGION_FILTER,
		payload,
	};
};

export const setDistributorFilter = (payload: number | null) => {
	googleTMAction('release-list-distributor-format');

	return {
		type: types.RELEASES_LIST_SET_DISTRIBUTOR_FILTER,
		payload,
	};
};

export const setFormatFilter = (payload: string | null) => {
	googleTMAction('release-list-change-format');

	return {
		type: types.RELEASES_LIST_SET_FORMAT_FILTER,
		payload,
	};
};

export const setStartVisibleDate = (payload: string | null) => ({
	type: types.RELEASES_LIST_SET_START_VISIBLE_DATE,
	payload,
});

export const addReleaseToFavorites = (releaseID: string | number, cinemaIDs: number[]) => ({
	type: types.RELEASES_LIST_ADD_RELEASES_TO_FAVORITES,
	payload: { releaseID, cinemaIDs },
});

export const deleteReleaseFromFavorites = (releaseID: string | number, cinemaIDs: number[]) => ({
	type: types.RELEASES_LIST_DELETE_RELEASES_FROM_FAVORITES,
	payload: { releaseID, cinemaIDs },
});

export const setReleasesToFavorites = (releaseID: string | number, cinemaIDs: number[]) => ({
	type: types.RELEASES_LIST_SET_RELEASES_TO_FAVORITES,
	payload: { releaseID, cinemaIDs },
});

export const updateRelease = (payload: IRelease) => ({
	type: types.RELEASES_LIST_UPDATE_RELEASE,
	payload,
});

export const addReleases = (releases: IRelease[], releasesListReleases?: ReleasesListRelease[]) => ({
	type: types.RELEASES_LIST_ADD_RELEASES,
	payload: { releases, releasesListReleases },
});

export const setIsFetching = (payload: boolean) => ({
	type: types.RELEASES_LIST_SET_IS_FETCHING,
	payload,
});

export const setScrollTop = (payload: number | null) => ({
	type: types.RELEASES_LIST_SET_SCROLL_TOP,
	payload,
});

export const setIsReleaseDeleted = (payload: boolean) => ({
	type: types.RELEASES_LIST_SET_IS_RELEASE_DELETED,
	payload,
});

export const setTempPosterForNewRelease = (releaseID: string | number, cover: string) => ({
	type: types.RELEASES_LIST_SET_POSTER_FOR_NEW_RELEASE,
	payload: { releaseID, cover },
});
