export const UNKNOWN_DISTRIBUTOR_ID = 26;
export const COUNT_OF_INFORMATION_CELLS = 4;

export const SET_DISTRIBUTORS = 'SET_DISTRIBUTORS';
export const SET_DISTRIBUTOR = 'SET_DISTRIBUTOR';
export const DELETE_DISTRIBUTOR = 'DELETE_DISTRIBUTOR';
export const SELECT_DISTRIBUTOR_RELATION = 'SELECT_DISTRIBUTOR_RELATION';
export const USER_DISTRIBUTOR_SAVE_RELATIONS = 'USER_DISTRIBUTOR_SAVE_RELATIONS';

export const DistributorsReleaseProps = PropTypes.shape({
	id: PropTypes.number.isRequired,
	cover: PropTypes.string,
	title: PropTypes.shape({
		ru: PropTypes.string,
		en: PropTypes.string,
	}),
	date: PropTypes.shape({russia: PropTypes.shape({start: PropTypes.string})}),
});

export const DistributorsContactProps = PropTypes.shape({
	id: PropTypes.number,
	department: PropTypes.string,
	email: PropTypes.string,
	fio: PropTypes.string,
	phone: PropTypes.string,
	position: PropTypes.string,
	sort: PropTypes.number,
});

export const DistributorProps = PropTypes.shape({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(['original', 'custom']),
	name: PropTypes.shape({
		full: PropTypes.string.isRequired,
		short: PropTypes.string,
	}),
	hidden: PropTypes.oneOf([0, 1]).isRequired,
	address: PropTypes.string,
	emails: PropTypes.arrayOf(PropTypes.string),
	fax: PropTypes.string,
	count_releases: PropTypes.number,
	last_releases: PropTypes.arrayOf(DistributorsReleaseProps).isRequired,
	logo: PropTypes.string.isrequired,
	phone: PropTypes.string,
	www: PropTypes.string,
	social_networks: PropTypes.arrayOf(PropTypes.shape({
		network_key: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired,
	})),
	updated: PropTypes.number,
	contacts: PropTypes.arrayOf(DistributorsContactProps),
});

export const getDistributorDefaults = _.once(() => ({
	id: 0,
	name: {
		full: i18n.t('distributors:Unknown'),
		short: '',
	},
	hidden: 0,
	last_releases: [],
	address: '',
	emails: [],
	fax: '',
	count_releases: 0,
	logo: '/img/distr.png',
	phone: '',
	www: '',
	social_networks: [],
	updated: 0,
	contacts: [],
	user_id: '',
}));
