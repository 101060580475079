import {routes} from 'app/authentication/constants';

export const KINOPLAN_TOKEN = 'X-KINOPLAN-TOKEN';
export const KINOPLAN_USER_ID = 'X-KINOPLAN-USER-ID';
export const URL_AFTER_AUTH = 'urlAfterAuth';

export const getAuthToken = () => sessionStorage.getItem(KINOPLAN_TOKEN);
export const getAuthUserID = () => sessionStorage.getItem(KINOPLAN_USER_ID);
export const getRedirectAfterAuthUrl = () => sessionStorage.getItem(URL_AFTER_AUTH);

export const setAuthentication = (token = '', userID = '') => {
	localStorage.setItem(KINOPLAN_TOKEN, token);
	localStorage.setItem(KINOPLAN_USER_ID, userID);
	sessionStorage.setItem(KINOPLAN_TOKEN, token);
	sessionStorage.setItem(KINOPLAN_USER_ID, userID);
};

export const redirectToAuth = () => {
	setAuthentication();
	document.location.href = routes.LOGIN;
};
