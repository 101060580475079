import { createAction } from 'redux-actions';
import Request from 'app/common/Request';
import { showLoader, hideLoader, showPopup, addErrorToast, addSuccessToast } from 'app/app/AppActions';
import { hidePopup } from 'app/app/appHelpers';
import { getRepertoryCurrentSection } from 'app/repertory/selectors/repertorySelectors';
import {
	getWeekIntervals, getDaysRange, getCurrentCinemaViewMode,
} from 'app/repertory/selectors/repertoryCommonSelectors';
import {
	getWeeksByDate,
	getDeletedWeeks,
	getCustomWeekIntervals,
	createWeeks,
	warnAboutDeletedWeeks,
	getCustomPlanRange,
	getReleaseWeeks,
} from 'app/repertory/selectors/repertoryPopupSelectors';
import { changeWeeks } from 'app/repertory/selectors/RepertoryHallsReleaseSelectors';
import { changeCurrentCinema, changeDate } from 'app/user/actions/UserActions';
import { setScheduleDate } from 'app/schedule/scheduleActions';
import * as actionsTypes from 'app/repertory/RepertoryConstants';
import { getUserCurrentDate } from 'app/user/userSelectors';
import { googleTMAction } from 'app/common/googleTM';
import { ReduxTypes } from 'app/store/redux';
import { get } from 'app/mixins';
import { IRepertoryRelease, IRepertoryReleaseWeek } from 'app/repertory/types/types';
import { Moment } from 'moment';
import { massWeekUpdate } from 'app/release_card/releaseCardActions';
import { ReleaseWeek } from 'app/release_card/releaseCardTypes';
import { batch } from 'react-redux';
import { deleteReleaseFromFavorites } from 'app/releases_list/actions/actionsCreators';
import { getReferenceFormats } from 'app/Reference';

export const getRepertoryRequestForScheduleSidebar = ({
	currentCinemaID, dateStart, dateEnd }: { currentCinemaID: number; dateStart:	string; dateEnd: string },
): ReduxTypes.ThunkPromise => async dispatch => {
	try {
		const releases = await new Request().get(
			`/api/repertory/cinema/${currentCinemaID}?date_start=${dateStart}&date_end=${dateEnd}`,
		);
		dispatch(getRepertorySuccess(releases));
	} catch (error) {
		dispatch(addErrorToast({
			subtitle: i18n.t('validations.ConnectionError'),
		}));
	}
};

export const getRepertorySuccess = createAction(
	actionsTypes.GET_REPERTORY_SUCCESS,
	releases => ({ releases }),
);

export const setCurrentSection = createAction(
	actionsTypes.REPERTORY_SET_CURRENT_SECTION,
	section => section,
);

export const setNetworkReleases = createAction(
	actionsTypes.REPERTORY_NETWORK_SET_RELEASES,
	(releases, dateRange, isPartial) => ({ releases, dateRange, isPartial }),
);

export const setNetworkIsLoadingReleases = createAction(
	actionsTypes.REPERTORY_NETWORK_SET_RELEASES_IS_LOADING,
);

export const setNetworkReleasesTotal = createAction(
	actionsTypes.REPERTORY_NETWORK_SET_RELEASES_TOTAL,
	releasesTotal => ({ releasesTotal }),
);

export const addWeekBySocket = createAction(
	actionsTypes.REPERTORY_ADD_WEEKS_BY_SOCKET,
);

export const changeWeekBySocket = createAction(
	actionsTypes.REPERTORY_CHANGE_WEEKS_BY_SOCKET,
);

export const deleteWeekBySocket = createAction(
	actionsTypes.REPERTORY_DELETE_WEEKS_BY_SOCKET,
);

export const removeReleaseBySocket = createAction(
	actionsTypes.REPERTORY_DELETE_RELEASE_BY_SOCKET,
);

export const setCopyReleases = createAction(
	actionsTypes.REPERTORY_NETWORK_SET_COPY_RELEASES,
	(releases, cinemaToIDs, date) => ({ releases, cinemaToIDs, date }),
);

const loadReleasesByDateRequest = createAction(actionsTypes.GET_FOUND_RELEASES_BY_DATE_REQUEST);

const loadReleasesByDateSuccess = createAction(
	actionsTypes.GET_FOUND_RELEASES_BY_DATE_SUCCESS,
	releases => ({ releases }),
);

const loadReleasesByDateFail = createAction(
	actionsTypes.GET_FOUND_RELEASES_BY_DATE_FAIL,
	error => ({ error }),
);

export const loadReleasesByTitleRequest = createAction(
	actionsTypes.GET_FOUND_RELEASES_BY_TITLE_REQUEST,
	title => ({ title }),
);

export const loadReleasesByTitleSuccess = createAction(
	actionsTypes.GET_FOUND_RELEASES_BY_TITLE_SUCCESS,
	releases => ({ releases }),
);

export const loadReleasesByTitleFail = createAction(
	actionsTypes.GET_FOUND_RELEASES_BY_TITLE_FAIL,
	error => ({ error }),
);

export const addRelease = createAction(
	actionsTypes.REPERTORY_ADD_RELEASE,
	release => ({ release }),
);

export const addReleaseFromSearch = createAction(
	actionsTypes.REPERTORY_ADD_RELEASE_FROM_SEARCH,
	release => ({ release }),
);

export const deleteRelease = createAction(
	actionsTypes.REPERTORY_DELETE_RELEASE,
	(releaseID, cinemaID) => ({ releaseID, cinemaID }),
);

export const deleteReleaseFromSearch = createAction(
	actionsTypes.REPERTORY_DELETE_RELEASE_FROM_SEARCH,
	(releaseID, cinemaID) => ({ releaseID, cinemaID }),
);

export const loadReleasesByDate = (cinemaID: number, date): ReduxTypes.ThunkPromise => dispatch => {
	const dateStart = date.format();
	const dateEnd = date.clone().add(6, 'days').format();
	const url = `/api/repertory/cinema/${cinemaID}/releases?date_start=${dateStart}&date_end=${dateEnd}&is_like`;

	dispatch(loadReleasesByDateRequest());

	return new Request().get(url)
		.then(response => dispatch(loadReleasesByDateSuccess(response)))
		.catch(error => dispatch(loadReleasesByDateFail(error)));
};

export const loadReleasesByTitle = (cinemaID: number, title: string): ReduxTypes.ThunkPromise =>
	dispatch => {
		const url = `/api/repertory/cinema/${cinemaID}/releases?title=${title}&is_like`;

		dispatch(loadReleasesByTitleRequest(title));

		return new Request().get(url)
			.then(response => dispatch(loadReleasesByTitleSuccess(response)))
			.catch(error => dispatch(loadReleasesByTitleFail(error)));
	};

export const toggleSort = createAction(
	actionsTypes.TOGGLE_ADD_RELEASE_SORT,
	sort => ({ sort }),
);

export const disableAddReleaseSearch = createAction(actionsTypes.DISABLE_ADD_RELEASE_SEARCH);

export const setCinemas = createAction(
	actionsTypes.REPERTORY_NETWORK_SET_CINEMAS,
	cinemas => ({ cinemas }),
);

export const setRepertoryNetworkFilter = createAction(
	actionsTypes.REPERTORY_NETWORK_SET_CINEMA_FILTER,
	cinemaValueFilter => ({ cinemaValueFilter }),
);

export const setAlreadyCalledCinemas = createAction(
	actionsTypes.REPERTORY_NETWORK_SET_ALREADY_CALLED,
	alreadyCalledCinemas => ({ alreadyCalledCinemas }),
);

export const changeNetworkCinemasOrder = createAction(
	actionsTypes.REPERTORY_NETWORK_CHANGE_CINEMAS_ORDER,
	(dragCinema, hoverCinema) => ({ dragCinema, hoverCinema }),
);

export const changeReleasesHighlight = createAction(
	actionsTypes.CHANGE_RELEASES_HIGHLIGHT,
	releasesHighlight => ({ releasesHighlight }),
);

export const changeCurrentCinemaViewMode = createAction(
	actionsTypes.CHANGE_CURRENT_CINEMA_VIEW_MODE,
	mode => ({ mode }),
);

export const setLastCinemaViewMode = createAction(
	actionsTypes.SET_LAST_CINEMA_VIEW_MODE,
	mode => ({ mode }),
);

export const changeCurrentReleasesViewMode = createAction(
	actionsTypes.CHANGE_CURRENT_RELEASES_VIEW_MODE,
	mode => ({ mode }),
);

export const networkDeleteRelease = createAction(
	actionsTypes.REPERTORY_NETWORK_DELETE_RELEASE,
	(releaseID, cinemaID) => ({ releaseID, cinemaID }),
);

export const saveNewWeeks = (
	release: IRepertoryRelease, newWeeks: IRepertoryReleaseWeek[], cinemaID: number,
): ReduxTypes.ThunkPromise => async dispatch => {
	dispatch(showLoader());

	try {
		const weeks = await new Request<IRepertoryRelease['weeks']>()
			.post(`/api/repertory/weeks/${cinemaID}`, newWeeks);
		const concatWeeks = _.union(release.weeks, weeks);
		const planRange = getCustomPlanRange(concatWeeks);

		dispatch(addRelease({
			...release,
			plan_start: planRange.start.format(),
			plan_end: planRange.end.format(),
			weeks: concatWeeks,
		}));
	} catch (error) {
		dispatch(addErrorToast({ subtitle: i18n.t('validations.ConnectionError') }));
	} finally {
		dispatch(hideLoader());
	}
};

export const removeRelease = (releaseID: string, cinemaID: number): ReduxTypes.ThunkPromise =>
	(dispatch, getState) => {
		const isNetwork = getRepertoryCurrentSection(getState()) === 'network';
		dispatch(showLoader());

		return new Request().delete(
			`/api/repertory/release/${releaseID}`,
			[cinemaID],
		).then(() => {
			batch(() => {
				if (isNetwork) {
					dispatch(networkDeleteRelease(releaseID, cinemaID));
					dispatch(deleteReleaseFromSearch(releaseID, cinemaID));
					dispatch(deleteReleaseFromFavorites(releaseID, [cinemaID]));
				} else {
					dispatch(deleteRelease(releaseID, cinemaID));
					dispatch(deleteReleaseFromSearch(releaseID, cinemaID));
					dispatch(deleteReleaseFromFavorites(releaseID, [cinemaID]));
				}
			});
		}).catch(() => {
			dispatch(addErrorToast({ subtitle: i18n.t('validations.ConnectionError') }));
		}).finally(() => {
			dispatch(hideLoader());
		});
	};

export const setNote = createAction(
	actionsTypes.SET_NOTE,
	({ id, releaseID, cinemaID, text }) => ({ id, releaseID, cinemaID, text }),
);

export const removeNote = createAction(
	actionsTypes.REMOVE_NOTE,
	(releaseID, cinemaID) => ({ releaseID, cinemaID }),
);

export const getRepertoryNetworkRequest = (
	cinemaIDs = [], cinemaTitle = '', isPartial = false,
): ReduxTypes.ThunkPromise =>
	async (dispatch, getState) => {
		try {
			batch(() => {
				dispatch(showLoader());
				dispatch(getRepertorySuccess([]));
				dispatch(setNetworkIsLoadingReleases(true));
			});

			const weekIntervals = getWeekIntervals(getState());
			const dateStart = (_.first(_.first(weekIntervals) || []))?.format();
			const dateEnd = (_.last(_.last(weekIntervals) || []))?.format();
			const releases = await new Request<{ releases: IRepertoryRelease }>().get(
				'/api/repertory/cinema', {
					date_start: dateStart,
					date_end: dateEnd,
					cinema_ids: cinemaIDs,
					...(cinemaTitle.length >= 2 ? { cinema_title: cinemaTitle } : {}),
				},
			);

			dispatch(setNetworkReleases(releases, weekIntervals, isPartial));
		} catch (error) {
			dispatch(addErrorToast({ subtitle: i18n.t('validations.ConnectionError') }));
		} finally {
			batch(() => {
				dispatch(hideLoader());
				dispatch(setNetworkIsLoadingReleases(false));
			});
		}
	};

export const getRepertoryNetworkStatistic = (): ReduxTypes.ThunkPromise =>
	async (dispatch, getState) => {
		try {
			const weekIntervals = getWeekIntervals(getState());
			const dateStart = (_.first(_.first(weekIntervals) || []))?.format();
			const dateEnd = (_.last(_.last(weekIntervals) || []))?.format();

			dispatch(showLoader());

			const releases = await new Request().get('/api/repertory/statistic', {
				date_start: dateStart,
				date_end: dateEnd,
			});

			dispatch(setNetworkReleasesTotal(releases));
		} catch (error) {
			dispatch(addErrorToast({ subtitle: i18n.t('validations.ConnectionError') }));
		} finally {
			dispatch(hideLoader());
		}
	};

export const getRepertoryRequest = (cinemaID: number): ReduxTypes.ThunkPromise =>
	async (dispatch, getState) => {
		try {
			batch(() => {
				dispatch(showLoader());
				dispatch(setNetworkReleases());
			});
			const state = getState();
			const mode = getCurrentCinemaViewMode(state);
			const date = getUserCurrentDate(state);
			const daysRange = getDaysRange(date, mode);
			const dateStart = date.clone().date(_.first(daysRange) || 0);
			const dateEnd = date.clone().date(_.last(daysRange) || 0);

			const releases = await new Request().get(`/api/repertory/cinema/${cinemaID}`, {
				date_start: dateStart.format(),
				date_end: dateEnd.format(),
			});

			dispatch(getRepertorySuccess(releases));
		} catch (error) {
			dispatch(addErrorToast({ subtitle: i18n.t('validations.ConnectionError') }));
		} finally {
			dispatch(hideLoader());
		}
	};

export const copyRepertoryRequest = (cinemaFromID: number, cinemasTo: number[], date: Moment[] = []):
ReduxTypes.ThunkPromise => async dispatch => {
	const dateStart = _.first(date)?.format();
	const dateEnd = _.last(date)?.format();

	googleTMAction('repertory_by-network-copy_repertory');

	dispatch(showLoader());

	try {
		const cinemaToIDs = _.pluck(cinemasTo, 'id');
		const releases = await new Request().post('/api/repertory/copy', {
			cinema_from: cinemaFromID,
			cinemas_to: cinemaToIDs,
			date_start: dateStart,
			date_end: dateEnd,
		});

		const COPYING_TOAST_MAX_CINEMAS = 5;
		const toastCinemaTitles = _.pluck(_.first(cinemasTo, COPYING_TOAST_MAX_CINEMAS), 'title');
		const severalMoreNumber = cinemasTo.length - COPYING_TOAST_MAX_CINEMAS;
		const successSubtitle = (
			<React.Fragment>
				{i18n.t('Copying.RepertoryWasCopiedTo')}
				<ul style={{ margin: 0, paddingLeft: '20px' }}>{toastCinemaTitles.map(title => <li key={title}>{title}</li>)}</ul>
				{severalMoreNumber > 0 &&
					`${i18n.t('preposition.andMore')} ${i18n.t('declension.cinema', { count: severalMoreNumber })}`
				}
			</React.Fragment>
		);

		batch(() => {
			dispatch(setCopyReleases(releases, cinemaToIDs, dateStart));
			dispatch(addSuccessToast({ subtitle: successSubtitle }));
		});
	} catch (error) {
		dispatch(addErrorToast({ subtitle: i18n.t('repertory:RepertoryCopyError') }));

		throw new Error(error);
	} finally {
		dispatch(hideLoader());
	}
};

const updateReleaseAndWeeks = createAction(
	actionsTypes.REPERTORY_UPDATE_RELEASE_AND_WEEKS,
	({ releaseID, planRange, cinemaID, newWeeks }) => ({ releaseID, planRange, cinemaID, newWeeks }),
);

export const networkUpdateReleaseAndWeeks = createAction(
	actionsTypes.REPERTORY_NETWORK_UPDATE_RELEASE_AND_WEEKS,
	({ releaseID, planRange, cinemaID, newWeeks }) => ({ releaseID, planRange, cinemaID, newWeeks }),
);

export const networkAddRelease = createAction(
	actionsTypes.REPERTORY_NETWORK_ADD_RELEASE,
	release => ({ release }),
);

export const addNewRelease = (release: IRepertoryRelease): ReduxTypes.ThunkPromise =>
	(dispatch, getState) => {
		const isNetwork = getRepertoryCurrentSection(getState()) === 'network';

		dispatch(showLoader());

		return new Request<IRepertoryRelease['weeks']>().post(`/api/repertory/weeks/${release.cinema_id}`, release.weeks)
			.then(weeks => {
				const planRange = getCustomPlanRange(weeks);
				const data = {
					...release,
					plan_start: planRange.start.format(),
					plan_end: planRange.end.format(),
					weeks,
				};

				dispatch(isNetwork ? networkAddRelease(data) : addRelease(data));
			})
			.catch(error => {
				dispatch(addErrorToast({ subtitle: i18n.t('validations.ConnectionError') }));
				throw new Error(error);
			})
			.finally(() => dispatch(hideLoader()));
	};

export const deleteWeeks = (weeks: IRepertoryReleaseWeek[]): ReduxTypes.ThunkResult<Promise<void> | undefined> =>
	(dispatch, getState) => {
		const state = getState();
		if (!warnAboutDeletedWeeks(getReferenceFormats(state), weeks)) return;

		const weeksIDs = _.pluck(weeks, 'id');
		const cinemaID = get(_.first(weeks), 'cinema_id');
		const releaseID = get(_.first(weeks), 'release_id');

		dispatch(showLoader());

		return new Request<Array<IRepertoryReleaseWeek['id']>>().delete(`/api/repertory/weeks/${cinemaID}`, weeksIDs)
			.then(() => {
				dispatch(hideLoader());
				const newWeeks = getReleaseWeeks(state).filter(
					(week: { id: string }) => !_.contains(weeksIDs, week.id),
				);
				const planRange = getCustomPlanRange(newWeeks);

				batch(() => {
					dispatch(updateReleaseAndWeeks({
						releaseID,
						planRange,
						newWeeks,
						cinemaID,
					}));
					dispatch(networkUpdateReleaseAndWeeks({
						releaseID,
						planRange,
						newWeeks,
						cinemaID,
					}));
				});
			}).catch(() => {
				dispatch(addErrorToast({ subtitle: i18n.t('validations.ConnectionError') }));
			}).finally(() => {
				dispatch(hideLoader());
			});
	};

export const saveChangedWeeks = (changedWeeks: IRepertoryRelease['weeks']): ReduxTypes.ThunkPromise =>
	(dispatch, getState) => {
		const state = getState();
		const cinemaID = (_.first(changedWeeks) || {}).cinema_id;

		dispatch(showLoader());

		return new Request().put(`/api/repertory/weeks/${cinemaID}`, changedWeeks)
			.then(() => {
				const newWeeks = _.map(getReleaseWeeks(state), week => (
					_.findWhere(changedWeeks, { id: week.id }) || week
				));
				const releaseID = (_.first(newWeeks) || { release_id: 0 }).release_id;
				const planRange = getCustomPlanRange(newWeeks);

				batch(() => {
					dispatch(updateReleaseAndWeeks({
						planRange,
						releaseID,
						cinemaID,
						newWeeks,
					}));
					dispatch(networkUpdateReleaseAndWeeks({
						releaseID,
						planRange,
						newWeeks,
						cinemaID,
					}));
				});
			}).catch(error => {
				dispatch(addErrorToast({ subtitle: i18n.t('validations.ConnectionError') }));
				throw new Error(error);
			}).finally(() => {
				dispatch(hideLoader());
			});
	};

export const addNewWeeks = (newWeeks: Partial<IRepertoryRelease['weeks']>): ReduxTypes.ThunkPromise =>
	(dispatch, getState) => {
		const state = getState();
		const cinemaID = (_.first(newWeeks) || {}).cinema_id;
		dispatch(showLoader());

		return new Request<IRepertoryRelease['weeks']>().post(`/api/repertory/weeks/${cinemaID}`, newWeeks)
			.then(weeks => {
				const concatWeeks = getReleaseWeeks(state).concat(weeks);
				const planRange = getCustomPlanRange(concatWeeks);
				const releaseID = (_.first(concatWeeks) as { release_id: number } || {}).release_id;

				batch(() => {
					dispatch(updateReleaseAndWeeks({
						planRange,
						releaseID,
						cinemaID,
						newWeeks: concatWeeks,
					}));
					dispatch(networkUpdateReleaseAndWeeks({
						releaseID,
						planRange,
						newWeeks: concatWeeks,
						cinemaID,
					}));
				});
			}).catch(error => {
				dispatch(addErrorToast({ subtitle: i18n.t('validations.ConnectionError') }));
				throw new Error(error);
			}).finally(() => {
				dispatch(hideLoader());
			});
	};

export const changePlanRange = (
	{ rangeStart, rangeEnd, release }: { rangeStart: Moment; rangeEnd: Moment; release: IRepertoryRelease },
): ReduxTypes.ThunkPromise => (dispatch, getState) => {
	const state = getState();
	const weekIntervals = getCustomWeekIntervals(rangeStart, rangeEnd);
	const deletedWeeks = getDeletedWeeks(state, { start: rangeStart, end: rangeEnd });
	const changedWeeks: IRepertoryReleaseWeek[][] = [];
	const newWeeks: IRepertoryReleaseWeek[][] = [];
	const requests: Array<ReduxTypes.ThunkResult<Promise<void> | undefined>> = [];

	_.each(weekIntervals, days => {
		const dateStart: string = _.first(days)!.format();
		const dateEnd: string = _.last(days)!.format();
		const weeks = getWeeksByDate(state, _.first(days) || moment());

		const firstWeek = _.first(weeks) || { date_start: null, date_end: null };

		if (weeks.length && (firstWeek.date_start !== dateStart || firstWeek.date_end !== dateEnd)) {
			changedWeeks.push(changeWeeks(weeks, {
				date_start: dateStart,
				date_end: dateEnd,
			}));
		}

		if (!weeks.length) {
			newWeeks.push(createWeeks(state, {
				date_start: dateStart,
				date_end: dateEnd,
				releaseID: release.release_id,
				cinemaID: release.cinema_id,
			}));
		}
	});

	if (changedWeeks.length) {
		requests.push(saveChangedWeeks(_.flatten(changedWeeks)));
	}
	if (deletedWeeks.length) {
		requests.push(deleteWeeks(deletedWeeks));
	}
	if (newWeeks.length) {
		requests.push(addNewWeeks(_.flatten(newWeeks)));
	}

	return _.compact(requests)
		.reduce((prevRequest, currentRequest) => prevRequest.then(() => dispatch(currentRequest)), Promise.resolve());
};

export const updateLastOrder = createAction(
	actionsTypes.REPERTORY_UPDATE_RELEASE_LAST_ORDER,
	(releaseID, cinemaID, lastOrder) => ({ releaseID, cinemaID, lastOrder }),
);

export const updateReleaseWeeksStatus = createAction(
	actionsTypes.REPERTORY_UPDATE_RELEASE_WEEKS_STATUS,
	(releaseID, cinemaID, status) => ({ releaseID, cinemaID, status }),
);

export const changeWeeksStatus = (
	{ releaseID, cinemaID, status }: { releaseID: number; cinemaID: number; status: string },
): ReduxTypes.ThunkResult<void> => dispatch => {
	if (status === actionsTypes.seancesStatuses.PROCESS) {
		const lastOrder = {
			cinema_id: cinemaID,
			updated: moment().unix(),
		};

		batch(() => {
			dispatch(updateLastOrder(releaseID, cinemaID, lastOrder));
			dispatch(updateReleaseWeeksStatus(releaseID, cinemaID, status));
		});
	}

	dispatch(updateReleaseWeeksStatus(releaseID, cinemaID, status));
};

export const massUpdateOfWeeksRequest = (releaseID: number, params: Array<{
	cinema_ids: number[];
	date_start: string;
	date_end: string;
	status: string;
	counts: Array<{
		format: number[];
		count: number;
	}>;
}>): ReduxTypes.ThunkResult<void> => async dispatch => {
	dispatch(showLoader());

	try {
		const weeks = await new Request<ReleaseWeek[]>().put(`/api/repertory/weeks?release_id=${releaseID}`, params);

		batch(() => {
			dispatch(massWeekUpdate(weeks));
			dispatch(hidePopup());
		});
	} catch (error) {
		dispatch(addErrorToast({
			subtitle: i18n.t(`${error.status === 400
				? 'repertory:CannotPaintRelease'
				: 'validations.ConnectionError'
			}`),
		}));
	} finally {
		dispatch(hideLoader());
	}
};

export const massChangeReleaseStatus = (
	releaseID, params: { weeks_ids: string[]; status: ReleaseWeek['status'] },
) => async dispatch => {
	dispatch(showLoader());

	try {
		const weeks = await new Request<ReleaseWeek[]>().put(`/api/repertory/weeks/status?release_id=${releaseID}`, params);

		dispatch(massWeekUpdate(weeks));
	} catch (error) {
		addErrorToast({
			subtitle: i18n.t('validations.ConnectionError'),
		});
	} finally {
		dispatch(hideLoader());
	}
};

export const onGoToSchedule = (date, cinema) => dispatch => {
	batch(() => {
		dispatch(changeCurrentCinema(cinema));
		dispatch(setScheduleDate(date));
		dispatch(changeDate(date));
		dispatch(showPopup(null));
	});
};

export const setCurrentRelease = createAction(
	actionsTypes.REPERTORY_SET_CURRENT_RELEASE,
	currentRelease => ({ currentRelease }),
);

export const networkAddWeeksBySocket = createAction(
	actionsTypes.REPERTORY_NETWORK_ADD_WEEKS_BY_SOCKET,
);

export const networkChangeWeeksBySocket = createAction(
	actionsTypes.REPERTORY_NETWORK_CHANGE_WEEKS_BY_SOCKET,
);

export const networkDeleteWeeksBySocket = createAction(
	actionsTypes.REPERTORY_NETWORK_DELETE_WEEKS_BY_SOCKET,
);

export const setReleaseListSearchValue = createAction(
	actionsTypes.SET_RELEASE_LIST_SEARCH_VALUE,
	searchValue => ({ searchValue }),
);

export const setReleaseListSearchLocation = createAction(
	actionsTypes.SET_RELEASE_LIST_SEARCH_LOCATION,
	location => ({ location }),
);

export const repertoryUpdateColorRelease = createAction(
	actionsTypes.REPERTORY_UPDATE_RELEASE_COLOR,
	(release, color) => ({ release, color }),
);
