import { getLocalize, getSearchRegExp } from 'app/mixins';
import { IReferenceFormat } from 'app/ReferenceTypes';
import { IReleaseKeys, ReleaseWeek } from 'app/release_card/releaseCardTypes';
import { releaseBase, releaseWeekBase } from 'app/repertory/constants/repertoryConstants';
import {
	getErrorInfo,
	getKeysInfo,
	getPreviewErrorInfo,
	getPreviewInfo,
	getReleaseInfo,
} from 'app/repertory/selectors/RepertoryHallsReleaseSelectors';
import { hasReleasePreviewError } from 'app/repertory/selectors/repertoryNetworkSelectors';
import { IRepertoryCinema, IRepertoryRelease, IRepertoryReleaseWeek } from 'app/repertory/types/types';
import { ICinema, ICinemaRight } from 'app/user/UserTypes';
import { Moment } from 'moment';

export const isRepertoryEditable = (cinemaRights: ICinemaRight[], cinemaID: number, canEditRepertory: boolean) => {
	const cinemaRight = cinemaRights.find(right => right.cinema_id === cinemaID);

	if (!cinemaRight) return false;

	return !(cinemaRight.level === 'read' || cinemaRight.level === 'rw' || cinemaRight.level === 'mediaplan')
		&& canEditRepertory;
};

export const calculateFormatTitleByIds = (referenceFormats: IReferenceFormat[]) => (formats: number[]) => formats.reduce(
	(formatKey, formatID, index) => {
		const formatTitle = getLocalize((referenceFormats.find(refFormat => refFormat.id === formatID) || {}).title_short);

		return !index ? `${formatTitle}` : `${formatKey}_${formatTitle}`;
	}, '',
);

export const getComputedDateByRange = (start: string, end: string | null, hasYear = false) => {
	const dateStart = moment(start);

	if (end) {
		const dateEnd = moment(end);

		if (dateStart.isSame(dateEnd, 'month')) {
			return hasYear
				? dateStart.format('MMMM YYYY')
				: `${dateStart.format('D')}–${dateEnd.format('D MMM')}`;
		}

		return hasYear
			? `${dateStart.format('MMMM')} - ${dateEnd.format('MMMM YYYY')}`
			: `${dateStart.format('D MMM')} - ${dateEnd.format('D MMM')}`;
	}

	return dateStart.format(hasYear ? 'D MMMM YYYY' : 'D MMM');
};

export const optionsComparator = (searchValue: string, option: IRepertoryCinema) => !searchValue ||
	getSearchRegExp(searchValue).test(option.textValue ? String(option.textValue) : String(option.title));


export const makeRelease = (release: IRepertoryRelease) => ({
	..._.defaults(release, releaseBase),
	weeks: release.weeks.map(week => _.defaults(week, releaseWeekBase)),
	keys: release.keys,
});

export const getSumCount = (weeks: IRepertoryReleaseWeek[]) => _.chain(weeks)
	.pluck('count')
	.reduce((memo, num) => (memo + num), 0)
	.value();

export const getStatus = (weeks: IRepertoryReleaseWeek[]) => weeks && weeks.length ? _.first(weeks)?.status : 'planned';

export const getSimplePlanRange = (weeks: IRepertoryReleaseWeek[]) => {
	return weeks.length ? {
		start: moment.min(_.map(weeks, week => moment(week.date_start))),
		end: moment.max(_.map(weeks, week => moment(week.date_end))),
		count: getSumCount(weeks),
		status: getStatus(weeks),
	} : {};
};

export const getPlanRange = (weeks: IRepertoryReleaseWeek[], allReleasesWeeks: IRepertoryReleaseWeek[]) => {
	if (!weeks.length) return {};

	const start = moment.min(_.map(weeks, week => moment(week.date_start)));
	const end = moment.max(_.map(weeks, week => moment(week.date_end)));
	const sumCount = getSumCount(weeks);
	const formatedStart = moment(start).startOf('week').format();
	const formatedEnd = moment(end).endOf('week').format();

	const totalCount = _.chain(allReleasesWeeks)
		.filter(week => (week.date_start >= formatedStart && week.date_end <= formatedEnd))
		.pluck('count')
		.reduce((acc, count) => acc + count, 0)
		.value() || 0;

	return {
		start,
		end,
		count: sumCount,
		percent: totalCount ? Math.round(sumCount / totalCount * 100) : 0,
		status: getStatus(weeks),
	};
};

export const getHasDateErrorBefore = (release: IRepertoryRelease) => {
	return moment(release.plan_start).isBefore(moment(release.date_preview || release.date));
};

export const getHasDateErrorAfter = (release: IRepertoryRelease) => (
	moment(release.plan_start).isAfter(moment(release.date))
);

export const getReleaseChrono = (release: IRepertoryRelease, isShowChrono: boolean) => {
	const duration = release.duration[release.chosen_duration] || release.duration.clean;

	return isShowChrono && !!duration && <span> {duration} {i18n.t('shortTime.minutes')}</span>;
};

export const getKeysPeriod = (keys: IReleaseKeys[] = []) => {
	if (!keys.length) return {};

	return {
		start: moment.min(_.map(keys, key => moment(key.valid_since))),
		end: moment.max(_.map(keys, key => moment(key.valid_till))),
	};
};

export const getTooltipText = (
	release: IRepertoryRelease,
	cinema: ICinema,
	refFormats: IReferenceFormat[],
	isShowChrono: boolean,
) => {
	return (
		<div>
			{release.age ? <span>{release.age} </span> : ''}
			{getReleaseInfo(release)}
			{getReleaseChrono(release, isShowChrono)}
			{release.date_preview ? getPreviewInfo(release, refFormats) : ''}
			{(!_.isEmpty(getKeysPeriod(release.keys))) ? getKeysInfo(release) : ''}
			{getHasDateErrorBefore(release) || getHasDateErrorAfter(release) ? getErrorInfo(release) : ''}
			{hasReleasePreviewError(release, cinema.formats_id) ? getPreviewErrorInfo() : ''}
		</div>
	);
};

export const getSeancesCountByDay = (releases, days) => getSumCount(
	releases.flatMap(release => getWeeksByDate(release.weeks, days[0])),
);

export const getWeeksByDate = (weeks: ReleaseWeek[], date: string | Moment) => {
	const startDate = moment(date).weekday(0);
	const endDate = moment(date).weekday(6);

	return _.filter(weeks, ({ date_start }) => (
		moment(date_start).isBetween(startDate, endDate, 'day', '[]')
	));
};
