export const parseUser = user => ({
	...user.pick([
		'admin',
		'access_content',
		'access_dd4_serv',
		'access_advertising',
		'access_ad_network',
		'access_tms',
		'access_kinosite',
		'access_videoposter',
		'access_cv',
		'access_releases',
		'access_ticket_system',
		'access_price_manual',
		'access_bms',
		'access_crm',
		'approved',
		'banner',
		'cinema',
		'cinema_rights',
		'cinema_relations',
		'cinema_tms',
		'cinemasData',
		'country_for',
		'distributor_id',
		'email',
		'first_name',
		'last_name',
		'last_read_chat',
		'name',
		'moderator',
		'notes',
		'phone',
		'photo_medium',
		'photo_big',
		'service',
		'type',
		'user_id',
		'user_position',
		'user_position_key',
		'birth_date',
		'mobile_settings',
		'tms_settings',
		'email_settings',
		'user_ban',
		'ticket_system_token',
		'our_employee',
		'system_employee',
		'feature_awareness',
		'vip',
		'releases_sub_user',
	]),
	currentDate: moment(),
	currentCinema: user.getCinema().id,
	currentSidebar: 'kinoplan',
	allowedPages: user.getPages(),
	features: user.getFeatures(),
});
