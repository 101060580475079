export const CHANGE_CHOSEN_DAY = 'CHANGE_CHOSEN_DAY';
export const SET_RELEASES_COUNT = 'SET_RELEASES_COUNT';
export const SCHEDULE_SHOW = 'SCHEDULE_SHOW';

export const SCHEDULE_WINDOW_RESIZE = 'SCHEDULE_WINDOW_RESIZE';
export const SCHEDULE_SET_EDIT_ONLY_CURRENT_DAY = 'SCHEDULE_SET_EDIT_ONLY_CURRENT_DAY';
// actions types
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS';
export const SET_SCHEDULE_DATE = 'SET_SCHEDULE_DATE';
export const SET_SCHEDULE_WEEKDAY = 'SET_SCHEDULE_WEEKDAY';
export const SET_SCHEDULE_POPUP_DATE = 'SET_SCHEDULE_POPUP_DATE';
export const TOGGLE_SCHEDULE_SHOW_UNPLANNED_RELEASES = 'TOGGLE_SCHEDULE_SHOW_UNPLANNED_RELEASES';
export const TOGGLE_SORT_BY_START_WEEK = 'TOGGLE_SORT_BY_START_WEEK';
export const SET_SCHEDULE_SEANCES_TIME_START = 'SET_SCHEDULE_SEANCES_TIME_START';
export const SET_SCHEDULE_GENERATION_SETTINGS = 'SET_SCHEDULE_GENERATION_SETTINGS';
export const UPDATE_SCHEDULE_SEANCES_DAYS = 'UPDATE_SCHEDULE_SEANCES_DAYS';
export const UPDATE_SCHEDULE_SEANCE_BY_SOCKET = 'UPDATE_SCHEDULE_SEANCE_BY_SOCKET';
export const SET_SCHEDULE_DRAG_SEANCES = 'SET_SCHEDULE_DRAG_SEANCES';
export const SET_HALL_CONTENT_OFFSET = 'SET_HALL_CONTENT_OFFSET';
export const RESET_HALL_CONTENT_OFFSET = 'RESET_HALL_CONTENT_OFFSET';
export const SET_CHOSEN_HALLS = 'SET_CHOSEN_HALLS';
export const SET_CHOSEN_CINEMAS = 'SET_CHOSEN_CINEMAS';
export const SET_CHOSEN_DATES = 'SET_CHOSEN_DATES';
export const RESET_CHOSEN_HALLS = 'RESET_CHOSEN_HALLS';
export const RESET_CHOSEN_CINEMAS = 'RESET_CHOSEN_CINEMAS';
export const RESET_CHOSEN_DATES = 'RESET_CHOSEN_DATES';
export const SELECT_SCHEDULE_TIMELINE_SEANCE = 'SELECT_SCHEDULE_TIMELINE_SEANCE';
export const SET_SCHEDULE_SEANCES = 'SET_SCHEDULE_SEANCES';
export const RESET_SCHEDULE = 'RESET_SCHEDULE';
export const UPDATE_SOLD_TICKETS = 'UPDATE_SOLD_TICKETS';
export const SCHEDULE_UPDATE_SEANCE_OBJECT = 'SCHEDULE_UPDATE_SEANCE_OBJECT';
export const SCHEDULE_SEANCES_UNDO = 'SCHEDULE_SEANCES_UNDO';
export const SCHEDULE_SEANCES_REDO = 'SCHEDULE_SEANCES_REDO';
export const SCHEDULE_DELETE_ALL_SEANCES = 'SCHEDULE_DELETE_ALL_SEANCES';
export const LOCK_OR_UNLOCK_ALL_SEANCES = 'LOCK_OR_UNLOCK_ALL_SEANCES';
export const TOGGLE_PERIODS_MARK = 'TOGGLE_PERIODS_MARK';
export const EDIT_MARKS_SUCCESS = 'EDIT_MARKS_SUCCESS';
export const SET_SEANCES_DRAG_COUNT = 'SET_SEANCES_DRAG_COUNT';
export const TOGGLE_COLOR_RELEASES = 'TOGGLE_COLOR_RELEASES';
export const UPDATE_TIMELINE_RELEASE = 'UPDATE_TIMELINE_RELEASE';
export const TOGGLE_TICKET_MODE = 'TOGGLE_TICKET_MODE';
export const TOGGLE_TIME_MODE = 'TOGGLE_TIME_MODE';
export const SET_HISTORY_TEMPORARY_DISABLED = 'SET_HISTORY_TEMPORARY_DISABLED';
export const UPDATE_SCHEDULE_SEANCE_STATUS_BY_SOCKET = 'UPDATE_SCHEDULE_SEANCE_STATUS_BY_SOCKET';

// draggable items types
export const TIMELINE_SEANCE_DRAG = 'TIMELINE_SEANCE_DRAG';
export const SEANCE_DRAG = 'seance';
export const EDIT_DAY_POPUP_SEANCE_DRAG = 'EDIT_DAY_POPUP_SEANCE_DRAG';
export const RELEASE_DRAG = 'release';
export const DAY_SEANCES_DRAG = 'daySeances';

export const OPEN_SALES_CODE = 15906;
export const APPROVED_SEANCES_CODE = 15907;
export const LOCKED_SEANCES_CODE = 15908;
export const NO_SUBSCRIPTION_CODE = 15909;
export const SUCCESS_CODE = 200;
export const SOLD_TICKETS_ON_PREMIERE_CODE = 15910;
export const UNDO_BTNS_DISABLE_DELAY = 2000;
export const NO_LICENSE_CODE = 15909;
export const WRONG_COPY_LICENSE_CODE = 15911;
export const WRONG_END_OF_DAY_CODE = 15901;
export const CANT_DELETE_UNAPPROVED_SEANCES = 15912;
export const SEANCE_INTERSECT_WITH_OTHER = 15910;

export const emptyRelease = {
	age: null,
	release_id: 0,
	chosen_duration: 'custom',
	duration: {custom: 90},
	formats: [1],
	week: {count: 0, formats: [1], id: 0, hall: {}, status: 'default'},
	title: {ru: 'Пустой сеанс', en: 'Empty Seance'},
};

export const GenerationSettingsProps = PropTypes.shape({
	id: PropTypes.string,
	repertory_top: PropTypes.number.isRequired,
	alternate_releases: PropTypes.number,
	long_duration: PropTypes.number,
	main_hall: PropTypes.shape({
		id: PropTypes.number,
		time_start: PropTypes.string,
	}),
	min_interval: PropTypes.number,
	consider_age: PropTypes.arrayOf(PropTypes.shape({
		age: PropTypes.string.isRequired,
		time: PropTypes.string.isRequired,
	})).isRequired,
	correct_coincidence: PropTypes.number,
});

export const seancePropTypes = PropTypes.shape({
	id: PropTypes.string,
	title: PropTypes.string,
	release_id: PropTypes.number,
	hall_id: PropTypes.number,
	time_start: PropTypes.string.isRequired, // HH:mm
	date_start: PropTypes.string.isRequired, // YYYY-MM-DD
	date: PropTypes.number.isRequired, // unix-time
	duration: {
		advertising: PropTypes.number.isRequired,
		release: PropTypes.number.isRequired,
		pause: PropTypes.number.isRequired,
	}.isRequired,
	formats: PropTypes.arrayOf(PropTypes.number).isRequired,
	advertising: PropTypes.arrayOf(PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	])),
	is_advertising_actual: PropTypes.bool,
	is_locked: PropTypes.bool,
	is_approved: PropTypes.bool,
	can_move: PropTypes.bool.isRequired,
	can_delete: PropTypes.bool.isRequired,
	comment: PropTypes.string,
	is_advertising_calculation: PropTypes.bool,
});

export const advertisingPropTypes = PropTypes.shape({
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	title: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
});

export const releasePropTypes = PropTypes.shape({
	release_id: PropTypes.number.isRequired,
	title: PropTypes.shape({
		ru: PropTypes.string.isRequired,
		en: PropTypes.string,
	}).isRequired,
});

export const dayPropTypes = PropTypes.shape({
	date_start: PropTypes.string.isRequired,
	similarDaysLength: PropTypes.number.isRequired,
	isSameAsPrev: PropTypes.bool.isRequired,
});

export const highlightClass = 'schedule-timeline-hall-content-item--blue';

export const markProps = PropTypes.shape({
	duration: PropTypes.number.isRequired,
	price: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	to: PropTypes.string.isRequired,
});

export const marksPeriodProps = PropTypes.shape({
	morning: markProps,
	day: markProps,
	evening: markProps,
	night: markProps,
});

export const releaseColors = [
	'#D62518', '#FFB97B', '#AFE313', '#93DFB8', '#8FD8D8', '#7A89B8', '#8359A3', '#614051', '#FF91A4', '#926F5B', '#E6BE8A',
	'#AD0000', '#E77200', '#C5E17A', '#33CC99', '#004C71', '#4F69C6', '#C154C1', '#E91E63', '#CA3435', '#DE6900', '#C9C0BB',
	'#C32148', '#FFAE42', '#7BA05B', '#1AB385', '#0095B7', '#9999CC', '#E29CD2', '#FF3399', '#E97451', '#FFCBA4', '#C88A65',
	'#FE6F5E', '#FED85D', '#9DE093', '#00755E', '#02A4D3', '#6456B7', '#8E3179', '#E30B5C', '#AF593E', '#EED9C4', '#8B8680',
	'#FF9980', '#F1E788', '#63B76C', '#008080', '#1560BD', '#652DC1', '#BB3385', '#DB5079', '#87421F', '#837050', '#232323',
	'#FF7034', '#B5B35C', '#5FA777', '#00CCCC', '#C3CDE6', '#333399', '#A50B5E', '#F898C8',
];
