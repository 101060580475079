export function googleTMAction(event, options = {}) {
	const dataLayer = _.get(window, 'dataLayer', []);

	if (process.env.NODE_ENV !== 'production') {
		// eslint-disable-next-line no-console
		console.log('%cGTM send event: %o', 'color: #0074B6; font-weight: 600', {event, ...options});
	}

	dataLayer.push({
		event,
		...options,
	});
}
