import Request from 'app/common/Request';

export const createRequest = () => {
	const request = (...args) => new Proxy(new Request(), {
		async get(target, prop) {
			try {
				const promise = target[prop](...args);

				return Promise.resolve(await promise);
			} catch (error) {
				return Promise.reject(error);
			}
		},
	});

	return request;
};

export default createRequest;
