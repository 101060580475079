import { addErrorToast, showPopup } from 'app/app/AppActions';
import { setScheduleDate } from 'app/schedule/scheduleActions';
import { changeDate } from 'app/tms_playlists/actions/PlaylistsActions';
import { changeDate as changeDateLegacy } from 'app/tms_playlists_legacy/actions/PlaylistsActions';
import AsyncComponent from 'app/common/AsyncComponent';
import { InfoPopup } from 'tochkak-ui';
import { InfoPopupProps, IToast } from 'app/app/types';
import { ReduxTypes } from 'app/store/redux';
import { batch } from 'react-redux';

export const addServerErrorToast = (properties: IToast = {}) => (dispatch: ReduxTypes.Dispatch) => {
	dispatch(addErrorToast({
		subtitle: i18n.t('notifications.ConnectionError'),
		...properties,
	}));
};

export const setScheduleAndTmsPlaylistDates = date => (dispatch: ReduxTypes.Dispatch) => {
	batch(() => [
		dispatch(setScheduleDate(date)),
		dispatch(changeDate(date)),
	]);
};

export const setScheduleAndTmsPlaylistDatesLegacy = date => (dispatch: ReduxTypes.Dispatch) => {
	batch(() => [
		dispatch(setScheduleDate(date)),
		dispatch(changeDateLegacy(date)),
	]);
};

export const hidePopup = (onClose?: () => void) => dispatch => {
	document.querySelector('.popup-overlay')?.classList.remove('popup-overlay--visible');

	_.delay(() => {
		dispatch(showPopup(null));

		if (_.isFunction(onClose)) onClose();
	}, 500);
};

export const copyToClipboard = text => {
	const inputElement = document.createElement('input');
	inputElement.value = text;
	document.body.appendChild(inputElement);
	inputElement.select();
	document.execCommand('copy');
	document.body.removeChild(inputElement);
};

export const handleChunkError = error => dispatch => {
	// eslint-disable-next-line no-console
	if (process.env.NODE_ENV !== 'production') console.error(error);

	if (_.getSearchRegExp('Error: Loading chunk|Loading CSS chunk').test(error)) {
		window.location.reload();
	} else {
		dispatch(showPopup(
			<AsyncComponent
				import={import(/* webpackChunkName: "chunk_error_popup" */ 'app/common/ChunkErrorPopup')}
			/>,
		));
	}
};

/**
 * Replacer for window.confirm.
 * Used asynchronously with async/await.
 * Hides showed popup on both actions.
 *
 * @param {{content: JSX.Element}} props Same structure, that passed to InfoPopup component.
 * @returns {Promise}
 */
export const confirmAction = (props: InfoPopupProps) => dispatch => new Promise((resolve, reject) => {
	const onCancel = () => {
		dispatch(hidePopup());
		reject();
	};
	const onConfirm = () => {
		dispatch(hidePopup());
		resolve(true);
	};

	dispatch(showPopup(<InfoPopup
		{...props}
		title={i18n.t('actions.ConfirmAction')}
		width="400px"
		confirmLabel={i18n.t('actions.Ok')}
		cancelLabel={i18n.t('actions.Cancel')}
		onCancel={onCancel}
		onConfirm={onConfirm}
	>
		{props.content}
	</InfoPopup>));
});
