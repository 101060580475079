import {
	getSimilarSeances,
	canMoveSeance,
	getDaysToPatch,
} from 'app/schedule/selectors/scheduleSeancesSelectors';
import handleSortSeances from 'app/schedule/handlers/handleSortSeances';
import { ISeance, ISeanceToPatch } from 'app/schedule/scheduleTypes';
import { ICinema, IHall } from 'app/user/UserTypes';
import { ICinemaSettingsHall } from 'app/cinemas_settings/types';
import { addErrorToast } from 'app/app/AppActions';
import { SALES_EMAIL, SUPPORT_PHONE } from 'app/app/AppConstants';

export const seanceSource = {
	beginDrag: ({ seance, editOnlyCurrentDay, seancesInHall }, _monitor, component) => {
		const initialX = component.seanceRef.getBoundingClientRect().left;

		return {
			movingSeancesIDs: editOnlyCurrentDay ? [seance.id] : _.pluck(getSimilarSeances(seancesInHall, seance), 'id'),
			position: seance.position,
			nearestLockedSeancePosition: _.chain(seancesInHall[seance.date_start])
				.first(seance.position)
				.findLastIndex((daySeance: ISeance) => !canMoveSeance(daySeance))
				.value(),
			dragPreviewWidth: component.seanceRef.offsetWidth,
			initialHallID: seance.hall_id,
			minX: initialX,
			maxX: initialX,
		};
	},
	canDrag: ({ seance, canUserEditSchedule, hasReverseIntegration }) => {
		return canUserEditSchedule && canMoveSeance(seance) && !hasReverseIntegration;
	},
	endDrag: (props, monitor) => {
		const { movingSeancesIDs } = monitor.getItem();
		const { currentCinemaID, patchScheduleSeances, seancesInHall, editOnlyCurrentDay, seance } = props;

		const changingDaysArray = getDaysToPatch(
			seancesInHall,
			editOnlyCurrentDay,
			seance.date_start,
		);

		const seancesToPatch = _.map(
			changingDaysArray,
			(day: string) => ({
				date_start: day,
				time_start: _.min(seancesInHall[day], (item: ISeance) => item.date).time_start,
				seances: handleSortSeances({
					seances: seancesInHall[day],
					movingSeancesIDs,
					newPosition: seance.position,
				}),
			}),
		);

		patchScheduleSeances({
			seancesToPatch,
			currentCinemaID,
			initialSeances: seancesInHall,
		});
	},
};

export const seanceTarget = {
	canDrop: ({ seance }, monitor) => {
		const { initialHallID, nearestLockedSeancePosition } = monitor.getItem();

		return seance.hall_id === initialHallID && seance.position > nearestLockedSeancePosition;
	},
	hover(props, monitor) {
		if (!monitor.canDrop()) return;
		const { movingSeancesIDs, position: dragIndex } = monitor.getItem();
		const hoverIndex = props.seance.position;

		if (dragIndex !== hoverIndex) {
			props.onSortSeances({ movingSeancesIDs, newPosition: hoverIndex });
			monitor.getItem().position = hoverIndex;
		}
	},
};

const transformToSeancePatch = (seances: ISeance[], hallID: IHall['id']): ISeanceToPatch => ({
	date_start: seances[0].date_start,
	time_start: _.min(seances, (item: ISeance) => item.date).time_start,
	seances: seances.map((seance: ISeance) => ({
		...seance,
		id: null,
		hall_id: hallID,
	}),
	) });

export const getPatchDataForCastling = (
	groupedSeances: {[date: string]: ISeance[] },
	hallID: IHall['id'],
	days: string[],
): ISeanceToPatch[] => _.chain(groupedSeances)
	.pick(days)
	.reduce((memo: ISeanceToPatch[], seances) => {
		if (seances.length) {
			memo.push(transformToSeancePatch(seances, hallID));
		}

		return memo;
	}, [])
	.value();

export const getAllSeancesForCastling = (
	groupedSeances: {[date: string]: ISeance[] },
	days: string[],
): ISeance[] => _.chain(groupedSeances).pick(days).values().flatten().value();

export const checkHallLicense = (hallSettings: ICinemaSettingsHall[], currentHallID: number): boolean =>
	!!hallSettings.find(item => item.id === currentHallID)?.active.value;

export const showNoLiceseAlert = (type: 'add' | 'copy' | 'approve', hallTitle?: string) => {
	switch (type) {
		case 'add':
			addErrorToast({
				subtitle: i18n.t('schedule:alerts.NoLicenseAddTypeAlert', {
					hallTitle: hallTitle ? `${i18n.t('common.Hall')} ${hallTitle}` : i18n.t('common.hall'),
					phone: SUPPORT_PHONE,
					email: SALES_EMAIL,
				}),
			});
			break;
		case 'copy':
			addErrorToast({
				subtitle: i18n.t('schedule:alerts.NoLicenseCopyTypeAlert', {
					hallTitle: hallTitle ? `${i18n.t('common.Hall')} ${hallTitle}` : i18n.t('common.hall'),
					phone: SUPPORT_PHONE,
					email: SALES_EMAIL,
				}),
			});
			break;
		case 'approve':
			addErrorToast({
				subtitle: i18n.t('schedule:alerts.NoLicenseApproveTypeAlert', {
					phone: SUPPORT_PHONE,
					email: SALES_EMAIL,
				}),
			});
			break;
		default:
			break;
	}
};

export const getCinemasWithoutReverseIntegration = (cinemas: ICinema[]) =>
	cinemas.filter(({ is_external_schedule }) => !is_external_schedule);

export const getSeanceDurationWithoutPause = (seance: ISeance) => seance.duration.advertising + seance.duration.release;

export const getReleaseWeekCount = (releaseDate: string, comparedDate: string) => {
	const releaseWeekCount = moment(comparedDate).week() - moment(releaseDate).week();

	return releaseWeekCount >= 0 ? releaseWeekCount + 1 : '';
};
