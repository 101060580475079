export default function handleSortSeances({seances, movingSeancesIDs, newPosition}) {
	const movingSeance = _.find(seances, seance => _.any(movingSeancesIDs, id => id === seance.id));

	if (!movingSeance) return seances;

	return _.chain(seances)
		.reject(seance => seance.id === movingSeance.id)
		.insert(movingSeance, newPosition)
		.map((seance, position) => (seance.position !== position ? {...seance, position} : seance))
		.value();
}
