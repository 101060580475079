import {createSelector} from 'reselect';
import {
	CINEMA_PARK_CINEMA_NETWORK_ID,
	FORMULA_KINO_CINEMA_NETWORK_ID,
	KINOMAX_CINEMA_NETWORK_ID,
	MIRAGE_CINEMA_NETWORK_ID,
} from 'app/user/constants/UserConstants';
import {IS_NEW_YEAR_HOLIDAYS} from 'app/new_year/NewYearConstants';
import {defaultCinemasSettings} from 'app/cinemas_settings/constants';
import {PREMIERE_GATEWAY} from 'app/user/constants/TicketSystemConstants';
import merge from 'merge';
import {makeHallTitle} from 'app/user/helpers/userCinemaHelpers';
import {get} from 'app/mixins';

export const getUserID = state => state.userReducer.user_id;
export const getUserFirstName = state => state.userReducer.first_name;
export const getUserLastName = state => state.userReducer.last_name;
export const getUserPosition = state => ({
	user_position: state.userReducer.user_position,
	user_position_key: state.userReducer.user_position_key,
});
export const getUserPhoto = state => state.userReducer.photo_medium;
export const getUserPhotoBig = state => state.userReducer.photo_big;
export const getUserType = state => state.userReducer.type;
export const getUserCinemas = state => state.userReducer.cinema;
export const getUserEmail = state => state.userReducer.email;
export const getUserPhone = state => state.userReducer.phone;
export const getUserBirthday = state => state.userReducer.birth_date;
export const getDistributorID = state => state.userReducer.distributor_id || null;
export const getDistributorName = state => (state.userReducer.name || {}).short;
export const getDistributorFullName = state => (state.userReducer.name || {}).full;
export const getCinemasData = state => state.userReducer.cinemasData;
export const getIsCinemasDataFetching = state => state.userReducer.isCinemasDataFetching;
export const getCinemaRights = state => state.userReducer.cinema_rights;
export const getUserCurrentCinemaID = state => state.userReducer.currentCinema;
export const getUserCurrentCountry = state => state.userReducer.country_for;
export const getAdNetworkAccess = state => !!state.userReducer.access_ad_network;
export const getKdmContentAccess = state => state.userReducer.access_content;
export const getDD24Access = state => state.userReducer.access_dd4_serv;
export const getTMSAccess = state => state.userReducer.access_tms;
export const getKinositeAccess = state => !!state.userReducer.access_kinosite;
export const getKinopulseAccess = state => !!state.userReducer.allowedPages.cinema_pulse;
export const getCVAccess = state => state.userReducer.access_cv;
export const getReleasesAccess = state => state.userReducer.releases_sub_user;
export const getTicketSystemAccess = state => !!state.userReducer.access_ticket_system;
export const getAutoUploadAccess = state => state.userReducer.features.access_autoupload;
export const getCueCatalogAccess = state => !!state.userReducer.features.access_tms_cues_catalog;
export const getMassSchedulingAccess = state => state.userReducer.features.mass_scheduling;
export const hasCampaignImportFeature = state => !!state.userReducer.features.campaign_import;
export const getVideoposterAccess = state => state.userReducer.access_videoposter;
export const getBarAccess = state => !!state.userReducer.access_bms;
export const getCRMAccess = state => !!state.userReducer.access_crm;
export const getPrmAccess = state => !!state.userReducer.access_price_manual;
export const getTx = state => ((state || {}).userReducer || {}).tx;
export const getUserMobileSettings = state => state.userReducer.mobile_settings;
export const getUserTmsSettings = state => state.userReducer.tms_settings;
export const getUserEmailSettings = state => state.userReducer.email_settings;
export const getUserAllowedPages = state => state.userReducer.allowedPages;
export const getCinemaTmsSettings = state => state.userReducer.cinema_tms;
export const getHallsTmsSettings = state => state.userReducer.halls_settings;
export const getTMSPlaylistsAccess = state => state.userReducer.allowedPages.tms.playlists;
export const getLastReadChatTimestamp = state => state.userReducer.last_read_chat;
export const getUserCurrentDate = state => state.userReducer.currentDate;
export const getUserBannerData = state => state.userReducer.banner;
export const getUserBanInfo = state => state.userReducer.user_ban;
export const getUserTicketSystem = state => state.userReducer.allowedPages.ticketSystem;
export const getTicketSystemScheduleAccess = state => state.userReducer.allowedPages.ticketSystem.schedules;
export const getUserFeatureAwareness = state => state.userReducer.feature_awareness;
export const getCinemasRelations = state => state.userReducer.cinema_relations;
export const getUserNotifications = state => state.userReducer.allowedPages.notifications;
export const getIsUserSystemEmployee = state => !!state.userReducer.system_employee;
export const getTmsTemplatesAccess = state => !!state.userReducer.features.access_tms_playlist_templates;
export const getHallsLicenses = state => state.userReducer.halls_licenses;
export const getUnreadsCount = state => state.userReducer.salePromotionsUnreadCount;

export const getIndexedCinemas = createSelector(
	getUserCinemas,
	cinemas => _.indexBy(cinemas, 'id'),
);

export const hasOnlyOneCinema = createSelector(
	getUserCinemas,
	cinemas => cinemas.length === 1,
);

export const getIndexedCinemasTmsSettings = createSelector(
	getCinemaTmsSettings,
	cinemaTmsSettings => _.indexBy(cinemaTmsSettings, 'cinema_id'),
);

export const getCinemaSettingsByID = (state, id) => {
	const cinemaData = getCinemasData(state)[id || getUserCurrentCinemaID(state)];

	return _.get(cinemaData, 'cinema_settings', defaultCinemasSettings);
};

export const getUserCurrentCinema = state => getIndexedCinemas(state)[getUserCurrentCinemaID(state)] || {};
export const getHasReverseIntegration = state => getUserCurrentCinema(state).is_external_schedule;

export const isCurrentUser = (state, userID) => getUserID(state) === userID;
export const getUserFullName = state => `${getUserFirstName(state)} ${getUserLastName(state)}`;

export const getUserProfileNotificationsSettings = createSelector(
	getUserMobileSettings,
	({
		push_settings: {
			on_keys: onKeys,
			on_pinned_message: onPinnedMessage,
			on_releases: onReleases,
			on_shipments: onShipments,
			on_releases_from_repertoire: onReleasesFromRepertoire,
		},
	}) => ({
		onKeys,
		onPinnedMessage,
		onReleases,
		onShipments,
		onReleasesFromRepertoire,
	}),
);

export const getUserTmsSettingsNotificationSettings = createSelector(
	getUserTmsSettings,
	({send_auto_cancel_notification: sendAutoCancelNotifications}) => ({
		sendAutoCancelNotifications,
	}),
);

export function calcUserDescriptionType(type) {
	let i18nKey = _.capitalize(type);

	if (type === 'senior_cashier') {
		i18nKey = 'SeniorCashier';
	}

	return i18n.t(`UsersTypes.${i18nKey}`);
}

export const getUserDescriptionType = createSelector(getUserType, calcUserDescriptionType);

export const hasCinemaTicketSystem = (state, id = getUserCurrentCinema(state).id) =>
	_.get(getCinemaSettingsByID(state, id), 'ticket_system.title') === 'Система Кино';

export const getTicketSystemToken = state => state.userReducer.ticket_system_token;

export const getCinemaRightsById = (state, id) => _.findWhere(getCinemaRights(state), {
	cinema_id: (id || getUserCurrentCinema(state).id),
}) || {};

export const getCurrentCinemaSettings = createSelector(
	[getCinemasData, getUserCurrentCinema],
	(cinemasData, cinema) => {
		const cinemaData = cinemasData[cinema.id];

		return _.get(cinemaData, 'cinema_settings', defaultCinemasSettings);
	},
);

export const doesCurrentCinemaHasSeatsLimit = createSelector(
	getCurrentCinemaSettings,
	settings => !!get(
		settings,
		'ticket_system.halls.capacity_restriction_settings',
		false,
	),
);

export const getReleaseCountFormat = createSelector(
	[getCurrentCinemaSettings],
	cinemaSettings => _.get(cinemaSettings, 'release_count_format', ''),
);

export const getTicketSystemRootUrl = createSelector(
	getCurrentCinemaSettings,
	cinemaSettings => {
		const additionalCashboxSystem = _.find(cinemaSettings.additional_ticket_systems, system => system.is_kinokassa);
		const additionalUrl = _.get(additionalCashboxSystem, 'url');
		const mainUrl = _.get(cinemaSettings, 'ticket_system.url');

		return mainUrl || additionalUrl;
	},
);

export const getTicketSystemMarkingSubsectionAccess = createSelector(
	getCurrentCinemaSettings,
	cinemaSettings =>
		!!_.get(cinemaSettings.ticket_system, 'new_stuff_marking', false),
);

export const getTicketSystemExciseAccess = createSelector(
	getCurrentCinemaSettings,
	cinemaSettings =>
		!!_.get(cinemaSettings.ticket_system, 'has_excise', false),
);

export const getTicketSystemCertificateTypesAccess = createSelector(
	getCurrentCinemaSettings,
	cinemaSettings =>
		_.get(cinemaSettings, 'ticket_system.certificate_types'),
);

export const getTicketSystemCertificateLifeCycleAccess = createSelector(
	getCurrentCinemaSettings,
	cinemaSettings =>
		_.get(cinemaSettings, 'ticket_system.certificate_life_cycle'),
);

export const isShowChrono = state => _.get(getCurrentCinemaSettings(state), 'show_release_chrono', false);

const getCinemaID = (state, cinemaID) => cinemaID;
export const makeGetCinemaSettings = () => createSelector(
	[getCinemasData, getCinemaID],
	(cinemasData, cinemaID) => {
		const cinemaData = cinemasData[cinemaID];

		return _.get(cinemaData, 'cinema_settings', defaultCinemasSettings);
	},
);

export const getCinemaLegal = (state, id) => {
	const cinemasData = getCinemasData(state);
	const currentCinemaID = getUserCurrentCinema(state).id;
	const cinemaData = cinemasData[id || currentCinemaID];

	if (cinemaData) {
		return {
			...cinemaData.cinema_legal,
			cinema_id: cinemaData.cinema_id,
		};
	}

	return {};
};

export const isMirageCinema = state =>
	parseInt((getUserCurrentCinema(state).cinema_network || {}).id, 10) === MIRAGE_CINEMA_NETWORK_ID;

export const isKinomax = state =>
	parseInt((getUserCurrentCinema(state).cinema_network || {}).id, 10) === KINOMAX_CINEMA_NETWORK_ID;

export const isCinemaPark = state =>
	parseInt((getUserCurrentCinema(state).cinema_network || {}).id, 10) === CINEMA_PARK_CINEMA_NETWORK_ID;

export const isFormulaKino = state =>
	parseInt((getUserCurrentCinema(state).cinema_network || {}).id, 10) === FORMULA_KINO_CINEMA_NETWORK_ID;

export const isUnifiedNetwork = state =>
	isKinomax(state) || isCinemaPark(state) || isFormulaKino(state);

export const isAdmin = state => state.userReducer.admin;

export const isModerator = state => Boolean(state.userReducer.moderator);

export const isApproved = state => state.userReducer.approved;

export const isBooker = state => getUserType(state) === 'cinema';

export const isGuest = state => getUserType(state) === 'guest';

export const isPartner = state => getUserType(state) === 'partner';

export const isManager = state => getUserType(state) === 'manager';

export const isDistributor = state => getUserType(state) === 'distributor';

export const isMechanic = state => getUserType(state) === 'mechanic';

export const isSeniorCashier = state => getUserType(state) === 'senior_cashier';

export const isAccountant = state => getUserType(state) === 'accountant';

export const isAdministrator = state => getUserType(state) === 'administrator';

export const isUserMaster = (state, id) => isDistributor(state) || !!getCinemaRightsById(state, id).master;

export const isUserModerator = (state, id) => isDistributor(state) || !!getCinemaRightsById(state, id).is_moderator;

export const hasFullAccess = (state, id) => isDistributor(state) || getCinemaRightsById(state, id).level === 'write';

export const isInSandbox = (state, id) => getCinemaRightsById(state, id).level === 'none';

export const isReadOnly = (state, id) => getCinemaRightsById(state, typeof id === 'object' ? null : id).level === 'read';

export const isEditableSettings = (state, id) => !isInSandbox(state, id) && !isReadOnly(state, id);

export const isWithoutRepertoire = (state, id) => getCinemaRightsById(state, id).level === 'rw';

export const isVIP = state => !!state.userReducer.vip;

export const isCashboxUser = createSelector(
	getUserType,
	userType => ['accountant', 'cashier', 'senior_cashier'].includes(userType),
);

export const isMediaplanOnly = (state, id) =>
	getCinemaRightsById(state, typeof id === 'object' ? null : id).level === 'mediaplan';

export const isPremiere = (state, id = {}) => getCinemaSettingsByID(state, typeof id === 'object' ? null : id).is_premiere;

export const isPremiereGateway = state => _.get(getCurrentCinemaSettings(state), 'ticket_system.title') === PREMIERE_GATEWAY;

export const hasKinositeAccess = createSelector(
	[getCurrentCinemaSettings, getKinositeAccess],
	(cinemaSettings, hasUserAccess) => !_.get(cinemaSettings, 'kinosite.news.enabled') || !hasUserAccess,
);

export const hasKinositeEmailAccess = createSelector(
	[getCurrentCinemaSettings],
	cinemaSettings => _.get(cinemaSettings, 'kinosite.clients.enabled'),
);

export const hasUserBookingAccess = (state, cinemaID) =>
	(isDistributor(state) || isBooker(state) || isAdministrator(state) || isMechanic(state))
	&& (isUserMaster(state, cinemaID) || hasFullAccess(state, cinemaID) || isInSandbox(state, cinemaID));

export const getCinema = (state, id) =>
	(id && typeof id !== 'object' ? getIndexedCinemas(state)[id] || {} : getUserCurrentCinema(state));

export const getCinemaTitleAndCity = (state, id) =>
	`${_.getLocalize(getCinema(state, id).title)}, ${_.getLocalize(getCinema(state, id).city.title)}`;

export const getHall = (state, cinemaId, hallId) => _.findWhere(getCinema(state, cinemaId).halls, {id: hallId}) || {};
export const getHallsCount = (state, cinemaID) => getIndexedCinemas[cinemaID].halls.length;

const getHallIDFromArgs = (state, cinemaID, hallID) => hallID;

export const makeGetHall = () => createSelector(
	getCinema, getHallIDFromArgs,
	(cinema, hallID) => _.findWhere(cinema.halls, {id: hallID}) || {},
);

export const isIMAXHall = (state, cinemaId, hallId) => _.some(getHall(state, cinemaId, hallId).formats,
	format => format.toUpperCase().includes('IMAX'));

export const isAutoCancelActive = createSelector(
	[getCinemaTmsSettings, getUserCurrentCinemaID],
	(tmsSettings, cinemaID) => (_.findWhere(tmsSettings, {cinema_id: cinemaID}) || {}).is_auto_cancel,
);

export const getHalls = (state, cinemaId) => getCinema(state, cinemaId).halls || [];
export const getCurrentCinemaHalls = state => getHalls(state, getUserCurrentCinemaID(state));

export const getHallTitle = (state, cinemaID, hallID, isLongTitle = true) => {
	const hall = getHall(state, cinemaID, hallID);

	return makeHallTitle(hall, isLongTitle);
};

const getHallsHelper = (state, hallIDs) => hallIDs;
const getHallGetter = state => (cinemaID, hallID) => getHall(state, cinemaID, hallID);

export const makeGetHallsTitlesByIDs = () => createSelector(
	getUserCurrentCinemaID, getHallGetter, getHallsHelper,
	(cinemaID, hallGetter, hallIDs) => [...hallIDs]
		.sort((prev, next) => prev - next)
		.map(hallID => hallGetter(cinemaID, hallID).title),
);

export const getCurrentCinemaHallIDs = createSelector(
	getCurrentCinemaHalls,
	halls => _.pluck(halls, 'id'),
);

export const getAllUserHalls = createSelector(
	[getUserCinemas],
	cinemas => _.flatten(_.map(cinemas, cinema => cinema.halls)),
);

export const getHallFromAllHalls = (state, hallID) => _.findWhere(getAllUserHalls(state), {id: hallID});

export const getKdmBlocked = (state, id) => !!getCinema(state, id).kdm_blocked;

export const hasAccessToDistributors = state => isBooker(state) || isDistributor(state) || isMechanic(state)
	|| isAdministrator(state);

export const hasActiveBookingSubscription = (state, id) => {
	const status = _.get(getCinema(state, id), 'subscriptions.booking.status');

	return (status === 'active' || status === 'trial');
};

export const hasNeverSubscription = (state, id) => {
	const start = _.get(getCinema(state, id), 'subscriptions.booking.start');

	return start === null;
};

export const hasActiveTMSSubscription = (state, id) => {
	const {status} = getCinema(state, id).subscriptions.tms;

	return (status === 'active' || status === 'trial');
};

export const hasActiveCVSubscription = (state, id) => {
	const status = _.get(getCinema(state, id), 'subscriptions.cv.status');

	return status === 'active';
};

export const hasActiveReleasesSubscription = (state, id) => {
	const status = _.get(getCinema(state, id), 'subscriptions.releases.status');

	return status === 'active';
};

const subscriptionDaysDiff = createSelector(
	[getUserCurrentCinema, (state, service) => service],
	(cinema, service) => {
		if (service && !_.isEmpty(cinema.subscriptions)) {
			const subscriptionDateEnd = _.get(cinema.subscriptions[service.toLowerCase()], 'end');

			return subscriptionDateEnd ? moment().diff(moment.unix(subscriptionDateEnd), 'days') : null;
		}

		return null;
	},
);

const getTopNotificationClosingTimestamp = (state, service) => state.appReducer.topNotifications[service];

export const hasTopNotification = (state, service) => {
	const subscriptions = getUserCurrentCinema(state).subscriptions || {};
	const subscriptionService = service ? subscriptions[service.toLowerCase()] : null;

	return service
		? _.get(subscriptionService, 'status') !== 'inactive' && !!_.get(subscriptionService, 'end')
			&& (subscriptionDaysDiff(state, service) >= -5 && subscriptionDaysDiff(state, service) <= 0)
			&& !!moment().diff(moment.unix(getTopNotificationClosingTimestamp(state, service)), 'days')
		: false;
};

export const getTopNotificationTitle = (state, service) => (
	`${subscriptionDaysDiff(state, service)
		? i18n.t('Subscription.TopNotificationTitleDays', {count: Math.abs(subscriptionDaysDiff(state, service))})
		: i18n.t('Subscription.TopNotificationTitleLastDay')
	}
	${i18n.t('Subscription.TopNotificationTitle', {service: service ? i18n.t(`services.${service}`) : ''})}`
);

export const getTopNotificationSubtitle = createSelector(
	[getUserCurrentCinema, (state, service) => service],
	(cinema, service) => {
		if (service && !_.isEmpty(cinema.subscriptions)) {
			const subscriptionDateEnd = _.get(cinema.subscriptions[service.toLowerCase()], 'end');

			return i18n.t('Subscription.TopNotificationSubtitle', {
				screens: i18n.t(`services.${service}`),
				day: moment.unix(subscriptionDateEnd).format('DD MMMM YYYY'),
			});
		}

		return null;
	},
);
export const getAllSubscriptionsByCinemaId = createSelector(
	getCinema,
	cinema => cinema.subscriptions || {},
);

const getSubscriptionName = (state, cinemaID, subscriptionName) => subscriptionName;
export const getSubscriptionByCinemaIDByName = createSelector(
	getAllSubscriptionsByCinemaId, getSubscriptionName,
	(subscription, name) => ({...subscription[name], name}),
);

export const getBookingSubscriptionInfo = (state, cinemaID) => getSubscriptionByCinemaIDByName(state, cinemaID, 'booking');
export const getMonitoringSubscriptionInfo = (state, cinemaID) => getSubscriptionByCinemaIDByName(state, cinemaID, 'monitoring');
export const getKinokassaSubscriptionInfo = (state, cinemaID) => getSubscriptionByCinemaIDByName(state, cinemaID, 'kinokassa');
export const getKinowifiSubscriptionInfo = (state, cinemaID) => getSubscriptionByCinemaIDByName(state, cinemaID, 'kinowifi');
export const getTMSSubscriptionInfo = (state, cinemaID) => getSubscriptionByCinemaIDByName(state, cinemaID, 'tms');
export const getKinopulseSubscriptionInfo = (state, cinemaID) =>
	getSubscriptionByCinemaIDByName(state, cinemaID, 'dashboard');

export const needRepertoryBlocker = state => isMechanic(state) && isInSandbox(state);

export const hasAnyCinemaBookingSubscription = state =>
	!!_.find(getUserCinemas(state), cinema => hasActiveBookingSubscription(state, cinema.id));

export const atLeastOneCinemaMaster = state => (
	!!_.find(getUserCinemas(state), cinema => isUserMaster(state, cinema.id))
);

export const atLeastOneCinemaHasFullAccess = state => (
	!!_.find(getUserCinemas(state), cinema => hasFullAccess(state, cinema.id))
);

export const isRepertoireReadOnly = (state, id) => {
	const rights = getCinemaRightsById(state, id).level;

	return (rights === 'read' || rights === 'rw');
};

export const isEveryCinemasReadOnly = state => _.every(
	getUserCinemas(state),
	cinema => isRepertoireReadOnly(state, cinema.id),
);

export const hasKinoWifiInCinema = (state, cinema) => !!cinema.kino_wifi;

export const getKinoWifiAccess = state => (
	!!_.find(getUserCinemas(state), cinema => hasKinoWifiInCinema(state, cinema))
);

export const accessTo = (state, page) => {
	let access;

	switch (page) {
		case 'kdmContent': access = getKdmContentAccess(state);
			break;
		case 'DD24': access = getDD24Access(state);
			break;
		case 'TMS': access = getTMSAccess(state);
			break;
		case 'videoposter': access = getVideoposterAccess(state);
			break;

		default: access = false;
	}

	return !!access;
};

export const currentCinemaTimeZone = createSelector(
	[getUserCurrentCinema],
	cinema => _.get(cinema, 'region.time_zone'),
);

export const isUserBanned = createSelector(getUserBanInfo, info => !_.isNull(info));

export const isUserMultiplex = createSelector(getUserCinemas, cinemas => (cinemas.length > 1));

export const hasRepertory = state => isBooker(state) || isMechanic(state) || isAdministrator(state);

export const getKrisbergTMS = state => _.contains(['krisberg'], getUserCurrentCinema(state).tms_system);

export const getRosettaTMS = state => _.contains(['rosettabridge'], getUserCurrentCinema(state).tms_system);

export const getCurrentCinemaTmsSettings = createSelector(
	[getUserCurrentCinemaID, getCinemaTmsSettings],
	(cinemaID, tmsSettings) => _.findWhere(tmsSettings, {cinema_id: cinemaID}) || {},
);

export const hasCinemaAccessToTmsV2 = () => true; // TODO удалить при захоронении ТМС1.0

export const isNeedToShowAgencyPicker = createSelector(
	getAdNetworkAccess, (_state, route) => route,
	(isAdNetworkUser, route) => isAdNetworkUser && route.includes('ad_network'),
);

export const getTicketSystemAllowedPages = createSelector(
	[getUserAllowedPages, getCurrentCinemaSettings, isAdmin],
	(allowedPages, currentCinemaSettings, isAdminUser) => {
		if (!currentCinemaSettings) return allowedPages.ticketSystem;

		const hasNewSaleManagement = !!_.get(currentCinemaSettings.ticket_system, 'new_sale_management', false);
		const hasHallsSettings = !!(
			_.get(currentCinemaSettings.ticket_system, 'halls.capacity_restriction_settings', false)
			|| _.get(currentCinemaSettings.ticket_system, 'halls.social_distance_settings', false)
		);
		const hasCertificates = !!_.get(currentCinemaSettings.ticket_system, 'new_certificates', false);
		const hasCounterparty = !!_.get(currentCinemaSettings.ticket_system, 'new_counterparties', false);
		const hasStocks = !!_.get(currentCinemaSettings.ticket_system, 'stocks', false);
		const hasLimitedConstructor = !!_.get(currentCinemaSettings.ticket_system, 'halls.limited_constructor', false);
		const hasConstructor = !!_.get(currentCinemaSettings.ticket_system, 'halls.constructor', false);
		const hasReservation = !!_.get(currentCinemaSettings.ticket_system, 'new_reservations', false);
		const hasBackup = !!_.get(currentCinemaSettings.ticket_system, 'new_backups', false);

		return merge.recursive(true, allowedPages.ticketSystem, {
			administrate: {
				sale_management: allowedPages.ticketSystem.administrate.sale_management && !hasNewSaleManagement,
				goods_ops: allowedPages.ticketSystem.administrate.goods_ops && hasNewSaleManagement,
				seance_ops: allowedPages.ticketSystem.administrate.seance_ops && hasNewSaleManagement,
				sale_settings: isAdminUser || (allowedPages.ticketSystem.administrate.sale_settings && hasNewSaleManagement),
				backup: allowedPages.ticketSystem.administrate.backup && hasBackup,
			},
			auditoriums: {
				auditoriums_settings: allowedPages.ticketSystem.auditoriums.auditoriums_settings && hasHallsSettings,
				constructor: (allowedPages.ticketSystem.auditoriums.constructor && hasConstructor)
					|| (allowedPages.ticketSystem.auditoriums.limited_constructor && hasLimitedConstructor),
			},
			certificates: hasCertificates,
			counterparty: allowedPages.ticketSystem.customers && hasCounterparty,
			customers: allowedPages.ticketSystem.customers && !hasCounterparty,
			orders: allowedPages.ticketSystem.orders && !hasReservation,
			reservation_management: allowedPages.ticketSystem.orders && hasReservation,
			stocks: allowedPages.ticketSystem.stocks && hasStocks,
		});
	},
);

export const getKinositeAllowedPages = createSelector(
	[getUserAllowedPages, getCurrentCinemaSettings],
	(allowedPages, currentCinemaSettings) => {
		if (!currentCinemaSettings) return allowedPages.kinosite;
		const isKinositeNewsEnabled = _.get(currentCinemaSettings.kinosite, 'news.enabled', false);

		return {
			about: isKinositeNewsEnabled,
			content: isKinositeNewsEnabled,
			contacts: isKinositeNewsEnabled,
			clients_email: _.get(currentCinemaSettings.kinosite, 'clients.enabled', false),
			feedbacks: isKinositeNewsEnabled,
			halls: isKinositeNewsEnabled,
			pages: isKinositeNewsEnabled,
			kinowidget: isKinositeNewsEnabled,
			online_sales_report: isKinositeNewsEnabled,
			online_sales_refund: allowedPages.kinosite.online_sales_refund,
			online_sale_change_status_to_refund: allowedPages.kinosite.online_sale_change_status_to_refund,
			online_orders: isKinositeNewsEnabled,
		};
	},
);

/**
 * Returns minimal break length for current cinema in minutes
 * @param {State} state
 * @returns {number}
 */
export const getCurrentCinemaMinBreakLength = state => getCurrentCinemaTmsSettings(state).time_between_seances || 3;

export const getDD24CinemasAvailableToUpload = state => {
	let dd24Cinemas = [];
	const booker = isBooker(state);
	const mechanic = isMechanic(state);
	const isTMSUser = getTMSAccess(state);
	const isUserAdmin = isAdministrator(state);

	if (isApproved(state) && accessTo(state, 'DD24') && (booker || mechanic || isTMSUser || isUserAdmin)) {
		dd24Cinemas = _.filter(getUserCinemas(state), cinema => {
			const validBooker = booker && hasFullAccess(state, cinema.id);
			const isRealCinema = !isInSandbox(state, cinema.id);
			const validMechanic = mechanic && isRealCinema;
			const validAdmin = isUserAdmin && isRealCinema;

			return (isUserMaster(state, cinema.id) || validBooker || validMechanic || isTMSUser || validAdmin) && cinema.dd24_pro;
		});
	}

	return dd24Cinemas;
};

export const isNewYearHolidays = () => {
	const today = moment().dayOfYear();

	return (IS_NEW_YEAR_HOLIDAYS && (today < 8 || today >= 358));
};

export const canUserEditRepertory = createSelector(
	[isRepertoireReadOnly, hasActiveBookingSubscription, isMediaplanOnly],
	(isOnlyRead, bookingSubscription, isOnlyMediaplan) => !isOnlyRead && bookingSubscription && !isOnlyMediaplan,
);

export const canUserEditSchedule = createSelector(
	[isReadOnly, isMediaplanOnly, hasActiveBookingSubscription],
	(isOnlyRead, isOnlyMediaplan, bookingSubscription) => !(!bookingSubscription || isOnlyRead || isOnlyMediaplan),
);

export const getCinemasWithWritingAccess = createSelector(
	[getUserCinemas, getCinemaRights],
	(cinemas, cinemasRights) => _.filter(cinemas, cinema => {
		const cinemaRights = _.findWhere(cinemasRights, {cinema_id: cinema.id}).level;

		return !(cinemaRights === 'read' || cinemaRights === 'mediaplan');
	}),
);

export const isActiveUserWithoutCinemas = createSelector(
	[getUserCinemas, isGuest, isDistributor, isPartner],
	(cinemas, isUserGuest, isUserDistributor, isUserPartner) => !cinemas.length &&
		!isUserGuest && !isUserDistributor && !isUserPartner,
);

const ignorePages = ['tms/settings'];

export const needShowSubscriptionBlank = (state, service, route) => {
	if (ignorePages.includes(`${route[1]}/${route[2]}`)) {
		return false;
	}

	return _.get(getUserCurrentCinema(state), `subscriptions.${service.toLowerCase()}.status`) === 'inactive';
};

export const getCinemasWithBookingSubscription = createSelector(
	[getUserCinemas],
	cinemas => _.filter(cinemas, ({subscriptions: {booking: {status}}}) => status === 'active' || status === 'trial'),
);

export const getCinemasWithAccessAndSubscription = createSelector(
	[getCinemasWithWritingAccess, getCinemasWithBookingSubscription, getUserCurrentCinemaID],
	(cinemasWithAccess, cinemasWithSubscription, userID) => _.reject(
		_.intersection(cinemasWithAccess, cinemasWithSubscription),
		cinema => cinema.id === userID,
	),
);

export const getCinemasApprovedByFondKino = state =>
	_.filter(getUserCinemas(state), cinema => cinema.is_fond_kino);

export const hasCinemasApprovedByFondKino = state => Boolean(getCinemasApprovedByFondKino(state).length);

export const hasUserCinemaID = createSelector(
	[getUserCinemas, (_state, cinemaID) => cinemaID],
	(userCinemas, cinemaID) => userCinemas.some(cinema => cinema.id === cinemaID),
);

export const cinemasWhichUserHasFullAccessOrIsMaster = state =>
	_.filter(getUserCinemas(state), ({id}) => hasFullAccess(state, id) || isUserMaster(state, id));

export const getCinemasWithoutSandboxIDs = state =>
	new Set(
		getUserCinemas(state)
			.filter(cinema => !isInSandbox(state, cinema.id))
			.map(cinema => cinema.id),
	);

export const getCurrentCinemaPosterposURL = createSelector(
	[getCurrentCinemaSettings],
	cinemaSettings => _.get(cinemaSettings, 'bar_system.posterpos_url'),
);

const dateDiffInMinutes = (startTime, endTime) => {
	const startTimeMoment = moment(startTime, 'HH:mm');
	const endTimeMoment = (endTime > startTime) ? moment(endTime, 'HH:mm') : moment(endTime, 'HH:mm').add(1, 'days');

	return parseInt(endTimeMoment.diff(startTimeMoment, 'minutes'), 10);
};

export const getCinemaDayTime = (state, id) => {
	const cinemaHalls = getCinema(state, id).halls;
	const hallsAmount = cinemaHalls ? cinemaHalls.length : 0;
	const cinemaSettings = getCinemaSettingsByID(state, id);
	let cinemaDayTime = 960 * hallsAmount;

	if (cinemaSettings) {
		const dayTime = dateDiffInMinutes(cinemaSettings.cinema_open, cinemaSettings.cinema_close);
		const weekendTime = dateDiffInMinutes(cinemaSettings.cinema_weekend_open, cinemaSettings.cinema_weekend_close);

		if (dayTime && weekendTime) {
			cinemaDayTime = Math.floor(((dayTime * 5 + weekendTime * 2) / 7) * hallsAmount);
		}
	}

	return cinemaDayTime;
};

export const getIsUserOurEmployee = state => !!state.userReducer.our_employee;

export const getUserAccessRights = () => [0, 1, 2, 3, 4].map(access => ({
	value: access,
	title: i18n.t(`UserAccessRights.${access}.0`),
	description: i18n.t(`UserAccessRights.${access}.1`),
}));

export const getCinemaOptions = createSelector(
	getUserCinemas, cinemas => {
		const cinemaOptions = _.map(cinemas, cinema => ({
			title: `${_.getLocalize(cinema.title)}, ${_.getLocalize(cinema.city.title)}`,
			value: cinema.id,
		}));

		return [{title: i18n.t('common.AllCinemas'), value: null}, ...cinemaOptions];
	},
);

export const getUserCanEditDuration = createSelector(
	[getUserCinemas, getCinemaRights],
	(cinemas, cinemaRights) => cinemas.some(cinema => {
		const cinemaRight = cinemaRights.some(right => cinema.id === right.cinema_id);
		if (cinemaRight) return cinemaRight.level !== 'read' && cinemaRight.level !== 'mediaplan';

		return false;
	}),
);

export const getUserInitials = createSelector(
	[getUserFirstName, getUserLastName],
	(firstName, lastName) => (`${_.first(firstName) || ''}${_.first(lastName) || ''}`).toUpperCase(),
);

export const getAccessForNonBookingSections = createSelector(
	[getCurrentCinemaPosterposURL, getTicketSystemAccess, getTMSAccess,
		getKinositeAccess, getCVAccess, getKinopulseAccess, getAdNetworkAccess, getPrmAccess],
	(...params) => params.some(Boolean),
);

export const isCurrentCinemaTest = (state, cinemaID) => {
	const currentCinema = _.findWhere(getUserCinemas(state), {id: cinemaID || getUserCurrentCinemaID(state)});

	return !currentCinema || !currentCinema.is_test;
};

export const isUserOurOrSystemEmployee = createSelector(
	getIsUserOurEmployee,
	getIsUserSystemEmployee,
	(isOurEmployee, isSystemEmployee) => isOurEmployee || isSystemEmployee,
);

export const isRepertoryTutorialAllowed = createSelector(
	getUserType, getUserCinemas,
	(type, cinemas) => _.contains(['cinema', 'administrator', 'mechanic', 'manager'], type)
		&& !!cinemas.length,
);

export const getUserCinemasIds = createSelector(getUserCinemas, cinemas => _.pluck(cinemas, 'id'));
