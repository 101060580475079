import { Sections, ICinemaSettings, IUserEmployee } from 'app/cinemas_settings/types';

export const availableSections = [
	Sections.information,
	Sections.params,
	Sections.employees,
	Sections.subscriptions,
];

export const emptyEmployee: IUserEmployee = {
	user_id: '',
	is_moderator: false,
	first_name: '',
	last_name: '',
	user_position: null,
	user_position_key: null,
	email: '',
	phone: '',
	role: 'cinema',
	level: 0,
	accesses: {
		tms: false,
		videoposter: false,
		kinosite: false,
		dashboard: false,
	},
	is_approved: true,
	cinema_rights: [],
	master: false,
};

export const Accesses = {
	tms: 'services.TMS',
	videoposter: 'services.Videoposter',
	kinosite: 'services.Kinosite',
	dashboard: 'services.CinemaPulse',
};

export const defaultCinemasSettings: ICinemaSettings = {
	cinema_id: 0,
	videoposter: {
		currency: 'RUB',
	},
	cinema_close: '02:00',
	cinema_open: '10:00',
	cinema_weekend_close: '02:00',
	cinema_weekend_open: '10:00',
	default_cinema_advertising_block: 0,
	halls_settings: [],
	is_premiere: false,
	new_release_period: 1,
	occupancy_time_format: 'minutes',
	release_count_format: 'number',
	ticket_system: { title: null },
	additional_ticket_systems: [],
	ticket_system_custom: '',
	show_release_chrono: false,
	schedule_with_integrated_trailers: false,
};
