import * as types from 'app/app/AppConstants';
import { AppState, IToast } from 'app/app/types';
import { ReactNode } from 'react';
import { Moment } from 'moment/moment';

export const setAppData = (payload: Partial<AppState>) => ({
	type: types.SET_APP_DATA,
	payload,
});

export const setCurrentRoute = (payload: string[]) => ({
	type: types.SET_CURRENT_ROUTE,
	payload,
});

export const switchMainView = (payload: ReactNode) => ({
	type: types.APP_SWITCH_MAIN_VIEW,
	payload,
});

export const setHeaderTitle = (payload: Partial<{ showHeaderSelector: boolean; title: ReactNode }>) => ({
	type: types.APP_SET_HEADER_TITLE,
	payload,
});

export const setIsNewSidebar = (payload: boolean) => ({
	type: types.APP_SET_NEW_SIDEBAR,
	payload,
});

export const showLoader = () => ({
	type: types.SHOW_APP_LOADER,
});

export const hideLoader = () => ({
	type: types.HIDE_APP_LOADER,
});

export const handleError = (payload: string | Error) => ({
	type: types.REQUEST_ERROR,
	payload,
});

export const showOverlay = () => ({
	type: types.SHOW_OVERLAY,
});

export const showPopup = (component: ReactNode, isClosable = true) => ({
	type: types.TOGGLE_POPUP,
	payload: { component, isClosable },
});

export const addDefaultToast = (payload: IToast) => ({
	type: types.ADD_DEFAULT_TOAST,
	payload,
});

export const addSuccessToast = (payload: IToast) => ({
	type: types.ADD_SUCCESS_TOAST,
	payload,
});

export const addWarningToast = (payload: IToast) => ({
	type: types.ADD_WARNING_TOAST,
	payload,
});

export const addErrorToast = (payload: IToast) => ({
	type: types.ADD_ERROR_TOAST,
	payload,
});

export const removeToast = (payload: string) => ({
	type: types.REMOVE_TOAST,
	payload,
});

export const removeAllToasts = () => ({
	type: types.REMOVE_ALL_TOASTS,
});

export const setTopNotificationClosingTimestamp = (service: string, timestamp: number) => ({
	type: types.SET_TOP_NOTIFICATION_CLOSE_TIMESTAMP,
	payload: { service, timestamp },
});

export const setKeydown = event => ({
	type: types.APP_KEY_DOWN,
	payload: { keycode: event.keyCode, value: event.key },
});

export const setKeyup = event => ({
	type: types.APP_KEY_UP,
	payload: { keycode: event.keyCode },
});

export const clearPressedKeys = () => ({
	type: types.APP_CLEAR_PRESSED_KEYS,
});

export const downloadFile = (path: string, fileName?: string) => {
	const linkElement = document.createElement('a');

	linkElement.href = path;
	linkElement.download = fileName || '';
	document.body.appendChild(linkElement);
	linkElement.click();
	document.body.removeChild(linkElement);

	return { type: types.DOWNLOAD_FILE };
};

export const markReadFeatureOnboarding = (payload: string) => ({
	type: types.MARK_READ_FEATURE_ONBOARDING,
	payload,
});

export const setUserAdvancedInfoDate = (payload: Moment | null) => ({
	type: types.SET_USER_ADVANCED_INFO_DATE,
	payload,
});
