export const SET_NEWYEAR_STATS = 'SET_NEWYEAR_STATS';
export const SET_NEWYEAR_IMAGE_URL = 'SET_NEWYEAR_IMAGE_URL';
export const IS_NEW_YEAR_HOLIDAYS = true;

export const userStatsProps = PropTypes.shape({
	repertoire_releases: PropTypes.number.isRequired,
	repertoire_seances: PropTypes.number.isRequired,
	seances: PropTypes.number.isRequired,
	messages: PropTypes.number.isRequired,
	commercials: PropTypes.number.isRequired,
	mediaplan: PropTypes.number.isRequired,
	tickets_all: PropTypes.number.isRequired,
	tickets_online: PropTypes.number.isRequired,
	goods_all: PropTypes.number.isRequired,
});

export const kinoplanStatsProps = PropTypes.shape({
	notification_release: PropTypes.number.isRequired,
	notification_kdm: PropTypes.number.isRequired,
	notification_shipment: PropTypes.number.isRequired,
});
