import { joinFormatsTitles } from 'app/Reference';
import { emptyRelease } from 'app/schedule/scheduleConstants';
import { getWeekStartDate, getLocalize } from 'app/mixins';
import { IReleaseKeys, IRelease } from 'app/release_card/releaseCardTypes';
import { IRepertoryRelease } from 'app/repertory/types/types';
import { Moment } from 'moment';
import { IReferenceFormat } from 'app/ReferenceTypes';

export const getKeyRange = (keys: IReleaseKeys[] = []) => (
	keys.length ? {
		start: moment.min(_.map(keys, key => moment(key.valid_since))),
		end: moment.max(_.map(keys, key => moment(key.valid_till))),
	} : {
		start: moment(),
		end: moment(),
	}
);

export const getErrorInfo = (release: IRepertoryRelease) => {
	const formatDate = moment(release.date).format('DD.MM.YYYY');

	return i18n.t('common.ReleaseDateIsChanged', { date: formatDate });
};

export const getPreviewErrorInfo = () => (<div>{i18n.t('repertory:PreviewFormatsNotSupported')}</div>);

export const getKeysInfo = (release: IRepertoryRelease) => {
	const keysFormats = _.groupBy(release.keys, key => key.format_title);
	const keysFormatsDates = _.map(keysFormats, (keyAttr, keyFormat: string) => {
		const dateKeyRange = getKeyRange(keyAttr);

		return (
			<span key={keyFormat}>
				{keyFormat} {dateKeyRange.start.format('lll')} — {dateKeyRange.end.format('lll')}<br />
			</span>
		);
	});

	return <div><u>{i18n.t('common.KDM')}:</u><br /> {keysFormatsDates}</div>;
};

export const getPreviewInfo = (release: IRepertoryRelease, referenceFormats: IReferenceFormat[]) => {
	const date = release.date_preview ? moment(release.date_preview) : moment();
	const formats = release.preview_formats;

	return <div>{i18n.t('repertory:Preview')} {date.format('ll')}, {joinFormatsTitles(referenceFormats, formats)}</div>;
};

export const getReleaseInfo = (release: IRepertoryRelease) => {
	const releaseTitle = getLocalize(release.title);
	const distributors = _.chain(release.distributors)
		.pluck('name')
		.pluck('short')
		.join(', ')
		.value();

	return <div>{releaseTitle} {distributors}</div>;
};

export const hasKeys = (release: IRepertoryRelease) => (!_.isEmpty(getKeyRange(release.keys)));

export const hasReleasePlanningError = (release: IRepertoryRelease) => {
	if (release.release_id === emptyRelease.release_id) {
		return false;
	}
	const date = release.date_preview || release.date;

	return moment(release.plan_start).isBefore(moment(date));
};

export const getWeekShow = ({ date }: IRelease | IRepertoryRelease, weekStartDate?: string | Moment) => {
	const releaseStart = typeof date === 'string'
		? getWeekStartDate({ week: moment(date).week(), year: moment(date).year() })
		: getWeekStartDate({ week: date.week, year: date.year });

	const diffWeeks = moment(weekStartDate).diff(releaseStart, 'weeks');

	return diffWeeks < 0 ? i18n.t('repertory:previewShort') : diffWeeks + 1;
};

export function changeWeeks<T>(
	changedWeeks: T[] ,
	options: Partial<T>,
): T[] {
	return _.map(changedWeeks, week => ({ ...week, ...options }));
}
