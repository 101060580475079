import {createAction} from 'redux-actions';
import {batch} from 'react-redux';
import Request from 'app/common/Request';
import createRequest from 'app/common/RequestWithTracker';
import {
	showLoader,
	hideLoader,
	handleError,
	addErrorToast,
	addSuccessToast,
	addWarningToast,
} from 'app/app/AppActions';
import {hidePopup, addServerErrorToast, confirmAction} from 'app/app/appHelpers';
import {getRepertoryRequestForScheduleSidebar, repertoryUpdateColorRelease} from 'app/repertory/RepertoryActions';
import * as actions from 'app/schedule/scheduleConstants';
import getNotificationsContent from 'app/schedule/validations/notifications';
import {getUserCurrentCinemaID, getHallTitle} from 'app/user/userSelectors';
import {
	getDateStart,
	getFutureSeances,
	getPastSeances,
	getDateEnd,
	getChosenWeekday,
} from 'app/schedule/selectors/scheduleSelectors';
import {getEditOnlyCurrentDay} from 'app/schedule/selectors/scheduleCommonSelectors';
import TTC from 'app/common/TimeTracker';
import {
	getDatesInString,
	getDayName,
	getRangeOfDates,
	showCopyingToasts,
	getErrorText,
} from 'app/schedule/selectors/ScheduleHelpers';
import {showNoLiceseAlert} from 'app/schedule/scheduleHelpers';
import {googleTMAction} from 'app/common/googleTM';
import {doesSeanceHasSolds, getSimilarSeances, getSeancesInHall} from 'app/schedule/selectors/scheduleSeancesSelectors';

export const TrackedRequest = createRequest();

export const getScheduleSuccess = createAction(
	actions.GET_SCHEDULE_SUCCESS,
	({plannedReleases, releases, advertising, seances, dateStart, dateEnd, marks}) =>
		({plannedReleases, releases, advertising, seances, dateStart, dateEnd, marks}),
);

export const editMarks = createAction(
	actions.EDIT_MARKS_SUCCESS,
);

const seanceUpdater = ({newSeances, deletedSeances}) => ({newSeances, deletedSeances});

export const updateScheduleSeancesDays = createAction(actions.UPDATE_SCHEDULE_SEANCES_DAYS, seanceUpdater);

export const updateScheduleSeanceBySocket = createAction(actions.UPDATE_SCHEDULE_SEANCE_BY_SOCKET, seanceUpdater);

export const deleteAllSeances = createAction(
	actions.SCHEDULE_DELETE_ALL_SEANCES,
	date => ({date}),
);

export const setEditOnlyCurrentDay = createAction(
	actions.SCHEDULE_SET_EDIT_ONLY_CURRENT_DAY,
	editOnlyCurrentDay => ({editOnlyCurrentDay}),
);

const setScheduleGenerationSettings = createAction(
	actions.SET_SCHEDULE_GENERATION_SETTINGS,
	scheduleGenerationSettings => ({scheduleGenerationSettings}),
);

const setScheduleSeances = createAction(
	actions.SET_SCHEDULE_SEANCES,
	(seances, dateStart, dateEnd) => ({seances, dateStart, dateEnd}),
);

export const updateSoldTickets = createAction(
	actions.UPDATE_SOLD_TICKETS,
	updatedSeances => ({updatedSeances}),
);

export const setChosenHalls = createAction(
	actions.SET_CHOSEN_HALLS,
	chosenHalls => ({chosenHalls}),
);

export const setChosenCinemas = createAction(
	actions.SET_CHOSEN_CINEMAS,
	chosenCinemas => ({chosenCinemas}),
);

export const setChosenDates = createAction(
	actions.SET_CHOSEN_DATES,
	chosenDates => ({chosenDates}),
);

export const setSeancesDragCount = createAction(
	actions.SET_SEANCES_DRAG_COUNT,
	seancesDragCount => ({seancesDragCount}),
);

export const setHistoryTemporaryDisabled = createAction(
	actions.SET_HISTORY_TEMPORARY_DISABLED,
	payload => payload,
);

export const updateScheduleStatusSeanceBySocket = createAction(
	actions.UPDATE_SCHEDULE_SEANCE_STATUS_BY_SOCKET,
	updatedSeanceIds => ({updatedSeanceIds}),
);

export const loadScheduleGenerationSettings = cinemaID => dispatch => {
	const url = `/api/schedule/cinema/${cinemaID}/generate_settings`;

	dispatch(showLoader());

	return new Request().get(url)
		.then(response => {
			dispatch(setScheduleGenerationSettings(response));
		})
		.catch(error => {
			batch(() => {
				dispatch(setScheduleGenerationSettings({}));
				dispatch(handleError(error));
			});

			throw error;
		}).finally(() => {
			dispatch(hideLoader());
		});
};

// TODO убрать после добавления сокета со статусом пересчета рекламы
let disableHistoryBtnsTimeout = null;

export const saveScheduleGenerationSettings = (cinemaID, fields) => dispatch => {
	const isNewSettings = !fields.id;
	const url = `/api/schedule/cinema/${cinemaID}/generate_settings${isNewSettings ? '' : `/${fields.id}`}`;

	dispatch(showLoader());

	return new Request().request(isNewSettings ? 'POST' : 'PUT', url, JSON.stringify(fields))
		.then(() => dispatch(hideLoader()))
		.catch(error => dispatch(handleError(error)));
};

export const setScheduleDate = createAction(
	actions.SET_SCHEDULE_DATE,
	date => ({date}),
);

export const setSchedulePopupDate = createAction(
	actions.SET_SCHEDULE_POPUP_DATE,
	date => ({date}),
);

export const setScheduleWeekday = createAction(
	actions.SET_SCHEDULE_WEEKDAY,
	weekday => ({weekday}),
);

export const handleWindowResize = createAction(
	actions.SCHEDULE_WINDOW_RESIZE,
	timelineScaleWidth => ({timelineScaleWidth}),
);

export const toggleShowUnplannedReleases = createAction(actions.TOGGLE_SCHEDULE_SHOW_UNPLANNED_RELEASES);

export const toggleSortByStartWeek = createAction(
	actions.TOGGLE_SORT_BY_START_WEEK,
	sortByStartWeek => ({sortByStartWeek}),
);

export const changeMarksRequest = (currentCinemaID, markValues) => async dispatch => {
	dispatch(showLoader());

	const payload = _.keys(markValues).reduce((obj, key) => {
		obj.weekdays[key] = {
			price: Number(markValues[key].weekdayPrice),
			to: markValues[key].to,
		};
		obj.weekends[key] = {
			price: Number(markValues[key].weekendPrice),
			to: markValues[key].to,
		};

		return obj;
	}, {weekdays: {}, weekends: {}});

	try {
		await TrackedRequest(`/api/cinema/${currentCinemaID}/seance/prices_scheme`, {
			...payload,
		}).put;
		dispatch(editMarks(payload));
		dispatch(hidePopup());
	} catch (error) {
		dispatch(addServerErrorToast());
	} finally {
		dispatch(hideLoader());
	}
};

export const getScheduleRequest = ({currentCinemaID, dateStart, dateEnd}) => dispatch => {
	dispatch(showLoader());

	return Promise.all([
		getRepertoryRequestForScheduleSidebar({currentCinemaID, dateStart, dateEnd})(dispatch),
		TrackedRequest(`/api/schedule/cinema/${currentCinemaID}/seances`, {
			date_start: dateStart,
			date_end: dateEnd,
		}).get,
	])
		.then(response => {
			dispatch(getScheduleSuccess({
				releases: response[1].releases,
				advertising: response[1].advertising,
				seances: response[1].seances,
				marks: response[1].marks,
				dateStart,
				dateEnd,
			}));
		}).catch(error => {
			batch(() => {
				dispatch(addErrorToast({subtitle: i18n.t('validations.ConnectionError')}));
				dispatch(handleError(error));
			});
		}).finally(() => {
			dispatch(hideLoader());
		});
};

export const getGeneratedSchedule = ({currentCinemaID, dateStart, dateEnd, save = true}) => dispatch => {
	dispatch(showLoader());
	const link = `/api/schedule/cinema/${currentCinemaID}/generate?date_start=${dateStart}&date_end=${dateEnd}&save=${save}`;

	return new Request().get(link)
		.then(response => {
			dispatch(getScheduleSuccess({
				releases: response.releases,
				advertising: response.advertising,
				seances: response.seances,
				marks: response.marks,
				dateStart,
				dateEnd,
			}));
		})
		.catch(error => {
			if (Request.parseResponse(error.response).code === actions.NO_LICENSE_CODE) {
				dispatch(showNoLiceseAlert('approve'));
			} else {
				batch(() => {
					dispatch(addErrorToast({subtitle: i18n.t('validations.ConnectionError')}));
					dispatch(handleError(error));
				});
			}
		}).finally(() => {
			dispatch(hideLoader());
		});
};

export const copyScheduleHall = ({sourceHall, targetHalls, targetCinemas, dateStart, dateEnd}) => async (dispatch, getState) => {
	try {
		dispatch(showLoader());
		const response = await new Request().post('/api/schedule/copy',
			_.map(targetHalls, hall => (
				{
					hall_id_from: sourceHall.id,
					hall_id_to: hall.id,
					dates_to: getRangeOfDates(dateStart, dateEnd),
				}
			)));
		showCopyingToasts(response, targetCinemas, dateStart, dateEnd, dispatch);
	} catch (error) {
		const hallIDs = targetHalls ? targetHalls[0].id : null;
		const cinemaID = targetHalls ? targetHalls[0].cinema_id : null;

		if (Request.parseResponse(error.response).code === actions.WRONG_COPY_LICENSE_CODE && cinemaID && hallIDs) {
			const hallTitle = getHallTitle(getState(), cinemaID, hallIDs);

			dispatch(showNoLiceseAlert('copy', hallTitle));
		} else {
			batch(() => {
				dispatch(addErrorToast({subtitle: i18n.t('validations.ConnectionError')}));
				dispatch(handleError(error));
			});
		}
	} finally {
		dispatch(hideLoader());
	}
};

export const copyScheduleCinema = ({sourceHalls, targetCinemasHalls, targetCinemas, dateStart, dateEnd}) => async dispatch => {
	try {
		dispatch(showLoader());
		const response = await new Request().post('/api/schedule/copy',
			_.reduce(targetCinemasHalls, (memo, hall) => {
				const sourceHallId = _.get(_.findWhere(sourceHalls, {number: hall.number}), 'id');
				if (!_.isUndefined(sourceHallId)) {
					memo.push({
						hall_id_from: sourceHallId,
						hall_id_to: hall.id,
						dates_to: getRangeOfDates(dateStart, dateEnd),
					});
				}

				return memo;
			}, []));
		showCopyingToasts(response, targetCinemas, dateStart, dateEnd, dispatch);
	} catch (error) {
		batch(() => {
			dispatch(addErrorToast({subtitle: i18n.t('validations.ConnectionError')}));
			dispatch(handleError(error));
		});
	} finally {
		dispatch(hideLoader());
	}
};

export const copyScheduleHalls = ({targetHalls, dateFrom, targetDates}) => async (dispatch, getState) => {
	const hallsNumbers = _.pluck(targetHalls, 'title');
	try {
		dispatch(showLoader());
		const response = await new Request().post('/api/schedule/copy',
			_.map(targetHalls, hall => (
				{
					hall_id_from: hall.id,
					hall_id_to: hall.id,
					date_from: dateFrom,
					dates_to: targetDates,
				}
			)));

		const toastSubtitle = {
			dateFrom: getDayName(dateFrom),
			hallsNumbers: (hallsNumbers.sort((hall1, hall2) => hall1 - hall2)).join(', '),
			hall: hallsNumbers.length > 1 ? i18n.t('common.atHalls') : i18n.t('common.atHall'),
			datesTo: getDatesInString(targetDates),
			reason: getErrorText(_.get(response[0], 'code')),
		};

		const responseCode = _.get(response[0], 'code');

		switch (responseCode) {
			case 200: {
				dispatch(addSuccessToast({subtitle: i18n.t('schedule:ScheduleWasCopiedAtHalls', toastSubtitle)}));
				break;
			}
			case actions.CANT_DELETE_UNAPPROVED_SEANCES: {
				dispatch(addErrorToast({
					subtitle: i18n.t('schedule:ScheduleFailedToCopy', {
						dateStart: getDayName(dateFrom),
						dates: getDatesInString(_.get(response[0], 'dates')),
					}),
				}));
				break;
			}

			default: {
				dispatch(addErrorToast({subtitle: i18n.t('schedule:ScheduleWasNotCopiedAtHalls', toastSubtitle)}));
			}
		}
	} catch (error) {
		const hallIDs = targetHalls ? _.pluck(targetHalls, 'title').join(',') : null;
		const cinemaID = targetHalls ? targetHalls[0].cinema_id : null;

		if (Request.parseResponse(error.response).code === actions.WRONG_COPY_LICENSE_CODE && cinemaID && hallIDs) {
			const hallTitle = getHallTitle(getState(), cinemaID, hallIDs);

			dispatch(showNoLiceseAlert('copy', hallTitle));
		} else {
			batch(() => {
				dispatch(addErrorToast({subtitle: i18n.t('validations.ConnectionError')}));
				dispatch(handleError(error));
			});
		}
	} finally {
		dispatch(hideLoader());
	}
};

export const deleteScheduleSeancesRequest = ({
	seancesToDelete,
	currentCinemaID,
	isAll = false,
	isDeleteOnlyDay = false,
}) => async dispatch => {
	if (_.isEmpty(seancesToDelete)) return Promise.resolve();

	dispatch(showLoader());

	const firstSeance = seancesToDelete[_.first(_.keys(seancesToDelete))];
	const trackWithRequestTime = `deleteSeancesFrom${firstSeance.hall_id}`;
	const trackFromRequestEndToRender = `handleDeleteSeancesFrom${firstSeance.hall_id}`;

	try {
		TTC.createTracker(trackWithRequestTime, 60);

		const newSeances = await TrackedRequest(
			`/api/schedule/cinema/${currentCinemaID}/seances`,
			_.pluck(seancesToDelete, 'id'),
		).delete;

		if (_.isArray(newSeances)) {
			TTC.createTracker(trackFromRequestEndToRender, 60);

			dispatch(isAll
				? deleteAllSeances(isDeleteOnlyDay ? firstSeance.date_start : null)
				: updateScheduleSeancesDays({newSeances, deletedSeances: seancesToDelete}));
		}
	} catch (error) {
		TTC.deleteTracker(trackWithRequestTime);
		TTC.deleteTracker(trackFromRequestEndToRender);

		const errorCode = _.get(Request.parseResponse(error.response), 'code');
		let subtitleText;

		switch (error.status) {
			case 304:
				subtitleText = 'notifications.WaitForRequestEnd';
				break;
			case 406:
				subtitleText = errorCode === actions.APPROVED_SEANCES_CODE
					? 'schedule:alerts.Approved'
					: 'schedule:alerts.SalesAreOpen';
				break;
			default: subtitleText = 'validations.ConnectionError';
		}
		batch(() => {
			dispatch(addErrorToast({subtitle: i18n.t(subtitleText)}));
			dispatch(handleError(error));
		});

		throw error;
	} finally {
		dispatch(hideLoader());
	}
};

export const addScheduleSeancesRequest = ({seancesToAdd, currentCinemaID}) => (dispatch, getState) => {
	const hallID = seancesToAdd[0].seances[0].hall_id;
	const trackWithRequestTime = `addSeanceIn${hallID}`;
	const trackFromRequestEndToRender = `handleAddSeancesIn${hallID}`;
	TTC.createTracker(trackWithRequestTime, 60);

	dispatch(showLoader());

	return TrackedRequest(
		`/api/schedule/cinema/${currentCinemaID}/seances`,
		seancesToAdd,
	)
		.post
		.then(newSeances => {
			TTC.createTracker(trackFromRequestEndToRender, 60);

			batch(() => {
				dispatch(updateScheduleSeancesDays({newSeances}));
				dispatch(setHistoryTemporaryDisabled(true));
			});

			if (disableHistoryBtnsTimeout) clearTimeout(disableHistoryBtnsTimeout);

			disableHistoryBtnsTimeout = setTimeout(() => {
				dispatch(setHistoryTemporaryDisabled(false));
			}, actions.UNDO_BTNS_DISABLE_DELAY);
		})
		.catch(error => {
			TTC.deleteTracker(trackWithRequestTime);
			TTC.deleteTracker(trackFromRequestEndToRender);

			if (Request.parseResponse(error.response).code === actions.NO_LICENSE_CODE) {
				const hallTitle = getHallTitle(getState(), currentCinemaID, hallID);

				dispatch(showNoLiceseAlert('add', hallTitle));
			} else if (Request.parseResponse(error.response).code === actions.WRONG_END_OF_DAY_CODE) {
				batch(() => {
					dispatch(addErrorToast({subtitle: i18n.t('schedule:alerts.EndOfDay')}));
					dispatch(handleError(error));
				});
			} else {
				batch(() => {
					dispatch(addErrorToast({subtitle: i18n.t('validations.ConnectionError')}));
					dispatch(handleError(error));
				});
			}

			throw error;
		}).finally(() => {
			dispatch(hideLoader());
		});
};

export const patchScheduleSeances = ({seancesToPatch, currentCinemaID, initialSeances}) => (dispatch, getState) => {
	dispatch(showLoader());
	const makeRequest = new Request();

	return makeRequest.patch(
		`/api/schedule/cinema/${currentCinemaID}/seances`,
		seancesToPatch,
	).then(newSeances => {
		batch(() => {
			dispatch(updateScheduleSeancesDays({newSeances}));
			dispatch(setHistoryTemporaryDisabled(true));
		});

		if (disableHistoryBtnsTimeout) clearTimeout(disableHistoryBtnsTimeout);

		disableHistoryBtnsTimeout = setTimeout(() => {
			dispatch(setHistoryTemporaryDisabled(false));
		}, actions.UNDO_BTNS_DISABLE_DELAY);
	}).catch(error => {
		const hallID = seancesToPatch ? seancesToPatch[0].seances[0].hall_id : null;

		if (Request.parseResponse(error.response).code === actions.NO_LICENSE_CODE && hallID) {
			const hallTitle = getHallTitle(getState(), currentCinemaID, hallID);

			dispatch(showNoLiceseAlert('add', hallTitle));
		} else {
			batch(() => _.compact([
				initialSeances && dispatch(updateScheduleSeancesDays({newSeances: _.flatten(_.toArray(initialSeances))})),
				dispatch(handleError()),
				dispatch(addErrorToast(getNotificationsContent(makeRequest.xhrInstance))),
			]));
		}
	}).finally(() => {
		dispatch(hideLoader());
	});
};

export const recalculateAdvertising = ({currentCinemaID, dateStart, dateEnd, hasUnmovableSeances}) => dispatch => {
	if (hasUnmovableSeances) {
		dispatch(addWarningToast({
			title: i18n.t('notifications.Attention'),
			subtitle: (
				<div>
					{i18n.t('schedule:alerts.HasUnmovableSeances')}<br />
					{i18n.t('schedule:alerts.CancelLocked')}
				</div>),
		}));
	}
	dispatch(showLoader());

	return new Request().put(
		`/api/schedule/cinema/${currentCinemaID}/adv_recalc?date_start=${dateStart}&date_end=${dateEnd}`,
		null,
	).then(newSeances => {
		dispatch(updateScheduleSeancesDays({newSeances}));
	})
	.catch(() => {
		batch(() => {
			dispatch(addErrorToast({subtitle: i18n.t('validations.ConnectionError')}));
			dispatch(handleError());
		});
	}).finally(() => {
		dispatch(hideLoader());
	});
};

export const modifyScheduleSeances = ({seancesToModify, currentCinemaID}) => dispatch => {
	dispatch(showLoader());

	return new Request().put(
		`/api/schedule/cinema/${currentCinemaID}/seances`,
		seancesToModify,
	).then(newSeances => {
		dispatch(updateScheduleSeancesDays({newSeances}));
	}).catch(error => {
		batch(() => {
			dispatch(addErrorToast({subtitle: i18n.t('validations.ConnectionError')}));
			dispatch(handleError(error));
		});
	}).finally(() => {
		dispatch(hideLoader());
	});
};

export const approveAllScheduleRequestHelper = (currentCinemaID, approved, dateStart, dateEnd) =>
	new Request().put(`/api/schedule/cinema/${currentCinemaID}/approve_week`, {
		date_start: dateStart,
		date_end: dateEnd,
		approved,
	});

export const approveAllScheduleRequest = ({currentCinemaID, approved, dateStart, dateEnd}) => dispatch => {
	dispatch(showLoader());

	googleTMAction('schedule-approve-all');

	return approveAllScheduleRequestHelper(currentCinemaID, approved, dateStart, dateEnd)
		.then(() => dispatch(hideLoader()))
		.catch(error => dispatch(handleError(error)));
};

export const approveAllSchedule = ({currentCinemaID, approved, dateStart, dateEnd}) => (dispatch, getState) => {
	dispatch(showLoader());

	const isApprovedToOneDay = getEditOnlyCurrentDay(getState());

	return approveAllScheduleRequestHelper(currentCinemaID, approved, dateStart, dateEnd)
		.then(newSeances => {
			batch(() => {
				dispatch(setScheduleSeances(newSeances, dateStart, dateEnd));
				dispatch(addSuccessToast({
					subtitle: approved
						? i18n.t(
							`schedule:alerts.${isApprovedToOneDay && moment(dateStart).isSame(dateEnd, 'day')
								? 'ScheduleApprovedDay' : 'ScheduleApprovedWeek'}`,
							{data: moment(dateStart).format('D MMMM')},
						)
						: i18n.t(
							`schedule:alerts.${isApprovedToOneDay && moment(dateStart).isSame(dateEnd, 'day')
								? 'ScheduleUnapprovedDay' : 'ScheduleUnapprovedWeek'}`,
							{data: moment(dateStart).format('D MMMM')},
						),
				}));
			});
		}).catch(error => {
			if (Request.parseResponse(error.response).code === actions.NO_LICENSE_CODE) {
				dispatch(showNoLiceseAlert('approve'));
			} else {
				batch(() => {
					dispatch(addErrorToast({subtitle: i18n.t('validations.ConnectionError')}));
					dispatch(handleError(error));
				});
			}
		}).finally(() => {
			dispatch(hideLoader());
		});
};

export const approveSeances = ({currentCinemaID, approved, dateStart, dateEnd, hallID, titleToast}) => (dispatch, getState) => {
	dispatch(showLoader());

	new Request().put(`/api/schedule/cinema/${currentCinemaID}/approve`, !dateStart ? null : {
		date_start: dateStart,
		date_end: dateEnd,
		hall_id: hallID,
		approved,
	})
		.then(newSeances => {
			batch(() => {
				dispatch(updateScheduleSeancesDays({newSeances}));
				dispatch(addSuccessToast({subtitle: titleToast}));
			});
		})
		.catch(error => {
			if (Request.parseResponse(error.response).code === actions.NO_LICENSE_CODE) {
				const hallTitle = getHallTitle(getState(), currentCinemaID, hallID);

				dispatch(showNoLiceseAlert('approve', hallTitle));
			} else {
				batch(() => {
					dispatch(addErrorToast({subtitle: i18n.t('validations.ConnectionError')}));
					dispatch(handleError(error));
				});
			}
		})
		.finally(() => {
			dispatch(hideLoader());
		});
};

export const approveSeance = ({
	currentCinemaID,
	approved,
	dateStart,
	dateEnd,
	hallID,
	seanceIDs,
	titleToast = '',
}) => async (dispatch, getState) => {
	try {
		dispatch(showLoader());

		const newSeances = await new Request().put(
			`/api/schedule/cinema/${currentCinemaID}/approve_one`,
			!dateStart ? null : {
				date_start: dateStart,
				date_end: dateEnd,
				hall_id: hallID,
				approved,
				seance_ids: seanceIDs,
			},
		);

		const isApprovedToOneDay = getEditOnlyCurrentDay(getState());
		const momentDateStart = moment(dateStart);
		const momentDateEnd = moment(dateEnd);

		batch(() => {
			dispatch(updateScheduleSeancesDays({newSeances}));
			dispatch(addSuccessToast({
				subtitle: approved
					? i18n.t(
						`schedule:alerts.${isApprovedToOneDay && momentDateStart.isSame(dateEnd, 'day')
							? 'SeanceApprovedDay' : 'SeanceApprovedRange'}`,
						{dataStart: momentDateStart.format('D MMMM'), dataEnd: momentDateEnd.format('D MMMM')},
					)
					: i18n.t(
						`schedule:alerts.${isApprovedToOneDay && moment(dateStart).isSame(dateEnd, 'day')
							? 'SeanceUnapprovedDay' : 'SeanceUnapprovedRange'}`,
						{dataStart: momentDateStart.format('D MMMM'), dataEnd: momentDateEnd.format('D MMMM')},
					),
			}));
		});

		if (titleToast.length) {
			dispatch(addSuccessToast({subtitle: titleToast}));
		}
	} catch (error) {
		if (Request.parseResponse(error.response).code === actions.SEANCE_INTERSECT_WITH_OTHER) {
			dispatch(addErrorToast({subtitle: i18n.t('schedule:alerts.ErrorSeanceApproved')}));
		} else {
			dispatch(addErrorToast({subtitle: i18n.t('validations.ConnectionError')}));
		}
	} finally {
		dispatch(hideLoader());
	}
};

export const changeSeancesTimeStart = createAction(
	actions.SET_SCHEDULE_SEANCES_TIME_START,
	({newSeances}) => ({newSeances}),
);

export const goToTimeline = date => dispatch => dispatch(setScheduleWeekday(date));

export const beginDragSeances = createAction(
	actions.SET_SCHEDULE_DRAG_SEANCES,
	seances => ({seances}),
);

export const setHallContentOffset = createAction(
	actions.SET_HALL_CONTENT_OFFSET,
	offset => ({offset}),
);
export const resetHallContentOffset = createAction(actions.RESET_HALL_CONTENT_OFFSET);

export const selectTimelineSeance = createAction(
	actions.SELECT_SCHEDULE_TIMELINE_SEANCE,
	seanceID => ({seanceID}),
);

export const moveSeancesAnotherHallRequest = ({
	seancesToDelete,
	seancesToPatch,
	initialSeances,
	currentCinemaID,
}) => async (dispatch, getState) => {
	try {
		dispatch(showLoader());

		const [seancesDeleteNew, seancesPatchNew] = await Promise.all([
			!_.isEmpty(seancesToDelete)
				? new Request().delete(`/api/schedule/cinema/${currentCinemaID}/seances`, _.pluck(seancesToDelete, 'id'))
				: Promise.resolve([]),
			new Request().patch(`/api/schedule/cinema/${currentCinemaID}/seances`, seancesToPatch),
		]);

		dispatch(updateScheduleSeancesDays({
			newSeances: [...seancesDeleteNew, ...seancesPatchNew],
			deletedSeances: seancesToDelete,
		}));
	} catch (error) {
		const hallID = seancesToPatch ? seancesToPatch[0].seances[0].hall_id : null;

		if (Request.parseResponse(error.response).code === actions.NO_LICENSE_CODE && hallID) {
			const hallTitle = getHallTitle(getState(), currentCinemaID, hallID);

			dispatch(showNoLiceseAlert('add', hallTitle));
		}

		batch(() => _.compact([
			initialSeances && dispatch(updateScheduleSeancesDays({newSeances: _.flatten(_.toArray(initialSeances))})),
			dispatch(handleError()),
		]));
	} finally {
		dispatch(hideLoader());
	}
};

export const changeSeancesInHallsRequest = ({
	seancesToDelete,
	seancesToPatch,
	currentCinemaID,
}) => async dispatch => {
	try {
		dispatch(showLoader());

		const seancesDeleteNew = await new Request().delete(
			`/api/schedule/cinema/${currentCinemaID}/seances`,
			_.pluck(seancesToDelete, 'id'),
		);
		const seancesPatchNew = await new Request().patch(`/api/schedule/cinema/${currentCinemaID}/seances`, seancesToPatch);

		dispatch(updateScheduleSeancesDays({
			newSeances: [...seancesDeleteNew, ...seancesPatchNew],
			deletedSeances: seancesToDelete,
		}));
	} catch (error) {
		dispatch(handleError());
	} finally {
		dispatch(hideLoader());
	}
};

export const resetSchedule = createAction(actions.RESET_SCHEDULE);

export const updateSeanceObject = createAction(
	actions.SCHEDULE_UPDATE_SEANCE_OBJECT,
	(seances, seanceID) => ({seances, seanceID}),
);

export const updateSeancePropertiesRequest = (seance, newAdvertisingFeature, newAutoCancel) =>
	async (dispatch, getState) => {
		try {
			dispatch(showLoader());
			const cinemaID = getUserCurrentCinemaID(getState());

			const updatingSeances = await new Request().post(`/api/schedule/cinema/${cinemaID}/property`, {
				seance_ids: [seance.id],
				advertising_feature: newAdvertisingFeature,
				is_auto_cancel_disabled: newAutoCancel,
			});

			dispatch(updateSeanceObject(updatingSeances, seance.id));
		} catch (error) {
			batch(() => {
				dispatch(addErrorToast({subtitle: i18n.t('validations.ConnectionError')}));
				dispatch(handleError(error));
			});
		} finally {
			dispatch(hideLoader());
		}
	};

export const seancesUndo = createAction(actions.SCHEDULE_SEANCES_UNDO);
export const seancesRedo = createAction(actions.SCHEDULE_SEANCES_REDO);

export const scheduleCancelSeancesHistoryRequest = type => async (dispatch, getState) => {
	try {
		const state = getState();
		const cinemaID = getUserCurrentCinemaID(state);
		const dateStart = getDateStart(state);
		const dateEnd = moment(dateStart).add(6, 'day').format();
		const futureSeances = getFutureSeances(state);
		const pastSeances = getPastSeances(state);
		const seancesToRequest = _.toArray(type === 'undo' ? pastSeances : futureSeances);
		const historyAction = type === 'undo' ? seancesUndo : seancesRedo;

		dispatch(showLoader());

		await new Request().post(`/api/schedule/cinema/${cinemaID}/cancel_action`, {
			date_start: dateStart,
			date_end: dateEnd,
			seances: seancesToRequest,
		});

		batch(() => {
			dispatch(historyAction());
			dispatch(setHistoryTemporaryDisabled(true));
		});

		if (disableHistoryBtnsTimeout) clearTimeout(disableHistoryBtnsTimeout);

		disableHistoryBtnsTimeout = setTimeout(() => {
			dispatch(setHistoryTemporaryDisabled(false));
		}, actions.UNDO_BTNS_DISABLE_DELAY);
	} catch (error) {
		const errorResponse = Request.parseResponse(error.response);

		if (errorResponse.code === 15906) {
			batch(() => {
				dispatch(addErrorToast({subtitle: i18n.t('schedule:alerts.CanNotCancelSeanse')}));
				dispatch(handleError(error));
			});
		}
	} finally {
		dispatch(hideLoader());
	}
};

const lockOrUnlockAllSeances = createAction(
	actions.LOCK_OR_UNLOCK_ALL_SEANCES,
	(seances, dateStart, dateEnd) => ({seances, dateStart, dateEnd}),
);

export const lockOrUnlockAllSeancesRequest = ({locked = true, isTimeline} = {}) => async (dispatch, getState) => {
	try {
		const state = getState();
		const cinemaID = getUserCurrentCinemaID(state);

		let dateStart = getDateStart(state);
		let dateEnd = getDateEnd(state);

		if (getEditOnlyCurrentDay(state) && isTimeline) {
			dateStart = dateEnd = moment(dateStart).weekday(getChosenWeekday(state)).format();
		}

		dispatch(showLoader());

		const updatedSeances = await new Request()
			.put(`/api/schedule/cinema/${cinemaID}/lock?locked=${locked}&date_start=${dateStart}&date_end=${dateEnd}`);

		dispatch(lockOrUnlockAllSeances(updatedSeances, dateStart, dateEnd));
	} catch (error) {
		batch(() => {
			dispatch(addErrorToast({subtitle: i18n.t('validations.ConnectionError')}));
			dispatch(handleError(error));
		});
	} finally {
		dispatch(hideLoader());
	}
};

export const togglePeriodsMarks = createAction(actions.TOGGLE_PERIODS_MARK);
export const toggleColorReleases = createAction(
	actions.TOGGLE_COLOR_RELEASES,
	showColorReleases => ({showColorReleases}),
);

const updateTimelineRelease = createAction(
	actions.UPDATE_TIMELINE_RELEASE,
	(release, color) => ({release, color}),
);

export const updateColorReleases = (color, release) => async dispatch => {
	try {
		const hexValue = color.substr(1, 6);
		const releaseID = _.get(release, 'release_id');

		await new Request().post(`/api/release_user_settings/${releaseID}/schedule_color?color=${hexValue}`);

		batch(() => {
			dispatch(repertoryUpdateColorRelease(release, hexValue));
			dispatch(updateTimelineRelease(release, hexValue));
		});
	} catch (error) {
		batch(() => {
			dispatch(addErrorToast({subtitle: i18n.t('validations.ConnectionError')}));
			dispatch(handleError(error));
		});
	}
};
export const toggleTicketMode = createAction(actions.TOGGLE_TICKET_MODE);
export const toggleTimeMode = createAction(actions.TOGGLE_TIME_MODE);

export const deleteOrUpdateScheduleSeancesConfirmation = (seances, isSeancesWithSoldTicketsOnWeek = false,
	shouldReplaceSeance = false, chosenDate = '') =>
	async dispatch => {
		const checkedSeances = chosenDate ? _.filter(seances, seance => seance.date_start >= chosenDate) : seances;
		const isSingleSeance = checkedSeances.length === 1;

		const soldTickets = checkedSeances.some(seance => !!seance.sale.sold_count);
		const reservedTickets = checkedSeances.some(seance => !!seance.sale.reserved_count);

		const descriptionType = () => {
			const getTicketType = () => {
				if (soldTickets && reservedTickets) return 'soldAndReserved';
				if (soldTickets) return 'sold';
				if (reservedTickets) return 'reserved';

				return 'soldOrReserved';
			};

			return i18n.t('schedule:DeleteSeancesDescription.seance', {
				ticketType: i18n.t(`schedule:DeleteSeancesDescription.ticketTypes.${getTicketType()}`),
				seanceType: i18n.t(
					`schedule:DeleteSeancesDescription.seanceTypes.${isSeancesWithSoldTicketsOnWeek ? '2' : +isSingleSeance}`,
				),
			});
		};

		if ((soldTickets || reservedTickets) || isSeancesWithSoldTicketsOnWeek) {
			try {
				await dispatch(confirmAction({
					title: i18n.t(`schedule:${shouldReplaceSeance ? 'Replace' : 'Delete'}SeanceTitle.${+isSingleSeance}`),
					content: descriptionType(),
					width: '400px',
					confirmLabel: i18n.t(`actions.${shouldReplaceSeance ? 'Change' : 'Delete'}`),
					cancelLabel: i18n.t('actions.Cancel'),
					confirmActionKind: 'danger',
				}));
			} catch (error) {
				throw error;
			}
		}
	};

export const unlockSeancesConfirm = (count, editOnlyCurrentDay) => confirmAction({
	content: i18n.t('schedule:alerts.UnlockSeancesWithSolds', {
		count,
		currentDay: editOnlyCurrentDay ? i18n.t('schedule:alerts.UnlockCurrentDay') : '',
	}),
	confirmActionKind: 'danger',
	confirmLabel: i18n.t('common.Continue'),
	cancelLabel: i18n.t('actions.Close'),
});

export const toggleSeancesLock = ({seance, editOnlyCurrentDay}) => async (dispatch, getState) => {
	const state = getState();
	const seancesToEdit = editOnlyCurrentDay
		? [seance]
		: _.filter(
			getSimilarSeances(getSeancesInHall(seance.hall_id)(state), seance),
			currentSeance => moment(currentSeance.date_start).isSameOrAfter(moment(seance.date_start)),
		);
	const seancesWithSoldsCount = _.filter(seancesToEdit, doesSeanceHasSolds).length;

	googleTMAction('schedule-lock-seance');

	if (seancesWithSoldsCount && seance.is_locked) {
		try {
			await dispatch(unlockSeancesConfirm(seancesWithSoldsCount, editOnlyCurrentDay));
		} catch (error) {
			return;
		}
	}

	dispatch(modifyScheduleSeances({
		seancesToModify: _.map(
			seancesToEdit,
			seanceItem => ({...seanceItem, is_locked: !seance.is_locked}),
		),
		currentCinemaID: getUserCurrentCinemaID(state),
	}));
};
