import Pages, { IPage } from 'app/new_sidebar/Pages';
import { PrmSectionName } from 'app/premiera/prmTypes';

export default class PrmPages extends Pages {
	static readonly SECTION_NAME = 'premiera';
	static readonly PAGES: IPage[] = [{
		name: 'ribbon_control',
		resource: 'ribbon_control',
		icon: 'folder_special',
		children: [
			{
				name: PrmSectionName.Ribbon,
				resource: 'ribbon_control',
			},
			{
				name: PrmSectionName.RibbonAcceptance,
				resource: 'ribbon_control',
			},
			{
				name: PrmSectionName.RibbonSettings,
				resource: 'ribbon_control',
			},
		],
	}, {
		name: 'tickets',
		resource: 'tickets',
		icon: 'confirmation_number',
		children: [
			PrmSectionName.TicketTypes,
			{
				name: PrmSectionName.Bod,
				resource: PrmSectionName.Bod,
			},
			{
				name: PrmSectionName.Invitations,
				resource: PrmSectionName.Invitations,
			},
			{
				name: PrmSectionName.ReturnTypes,
				resource: PrmSectionName.ReturnTypes,
			},
		],
	}, {
		name: PrmSectionName.PlaceTypes,
		resource: 'place_type',
		icon: 'HallIcon',
	}, {
		name: 'price_management',
		icon: 'monetization_on',
		children: [{
			name: PrmSectionName.PriceSchemes,
			resource: 'price_schemes_and_intervals',
		}, {
			name: PrmSectionName.PriceGroups,
			resource: 'price_group',
		}, {
			name: PrmSectionName.PriceModifiers,
			resource: 'price_modifier',
		}],
	}, {
		name: 'facilities',
		resource: 'accounting_objects',
		icon: 'domain',
		children: [
			{
				name: PrmSectionName.LegalEntity,
				resource: 'accounting_objects',
			},
			{
				name: PrmSectionName.Requisites,
				resource: 'accounting_objects',
			},
			{
				name: PrmSectionName.Positions,
				resource: 'accounting_objects',
			},
			{
				name: PrmSectionName.PaymentMeans,
				resource: 'payment_means',
				children: [
					PrmSectionName.Cash,
					PrmSectionName.Cashless,
					PrmSectionName.Card,
					PrmSectionName.CounterGrant,
					PrmSectionName.PrepaidExpense,
				],
			},
			PrmSectionName.ObjectSettings,
		],
	}, {
		name: 'releases',
		resource: 'release_properties',
		icon: 'shopping_cart',
		children: [
			{
				name: PrmSectionName.ReleaseProperties,
				resource: 'release_properties',
			},
		],
	}, {
		name: 'external_systems',
		resource: 'external_systems',
		icon: 'import_export',
		children: [
			{
				name: PrmSectionName.SpecialOffers,
				resource: 'sale_operations',
			},
			{
				name: PrmSectionName.ReservationTypes,
				resource: 'reservation_types',
			},
			{
				name: PrmSectionName.Discounts,
				resource: 'loyalty_programs',
			},
		],
	},
	{
		name: PrmSectionName.DistributorReports,
		resource: PrmSectionName.DistributorReports,
		icon: 'playlist_add_check',
	},
	{
		name: PrmSectionName.Reports,
		resource: PrmSectionName.Reports,
		icon: 'import_export',
	}];

	static getApiUrl = (name: PrmSectionName) => this.flatList[name].url
		.split('/')
		.slice(2)
		.join('/');
}
