import { ICinema, IHall } from 'app/user/UserTypes';
import { get } from 'app/mixins';

export const getUnitedNetworkCinemaID = (cinemaData: ICinema): string =>
	get(cinemaData, 'cinema_network.id') === 484
		? get(cinemaData, 'external_cinema.index20.id', '')
		: '';

export const makeHallTitle = (hall: IHall, isLongTitle = true) => {
	return `${i18n.t('common.Hall')} ${isLongTitle ? (hall.title || hall.number) : (hall.number || '-')}`;
};
