import {createAction} from 'redux-actions';
import Cookies from 'js-cookie';
import * as actions from 'app/user/constants/UserConstants';
import Request, {RequestForStartApp} from 'app/common/Request';
import {addErrorToast} from 'app/app/AppActions';
import {getUserFeatureAwareness, getCinemasData, getHallsLicenses} from 'app/user/userSelectors';
import {getAuthToken, getAuthUserID, redirectToAuth} from 'app/authentication/common';
import {googleTMAction} from 'app/common/googleTM';

export const changeCurrentCinema = createAction(
	actions.USER_CHANGE_CURRENT_CINEMA,
	(cinema, route) => ({cinema, route}),
);

export const addBan = createAction(
	actions.USER_ADD_BAN,
	userBanDates => ({userBanDates}),
);

export const changeCinema = (cinema, route = []) => dispatch => {
	dispatch(changeCurrentCinema(cinema, route));
	const isTimeline = route.includes('timeline');
	const isSchedule = route.includes('schedule');

	Cookies.set('currentCinema', cinema.id, {path: '/'});

	if (isSchedule) {
		googleTMAction(`header-select-cinema-${isTimeline ? 'schedule-timeline' : 'schedule'}`);
	}
};

export const changeDate = createAction(
	actions.USER_CHANGE_CURRENT_DATE,
	newDate => ({newDate}),
);

export const updateCinemaTmsSettings = createAction(
	actions.USER_UPDATE_CINEMA_TMS_SETTINGS,
	tmsSettings => ({tmsSettings}),
);

export const updateHallsTmsSettings = createAction(
	actions.USER_UPDATE_HALLS_TMS_SETTINGS,
	tmsSettings => ({tmsSettings}),
);

export const updateHallTmsSettings = createAction(
	actions.USER_UPDATE_HALL_TMS_SETTINGS,
	tmsSettings => ({tmsSettings}),
);

export const updateCinemaIDTmsSettings = createAction(
	actions.USER_UPDATE_CINEMA_ID_TMS_SETTINGS,
	cinemaID => cinemaID,
);

export const setKdmBlocked = createAction(
	actions.USER_SET_KDM_BLOCKED,
	(cinemaID, isKdmBlocked) => ({cinemaID, isKdmBlocked}),
);

export const setTx = createAction(
	actions.USER_SET_TX,
	tx => ({tx}),
);

const editInfoRequest = createAction(actions.USER_EDIT_INFO_REQUEST);

export const editInfoSuccess = createAction(
	actions.USER_EDIT_INFO_SUCCESS,
	data => ({data}),
);

const editInfoFail = createAction(
	actions.USER_EDIT_INFO_FAIL,
	error => ({error}),
);

export const editInfo = data => dispatch => {
	const editUrl = '/api/user/v2/edit';

	dispatch(editInfoRequest());

	return new Request().patch(editUrl, data)
		.then(() => {
			dispatch(editInfoSuccess(data));
		})
		.catch(error => {
			dispatch(editInfoFail(error));
		});
};

export const readChatSuccess = createAction(
	actions.USER_READ_CHAT_SUCCESS,
	timestamp => ({timestamp}),
);

const readChatFail = createAction(
	actions.USER_READ_CHAT_FAIL,
	error => ({error}),
);

const setFeatureAwareness = createAction(
	actions.USER_SET_FEATURE_AWARENESS,
	featureAwareness => ({featureAwareness}),
);

export const handleReadChat = () => dispatch =>
	new Request().post('/api/v2/messages/read')
		.then(response => dispatch(readChatSuccess(response.timestamp)))
		.catch(error => dispatch(readChatFail(error)));

export const updateUserFeatureAwarenessRequest = data => async (dispatch, getState) => {
	const updatedFeatureAwareness = {
		...getUserFeatureAwareness(getState()),
		...data,
	};

	try {
		await new Request().patch('/api/user/feature_awareness', updatedFeatureAwareness);
	} catch (error) {
		dispatch(addErrorToast({subtitle: i18n.t('common.ServerConnectionError')}));
	} finally {
		dispatch(setFeatureAwareness(updatedFeatureAwareness));
	}
};

export const logoutUserRequest = async () => {
	const requestData = {user_id: getAuthUserID(), api_token: getAuthToken()};

	try {
		await new RequestForStartApp().post('/api/account/logout', requestData);
		redirectToAuth();
	} catch (error) {
		throw error;
	}
};

export const setUser = createAction(
	actions.USER_SET_USER,
	user => ({user}),
);

export const setCinemaData = createAction(
	actions.USER_SET_CINEMA_DATA,
	(cinemaID, cinemaData) => ({cinemaID, cinemaData}),
);

export const setHallsLicenses = createAction(
	actions.USER_SET_HALLS_LICENSES,
	(cinemaID, hallsLicenses) => ({cinemaID, hallsLicenses}),
);


// Нигде не используется, но используется поле с редюсера
export const setCinemaDataFetching = createAction(
	actions.USER_SET_CINEMA_DATA_FETCHING,
	isFetching => (isFetching),
);

export const updateCinemaData = createAction(
	actions.USER_UPDATE_CINEMA_DATA,
	(cinemaID, updatedData) => ({cinemaID, updatedData}),
);

export const getCinemaSettingsRequest = cinemaID => async (dispatch, getState) => {
	try {
		const state = getState();
		const cinemasData = getCinemasData(state);
		const hallsLicenses = getHallsLicenses(state);

		if (!cinemasData[cinemaID]) {
			const cinemaData = await new Request().get(`/api/cinema_settings/${cinemaID}`);

			dispatch(setCinemaData(cinemaID, cinemaData));
		}

		if (!hallsLicenses || !hallsLicenses[cinemaID]) {
			const settings = await new Request().patch(`/api/cinema_settings/${cinemaID}/params/halls_settings`);

			dispatch(setHallsLicenses(cinemaID, settings));
		}
	} catch (error) { }
};

export const setUnreadsCount = createAction(
	actions.USER_SET_UNREADS_COUNT,
	({count}) => count,
);

export const setAdditionalInfo = createAction(
	actions.USER_SET_ADDITIONAL_INFO,
	data => data,
);
