import {googleTMAction} from 'app/common/googleTM';

class DefaultTimeTracker {
	stop() { return this }

	track() { return this }
}

export class TimeTracker {
	constructor(name) {
		this.timeStart = Date.now();
		this.name = name;

		return this;
	}

	stop() {
		this.timeEnd = Date.now();

		return this;
	}

	/**
	 * @property {?string} category
	 * @property {?string} action
	 * @property {?string} label
	 * @property {?string} url
	*/

	trackTime({category, action, label}) {
		const {timeEnd = Date.now(), timeStart} = this;

		googleTMAction(`${category}_${action}-${label}-${timeEnd - timeStart}`);
	}
}

export class TimeTrackerController {
	storage = {};

	/**
 	* @param {number|string} name
 	* @param {?number} selfDestructTimer - в СЕКУНДАХ
	*/
	createTracker(name, selfDestructTimer = null) {
		this.storage[name] = new TimeTracker(name);

		if (typeof selfDestructTimer === 'number') {
			setTimeout(
				() => this.getTracker(name) && this.deleteTracker(name),
				selfDestructTimer * 1000,
			);
		}

		return this.storage[name];
	}

	getTracker(name) {
		return this.storage[name];
	}

	getAndDelete(name) {
		const reference = this.storage[name];
		this.deleteTracker(name);

		return reference || new DefaultTimeTracker();
	}

	/**
 	* @param {number|string} name
 	* @return {{timeStart: number, name: string|number} | undefined}
 	*/
	deleteTracker(name) {
		const saved = {...this.storage[name]};
		delete this.storage[name];

		return saved;
	}
}

const controller = new TimeTrackerController();

export default controller;
